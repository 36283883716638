.Progress {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.ProgressBar {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex: 1;
}

.ProgressBar__label {
  padding: 6px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.8);
}

.ProgressBar__bar {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  transition: width 50ms cubic-bezier(0.1, 0.7, 1, 0.1);
}
.ProgressBar__b .ProgressBar__bar {
  left: 100%;
  transform: translateX(-100%);
}
