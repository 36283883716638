body {
  margin: 0;
}

/* Overwrite the spectre.css default. Sets it back to the regular link color.
   Otherwise it looks wird on link-buttons. */
a:visited {
  color: #5755d9;
}

h1,
h2,
h3,
h4,
h5,
h1.introjs-tooltip-title {
  font-family: "Mochiy Pop One", sans-serif;
}

#app {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* If we're not setting this, tiles will cause scroll */
  overflow: hidden;
}

/* overwriting spectre base styles */
#app .panel {
  border: none;
  border-radius: none;
}
