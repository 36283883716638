.Game {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.Game__message {
  margin: 1em 0;
  display: flex;
  align-items: center;
}

.Game__Interaction {
  border-top: 1px solid rgb(88, 88, 88);
}
