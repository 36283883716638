.Multi__Loading {
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-width: 150px;
}

.Multi__shareMsg {
  margin: 1rem 0.5rem;
  background: aliceblue;
  padding: 0.5rem;
  border-radius: 5px;
}

.Multi__URL {
  width: 100%;
}
