.Menu1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Menu__backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}
