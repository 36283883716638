@keyframes dots-loader {
  0% {
    box-shadow: var(--color-0) -10px -10px 0 5px, var(--color-1) 10px -10px 0 5px, var(--color-2) 10px 10px 0 5px, var(--color-3) -10px 10px 0 5px;
  }
  8.33% {
    box-shadow: var(--color-0) 10px -10px 0 5px, var(--color-1) 10px -10px 0 5px, var(--color-2) 10px 10px 0 5px, var(--color-3) -10px 10px 0 5px;
  }
  16.67% {
    box-shadow: var(--color-0) 10px 10px 0 5px, var(--color-1) 10px 10px 0 5px, var(--color-2) 10px 10px 0 5px, var(--color-3) -10px 10px 0 5px;
  }
  25% {
    box-shadow: var(--color-0) -10px 10px 0 5px, var(--color-1) -10px 10px 0 5px, var(--color-2) -10px 10px 0 5px, var(--color-3) -10px 10px 0 5px;
  }
  33.33% {
    box-shadow: var(--color-0) -10px -10px 0 5px, var(--color-1) -10px 10px 0 5px, var(--color-2) -10px -10px 0 5px, var(--color-3) -10px -10px 0 5px;
  }
  41.67% {
    box-shadow: var(--color-0) 10px -10px 0 5px, var(--color-1) -10px 10px 0 5px, var(--color-2) -10px -10px 0 5px, var(--color-3) 10px -10px 0 5px;
  }
  50% {
    box-shadow: var(--color-0) 10px 10px 0 5px, var(--color-1) -10px 10px 0 5px, var(--color-2) -10px -10px 0 5px, var(--color-3) 10px -10px 0 5px;
  }
  58.33% {
    box-shadow: var(--color-0) -10px 10px 0 5px, var(--color-1) -10px 10px 0 5px, var(--color-2) -10px -10px 0 5px, var(--color-3) 10px -10px 0 5px;
  }
  66.67% {
    box-shadow: var(--color-0) -10px -10px 0 5px, var(--color-1) -10px -10px 0 5px, var(--color-2) -10px -10px 0 5px, var(--color-3) 10px -10px 0 5px;
  }
  75% {
    box-shadow: var(--color-0) 10px -10px 0 5px, var(--color-1) 10px -10px 0 5px, var(--color-2) 10px -10px 0 5px, var(--color-3) 10px -10px 0 5px;
  }
  83.33% {
    box-shadow: var(--color-0) 10px 10px 0 5px, var(--color-1) 10px -10px 0 5px, var(--color-2) 10px 10px 0 5px, var(--color-3) 10px 10px 0 5px;
  }
  91.67% {
    box-shadow: var(--color-0) -10px 10px 0 5px, var(--color-1) 10px -10px 0 5px, var(--color-2) 10px 10px 0 5px, var(--color-3) -10px 10px 0 5px;
  }
  100% {
    box-shadow: var(--color-0) -10px -10px 0 5px, var(--color-1) 10px -10px 0 5px, var(--color-2) 10px 10px 0 5px, var(--color-3) -10px 10px 0 5px;
  }
}

.Loader {
  display: inline-block;
  width: 5px;
  height: 5px;
  box-shadow: var(--color-0) -10px -10px 0 5px, var(--color-1) 10px -10px 0 5px, var(--color-2) 10px 10px 0 5px, var(--color-3) -10px 10px 0 5px;
  animation: dots-loader 5s infinite ease-in-out;
  transform-origin: 50% 50%;
}
