.RouteContent {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.RouteContent__wrapped {
  min-width: 300px;
  max-width: 400px;
}

.RouteContent__content {
  background: rgba(255,255,255,0.8);
}

.RouteContent__content.RouteContent__wrapped {
  padding: 1.5em 1em;
}
