.Rematch {
  position: relative;
}

.Rematch__message {
  position: absolute;
  background: rgba(255, 255, 255, 0.9);
  padding: 2.5em;
  border-radius: 0.5em;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: flex;
  flex-direction: column;
  min-width: 300px;
  z-index: 1;
}

.Rematch__cta {
  margin-top: 1em;
}
