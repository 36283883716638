.Interaction {
  display: flex;
  cursor: pointer;
  width: 100%;
}

.Interaction__color {
  transition: all 100ms linear;
}

.Interaction__color:hover:not(:active) {
  opacity: 0.8;
  scale: 1.15;
}
