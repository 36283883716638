.Tile {
  width: 40px;
  height: 40px;
  position: absolute;
  transition-property: top, left, transform;
  transition-duration: 6s;
  transition-timing-function: linear;
  pointer-events: none;
}

@media screen and (max-width: 500px) {
  .Tile {
    transition-duration: 3s;
  }
}

@media screen and
  (prefers-reduced-motion: reduce),
  (update: slow) {
  * {
    transition-duration: 0.001ms !important;
  }
}
