html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-family: sans-serif;
}

body {
  margin: 0;
}

article, aside, footer, header, nav, section {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

figcaption, figure, main {
  display: block;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

a {
  -webkit-text-decoration-skip: objects;
  background-color: #0000;
}

a:active, a:hover {
  outline-width: 0;
}

address {
  font-style: normal;
}

b, strong {
  font-weight: inherit;
  font-weight: bolder;
}

code, kbd, pre, samp {
  font-family: SF Mono, Segoe UI Mono, Roboto Mono, Menlo, Courier, monospace;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

small {
  font-size: 80%;
  font-weight: 400;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

audio, video {
  display: inline-block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

[type="reset"], [type="submit"], button, html [type="button"] {
  -webkit-appearance: button;
}

[type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner, button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
  display: inline-block;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details, menu {
  display: block;
}

summary {
  outline: 0;
  display: list-item;
}

canvas {
  display: inline-block;
}

template, [hidden] {
  display: none;
}

*, :after, :before {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  font-size: 20px;
  line-height: 1.5;
}

body {
  color: #3b4351;
  text-rendering: optimizelegibility;
  background: #fff;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, sans-serif;
  font-size: .8rem;
  overflow-x: hidden;
}

a {
  color: #5755d9;
  outline: 0;
  text-decoration: none;
}

a:focus {
  box-shadow: 0 0 0 .1rem #5755d933;
}

a.active, a:active, a:focus, a:hover {
  color: #302ecd;
  text-decoration: underline;
}

a:visited {
  color: #807fe2;
}

h1, h2, h3, h4, h5, h6 {
  color: inherit;
  margin-top: 0;
  margin-bottom: .5em;
  font-weight: 500;
  line-height: 1.2;
}

.h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: 500;
}

.h1, h1 {
  font-size: 2rem;
}

.h2, h2 {
  font-size: 1.6rem;
}

.h3, h3 {
  font-size: 1.4rem;
}

.h4, h4 {
  font-size: 1.2rem;
}

.h5, h5 {
  font-size: 1rem;
}

.h6, h6 {
  font-size: .8rem;
}

p {
  margin: 0 0 1.2rem;
}

a, ins, u {
  -webkit-text-decoration-skip: ink edges;
  text-decoration-skip: ink edges;
}

abbr[title] {
  cursor: help;
  border-bottom: .05rem dotted;
  text-decoration: none;
}

kbd {
  color: #fff;
  background: #303742;
  border-radius: .1rem;
  padding: .1rem .2rem;
  font-size: .7rem;
  line-height: 1.25;
}

mark {
  color: #3b4351;
  background: #ffe9b3;
  border-bottom: .05rem solid #ffd367;
  border-radius: .1rem;
  padding: .05rem .1rem 0;
}

blockquote {
  border-left: .1rem solid #dadee4;
  margin-left: 0;
  padding: .4rem .8rem;
}

blockquote p:last-child {
  margin-bottom: 0;
}

ol, ul {
  margin: .8rem 0 .8rem .8rem;
  padding: 0;
}

ol ol, ol ul, ul ol, ul ul {
  margin: .8rem 0 .8rem .8rem;
}

ol li, ul li {
  margin-top: .4rem;
}

ul {
  list-style: inside;
}

ul ul {
  list-style-type: circle;
}

ol {
  list-style: decimal inside;
}

ol ol {
  list-style-type: lower-alpha;
}

dl dt {
  font-weight: 700;
}

dl dd {
  margin: .4rem 0 .8rem;
}

.lang-zh, .lang-zh-hans, html:lang(zh), html:lang(zh-Hans) {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, sans-serif;
}

.lang-zh-hant, html:lang(zh-Hant) {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, PingFang TC, Hiragino Sans CNS, Microsoft JhengHei, Helvetica Neue, sans-serif;
}

.lang-ja, html:lang(ja) {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Hiragino Sans, Hiragino Kaku Gothic Pro, Yu Gothic, YuGothic, Meiryo, Helvetica Neue, sans-serif;
}

.lang-ko, html:lang(ko) {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Malgun Gothic, Helvetica Neue, sans-serif;
}

.lang-cjk ins, .lang-cjk u, :lang(ja) ins, :lang(ja) u, :lang(zh) ins, :lang(zh) u {
  border-bottom: .05rem solid;
  text-decoration: none;
}

.lang-cjk del + del, .lang-cjk del + s, .lang-cjk ins + ins, .lang-cjk ins + u, .lang-cjk s + del, .lang-cjk s + s, .lang-cjk u + ins, .lang-cjk u + u, :lang(ja) del + del, :lang(ja) del + s, :lang(ja) ins + ins, :lang(ja) ins + u, :lang(ja) s + del, :lang(ja) s + s, :lang(ja) u + ins, :lang(ja) u + u, :lang(zh) del + del, :lang(zh) del + s, :lang(zh) ins + ins, :lang(zh) ins + u, :lang(zh) s + del, :lang(zh) s + s, :lang(zh) u + ins, :lang(zh) u + u {
  margin-left: .125em;
}

.table {
  border-collapse: collapse;
  border-spacing: 0;
  text-align: left;
  width: 100%;
}

.table.table-striped tbody tr:nth-of-type(2n+1) {
  background: #f7f8f9;
}

.table tbody tr.active, .table.table-striped tbody tr.active, .table.table-hover tbody tr:hover {
  background: #eef0f3;
}

.table.table-scroll {
  white-space: nowrap;
  padding-bottom: .75rem;
  display: block;
  overflow-x: auto;
}

.table td, .table th {
  border-bottom: .05rem solid #dadee4;
  padding: .6rem .4rem;
}

.table th {
  border-bottom-width: .1rem;
}

.btn {
  appearance: none;
  color: #5755d9;
  cursor: pointer;
  height: 1.8rem;
  text-align: center;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  background: #fff;
  border: .05rem solid #5755d9;
  border-radius: .1rem;
  outline: 0;
  padding: .25rem .4rem;
  font-size: .8rem;
  line-height: 1.2rem;
  text-decoration: none;
  transition: background .2s, border .2s, box-shadow .2s, color .2s;
  display: inline-block;
}

.btn:focus {
  box-shadow: 0 0 0 .1rem #5755d933;
}

.btn:focus, .btn:hover {
  background: #f1f1fc;
  border-color: #4b48d6;
  text-decoration: none;
}

.btn.active, .btn:active {
  color: #fff;
  background: #4b48d6;
  border-color: #3634d2;
  text-decoration: none;
}

.btn.active.loading:after, .btn:active.loading:after {
  border-bottom-color: #fff;
  border-left-color: #fff;
}

.btn.disabled, .btn:disabled, .btn[disabled] {
  cursor: default;
  opacity: .5;
  pointer-events: none;
}

.btn.btn-primary {
  color: #fff;
  background: #5755d9;
  border-color: #4b48d6;
}

.btn.btn-primary:focus, .btn.btn-primary:hover {
  color: #fff;
  background: #4240d4;
  border-color: #3634d2;
}

.btn.btn-primary.active, .btn.btn-primary:active {
  color: #fff;
  background: #3a38d2;
  border-color: #302ecd;
}

.btn.btn-primary.loading:after {
  border-bottom-color: #fff;
  border-left-color: #fff;
}

.btn.btn-success {
  color: #fff;
  background: #32b643;
  border-color: #2faa3f;
}

.btn.btn-success:focus {
  box-shadow: 0 0 0 .1rem #32b64333;
}

.btn.btn-success:focus, .btn.btn-success:hover {
  color: #fff;
  background: #30ae40;
  border-color: #2da23c;
}

.btn.btn-success.active, .btn.btn-success:active {
  color: #fff;
  background: #2a9a39;
  border-color: #278e34;
}

.btn.btn-success.loading:after {
  border-bottom-color: #fff;
  border-left-color: #fff;
}

.btn.btn-error {
  color: #fff;
  background: #e85600;
  border-color: #d95000;
}

.btn.btn-error:focus {
  box-shadow: 0 0 0 .1rem #e8560033;
}

.btn.btn-error:focus, .btn.btn-error:hover {
  color: #fff;
  background: #de5200;
  border-color: #cf4d00;
}

.btn.btn-error.active, .btn.btn-error:active {
  color: #fff;
  background: #c44900;
  border-color: #b54300;
}

.btn.btn-error.loading:after {
  border-bottom-color: #fff;
  border-left-color: #fff;
}

.btn.btn-link {
  color: #5755d9;
  background: none;
  border-color: #0000;
}

.btn.btn-link.active, .btn.btn-link:active, .btn.btn-link:focus, .btn.btn-link:hover {
  color: #302ecd;
}

.btn.btn-sm {
  height: 1.4rem;
  padding: .05rem .3rem;
  font-size: .7rem;
}

.btn.btn-lg {
  height: 2rem;
  padding: .35rem .6rem;
  font-size: .9rem;
}

.btn.btn-block {
  width: 100%;
  display: block;
}

.btn.btn-action {
  width: 1.8rem;
  padding-left: 0;
  padding-right: 0;
}

.btn.btn-action.btn-sm {
  width: 1.4rem;
}

.btn.btn-action.btn-lg {
  width: 2rem;
}

.btn.btn-clear {
  color: currentColor;
  height: 1rem;
  opacity: 1;
  width: 1rem;
  background: none;
  border: 0;
  margin-left: .2rem;
  margin-right: -2px;
  padding: .1rem;
  line-height: .8rem;
  text-decoration: none;
}

.btn.btn-clear:focus, .btn.btn-clear:hover {
  opacity: .95;
  background: #f7f8f980;
}

.btn.btn-clear:before {
  content: "✕";
}

.btn-group {
  flex-wrap: wrap;
  display: inline-flex;
}

.btn-group .btn {
  flex: 1 0 auto;
}

.btn-group .btn:first-child:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group .btn:not(:first-child):not(:last-child) {
  border-radius: 0;
  margin-left: -.05rem;
}

.btn-group .btn:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -.05rem;
}

.btn-group .btn.active, .btn-group .btn:active, .btn-group .btn:focus, .btn-group .btn:hover {
  z-index: 1;
}

.btn-group.btn-group-block {
  display: flex;
}

.btn-group.btn-group-block .btn {
  flex: 1 0 0;
}

.form-group:not(:last-child) {
  margin-bottom: .4rem;
}

fieldset {
  margin-bottom: .8rem;
}

legend {
  margin-bottom: .8rem;
  font-size: .9rem;
  font-weight: 500;
}

.form-label {
  padding: .3rem 0;
  line-height: 1.2rem;
  display: block;
}

.form-label.label-sm {
  padding: .1rem 0;
  font-size: .7rem;
}

.form-label.label-lg {
  padding: .4rem 0;
  font-size: .9rem;
}

.form-input {
  appearance: none;
  color: #3b4351;
  height: 1.8rem;
  max-width: 100%;
  width: 100%;
  background: #fff;
  border: .05rem solid #bcc3ce;
  border-radius: .1rem;
  outline: 0;
  padding: .25rem .4rem;
  font-size: .8rem;
  line-height: 1.2rem;
  transition: background .2s, border .2s, box-shadow .2s, color .2s;
  display: block;
  position: relative;
}

.form-input:focus {
  border-color: #5755d9;
  box-shadow: 0 0 0 .1rem #5755d933;
}

.form-input:-ms-input-placeholder {
  color: #bcc3ce;
}

.form-input::placeholder {
  color: #bcc3ce;
}

.form-input.input-sm {
  height: 1.4rem;
  padding: .05rem .3rem;
  font-size: .7rem;
}

.form-input.input-lg {
  height: 2rem;
  padding: .35rem .6rem;
  font-size: .9rem;
}

.form-input.input-inline {
  vertical-align: middle;
  width: auto;
  display: inline-block;
}

.form-input[type="file"], textarea.form-input, textarea.form-input.input-lg, textarea.form-input.input-sm {
  height: auto;
}

.form-input-hint {
  color: #bcc3ce;
  margin-top: .2rem;
  font-size: .7rem;
}

.has-success .form-input-hint, .is-success + .form-input-hint {
  color: #32b643;
}

.has-error .form-input-hint, .is-error + .form-input-hint {
  color: #e85600;
}

.form-select {
  appearance: none;
  color: inherit;
  height: 1.8rem;
  vertical-align: middle;
  width: 100%;
  background: #fff;
  border: .05rem solid #bcc3ce;
  border-radius: .1rem;
  outline: 0;
  padding: .25rem .4rem;
  font-size: .8rem;
  line-height: 1.2rem;
}

.form-select:focus {
  border-color: #5755d9;
  box-shadow: 0 0 0 .1rem #5755d933;
}

.form-select::-ms-expand {
  display: none;
}

.form-select.select-sm {
  height: 1.4rem;
  padding: .05rem 1.1rem .05rem .3rem;
  font-size: .7rem;
}

.form-select.select-lg {
  height: 2rem;
  padding: .35rem 1.4rem .35rem .6rem;
  font-size: .9rem;
}

.form-select[multiple], .form-select[size] {
  height: auto;
  padding: .25rem .4rem;
}

.form-select[multiple] option, .form-select[size] option {
  padding: .1rem .2rem;
}

.form-select:not([multiple]):not([size]) {
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%204%205'%3E%3Cpath%20fill='%23667189'%20d='M2%200L0%202h4zm0%205L0%203h4z'/%3E%3C/svg%3E") right .35rem center / .4rem .5rem no-repeat;
  padding-right: 1.2rem;
}

.has-icon-left, .has-icon-right {
  position: relative;
}

.has-icon-left .form-icon, .has-icon-right .form-icon {
  height: .8rem;
  width: .8rem;
  z-index: 2;
  margin: 0 .25rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.has-icon-left .form-icon {
  left: .05rem;
}

.has-icon-left .form-input {
  padding-left: 1.3rem;
}

.has-icon-right .form-icon {
  right: .05rem;
}

.has-icon-right .form-input {
  padding-right: 1.3rem;
}

.form-checkbox, .form-radio, .form-switch {
  min-height: 1.4rem;
  margin: .2rem 0;
  padding: .1rem .4rem .1rem 1.2rem;
  line-height: 1.2rem;
  display: block;
  position: relative;
}

.form-checkbox input, .form-radio input, .form-switch input {
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  position: absolute;
  overflow: hidden;
}

.form-checkbox input:focus + .form-icon, .form-radio input:focus + .form-icon, .form-switch input:focus + .form-icon {
  border-color: #5755d9;
  box-shadow: 0 0 0 .1rem #5755d933;
}

.form-checkbox input:checked + .form-icon, .form-radio input:checked + .form-icon, .form-switch input:checked + .form-icon {
  background: #5755d9;
  border-color: #5755d9;
}

.form-checkbox .form-icon, .form-radio .form-icon, .form-switch .form-icon {
  cursor: pointer;
  border: .05rem solid #bcc3ce;
  transition: background .2s, border .2s, box-shadow .2s, color .2s;
  display: inline-block;
  position: absolute;
}

.form-checkbox.input-sm, .form-radio.input-sm, .form-switch.input-sm {
  margin: 0;
  font-size: .7rem;
}

.form-checkbox.input-lg, .form-radio.input-lg, .form-switch.input-lg {
  margin: .3rem 0;
  font-size: .9rem;
}

.form-checkbox .form-icon, .form-radio .form-icon {
  height: .8rem;
  width: .8rem;
  background: #fff;
  top: .3rem;
  left: 0;
}

.form-checkbox input:active + .form-icon, .form-radio input:active + .form-icon {
  background: #eef0f3;
}

.form-checkbox .form-icon {
  border-radius: .1rem;
}

.form-checkbox input:checked + .form-icon:before {
  content: "";
  height: 9px;
  width: 6px;
  background-clip: padding-box;
  border: .1rem solid #fff;
  border-width: 0 .1rem .1rem 0;
  margin-top: -6px;
  margin-left: -3px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: rotate(45deg);
}

.form-checkbox input:indeterminate + .form-icon {
  background: #5755d9;
  border-color: #5755d9;
}

.form-checkbox input:indeterminate + .form-icon:before {
  content: "";
  height: 2px;
  width: 10px;
  background: #fff;
  margin-top: -1px;
  margin-left: -5px;
  position: absolute;
  top: 50%;
  left: 50%;
}

.form-radio .form-icon {
  border-radius: 50%;
}

.form-radio input:checked + .form-icon:before {
  content: "";
  height: 6px;
  width: 6px;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.form-switch {
  padding-left: 2rem;
}

.form-switch .form-icon {
  height: .9rem;
  width: 1.6rem;
  background: #bcc3ce padding-box padding-box;
  border-radius: .45rem;
  top: .25rem;
  left: 0;
}

.form-switch .form-icon:before {
  content: "";
  height: .8rem;
  width: .8rem;
  background: #fff;
  border-radius: 50%;
  transition: background .2s, border .2s, box-shadow .2s, color .2s, left .2s;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.form-switch input:checked + .form-icon:before {
  left: 14px;
}

.form-switch input:active + .form-icon:before {
  background: #f7f8f9;
}

.input-group {
  display: flex;
}

.input-group .input-group-addon {
  white-space: nowrap;
  background: #f7f8f9;
  border: .05rem solid #bcc3ce;
  border-radius: .1rem;
  padding: .25rem .4rem;
  line-height: 1.2rem;
}

.input-group .input-group-addon.addon-sm {
  padding: .05rem .3rem;
  font-size: .7rem;
}

.input-group .input-group-addon.addon-lg {
  padding: .35rem .6rem;
  font-size: .9rem;
}

.input-group .form-input, .input-group .form-select {
  width: 1%;
  flex: auto;
}

.input-group .input-group-btn {
  z-index: 1;
}

.input-group .form-input:first-child:not(:last-child), .input-group .form-select:first-child:not(:last-child), .input-group .input-group-addon:first-child:not(:last-child), .input-group .input-group-btn:first-child:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group .form-input:not(:first-child):not(:last-child), .input-group .form-select:not(:first-child):not(:last-child), .input-group .input-group-addon:not(:first-child):not(:last-child), .input-group .input-group-btn:not(:first-child):not(:last-child) {
  border-radius: 0;
  margin-left: -.05rem;
}

.input-group .form-input:last-child:not(:first-child), .input-group .form-select:last-child:not(:first-child), .input-group .input-group-addon:last-child:not(:first-child), .input-group .input-group-btn:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -.05rem;
}

.input-group .form-input:focus, .input-group .form-select:focus, .input-group .input-group-addon:focus, .input-group .input-group-btn:focus {
  z-index: 2;
}

.input-group .form-select {
  width: auto;
}

.input-group.input-inline {
  display: inline-flex;
}

.form-input.is-success, .form-select.is-success, .has-success .form-input, .has-success .form-select {
  background: #f9fdfa;
  border-color: #32b643;
}

.form-input.is-success:focus, .form-select.is-success:focus, .has-success .form-input:focus, .has-success .form-select:focus {
  box-shadow: 0 0 0 .1rem #32b64333;
}

.form-input.is-error, .form-select.is-error, .has-error .form-input, .has-error .form-select {
  background: #fffaf7;
  border-color: #e85600;
}

.form-input.is-error:focus, .form-select.is-error:focus, .has-error .form-input:focus, .has-error .form-select:focus {
  box-shadow: 0 0 0 .1rem #e8560033;
}

.form-checkbox.is-error .form-icon, .form-radio.is-error .form-icon, .form-switch.is-error .form-icon, .has-error .form-checkbox .form-icon, .has-error .form-radio .form-icon, .has-error .form-switch .form-icon {
  border-color: #e85600;
}

.form-checkbox.is-error input:checked + .form-icon, .form-radio.is-error input:checked + .form-icon, .form-switch.is-error input:checked + .form-icon, .has-error .form-checkbox input:checked + .form-icon, .has-error .form-radio input:checked + .form-icon, .has-error .form-switch input:checked + .form-icon {
  background: #e85600;
  border-color: #e85600;
}

.form-checkbox.is-error input:focus + .form-icon, .form-radio.is-error input:focus + .form-icon, .form-switch.is-error input:focus + .form-icon, .has-error .form-checkbox input:focus + .form-icon, .has-error .form-radio input:focus + .form-icon, .has-error .form-switch input:focus + .form-icon {
  border-color: #e85600;
  box-shadow: 0 0 0 .1rem #e8560033;
}

.form-checkbox.is-error input:indeterminate + .form-icon, .has-error .form-checkbox input:indeterminate + .form-icon {
  background: #e85600;
  border-color: #e85600;
}

.form-input:not(:-ms-input-placeholder):invalid, .form-input:not(:placeholder-shown):invalid {
  border-color: #e85600;
}

.form-input:not(:-ms-input-placeholder):invalid:focus, .form-input:not(:placeholder-shown):invalid:focus {
  background: #fffaf7;
  box-shadow: 0 0 0 .1rem #e8560033;
}

.form-input:not(:-ms-input-placeholder):invalid + .form-input-hint, .form-input:not(:placeholder-shown):invalid + .form-input-hint {
  color: #e85600;
}

.form-input.disabled, .form-input:disabled, .form-select.disabled, .form-select:disabled {
  cursor: not-allowed;
  opacity: .5;
  background-color: #eef0f3;
}

.form-input[readonly] {
  background-color: #f7f8f9;
}

input.disabled + .form-icon, input:disabled + .form-icon {
  cursor: not-allowed;
  opacity: .5;
  background: #eef0f3;
}

.form-switch input.disabled + .form-icon:before, .form-switch input:disabled + .form-icon:before {
  background: #fff;
}

.form-horizontal {
  padding: .4rem 0;
}

.form-horizontal .form-group {
  flex-wrap: wrap;
  display: flex;
}

.form-inline {
  display: inline-block;
}

.label {
  color: #455060;
  background: #eef0f3;
  border-radius: .1rem;
  padding: .1rem .2rem;
  line-height: 1.25;
  display: inline-block;
}

.label.label-rounded {
  border-radius: 5rem;
  padding-left: .4rem;
  padding-right: .4rem;
}

.label.label-primary {
  color: #fff;
  background: #5755d9;
}

.label.label-secondary {
  color: #5755d9;
  background: #f1f1fc;
}

.label.label-success {
  color: #fff;
  background: #32b643;
}

.label.label-warning {
  color: #fff;
  background: #ffb700;
}

.label.label-error {
  color: #fff;
  background: #e85600;
}

code {
  color: #d73e48;
  background: #fcf2f2;
  border-radius: .1rem;
  padding: .1rem .2rem;
  font-size: 85%;
  line-height: 1.25;
}

.code {
  color: #3b4351;
  border-radius: .1rem;
  position: relative;
}

.code:before {
  color: #bcc3ce;
  content: attr(data-lang);
  font-size: .7rem;
  position: absolute;
  top: .1rem;
  right: .4rem;
}

.code code {
  color: inherit;
  width: 100%;
  background: #f7f8f9;
  padding: 1rem;
  line-height: 1.5;
  display: block;
  overflow-x: auto;
}

.img-responsive {
  height: auto;
  max-width: 100%;
  display: block;
}

.img-fit-cover {
  object-fit: cover;
}

.img-fit-contain {
  object-fit: contain;
}

.video-responsive {
  width: 100%;
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.video-responsive:before {
  content: "";
  padding-bottom: 56.25%;
  display: block;
}

.video-responsive embed, .video-responsive iframe, .video-responsive object {
  height: 100%;
  width: 100%;
  border: 0;
  position: absolute;
  inset: 0;
}

video.video-responsive {
  height: auto;
  max-width: 100%;
}

video.video-responsive:before {
  content: none;
}

.video-responsive-4-3:before {
  padding-bottom: 75%;
}

.video-responsive-1-1:before {
  padding-bottom: 100%;
}

.figure {
  margin: 0 0 .4rem;
}

.figure .figure-caption {
  color: #66758c;
  margin-top: .4rem;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: .4rem;
  padding-right: .4rem;
}

.container.grid-xl {
  max-width: 1296px;
}

.container.grid-lg {
  max-width: 976px;
}

.container.grid-md {
  max-width: 856px;
}

.container.grid-sm {
  max-width: 616px;
}

.container.grid-xs {
  max-width: 496px;
}

.show-lg, .show-md, .show-sm, .show-xl, .show-xs {
  display: none !important;
}

.cols, .columns {
  flex-wrap: wrap;
  margin-left: -.4rem;
  margin-right: -.4rem;
  display: flex;
}

.cols.col-gapless, .columns.col-gapless {
  margin-left: 0;
  margin-right: 0;
}

.cols.col-gapless > .column, .columns.col-gapless > .column {
  padding-left: 0;
  padding-right: 0;
}

.cols.col-oneline, .columns.col-oneline {
  flex-wrap: nowrap;
  overflow-x: auto;
}

.column, [class~="col-"] {
  max-width: 100%;
  flex: 1;
  padding-left: .4rem;
  padding-right: .4rem;
}

.column.col-1, .column.col-10, .column.col-11, .column.col-12, .column.col-2, .column.col-3, .column.col-4, .column.col-5, .column.col-6, .column.col-7, .column.col-8, .column.col-9, .column.col-auto, [class~="col-"].col-1, [class~="col-"].col-10, [class~="col-"].col-11, [class~="col-"].col-12, [class~="col-"].col-2, [class~="col-"].col-3, [class~="col-"].col-4, [class~="col-"].col-5, [class~="col-"].col-6, [class~="col-"].col-7, [class~="col-"].col-8, [class~="col-"].col-9, [class~="col-"].col-auto {
  flex: none;
}

.col-12 {
  width: 100%;
}

.col-11 {
  width: 91.6667%;
}

.col-10 {
  width: 83.3333%;
}

.col-9 {
  width: 75%;
}

.col-8 {
  width: 66.6667%;
}

.col-7 {
  width: 58.3333%;
}

.col-6 {
  width: 50%;
}

.col-5 {
  width: 41.6667%;
}

.col-4 {
  width: 33.3333%;
}

.col-3 {
  width: 25%;
}

.col-2 {
  width: 16.6667%;
}

.col-1 {
  width: 8.33333%;
}

.col-auto {
  max-width: none;
  width: auto;
  flex: none;
}

.col-mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.col-ml-auto {
  margin-left: auto;
}

.col-mr-auto {
  margin-right: auto;
}

@media (max-width: 1280px) {
  .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    flex: none;
  }

  .col-xl-12 {
    width: 100%;
  }

  .col-xl-11 {
    width: 91.6667%;
  }

  .col-xl-10 {
    width: 83.3333%;
  }

  .col-xl-9 {
    width: 75%;
  }

  .col-xl-8 {
    width: 66.6667%;
  }

  .col-xl-7 {
    width: 58.3333%;
  }

  .col-xl-6 {
    width: 50%;
  }

  .col-xl-5 {
    width: 41.6667%;
  }

  .col-xl-4 {
    width: 33.3333%;
  }

  .col-xl-3 {
    width: 25%;
  }

  .col-xl-2 {
    width: 16.6667%;
  }

  .col-xl-1 {
    width: 8.33333%;
  }

  .col-xl-auto {
    width: auto;
  }

  .hide-xl {
    display: none !important;
  }

  .show-xl {
    display: block !important;
  }
}

@media (max-width: 960px) {
  .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto {
    flex: none;
  }

  .col-lg-12 {
    width: 100%;
  }

  .col-lg-11 {
    width: 91.6667%;
  }

  .col-lg-10 {
    width: 83.3333%;
  }

  .col-lg-9 {
    width: 75%;
  }

  .col-lg-8 {
    width: 66.6667%;
  }

  .col-lg-7 {
    width: 58.3333%;
  }

  .col-lg-6 {
    width: 50%;
  }

  .col-lg-5 {
    width: 41.6667%;
  }

  .col-lg-4 {
    width: 33.3333%;
  }

  .col-lg-3 {
    width: 25%;
  }

  .col-lg-2 {
    width: 16.6667%;
  }

  .col-lg-1 {
    width: 8.33333%;
  }

  .col-lg-auto {
    width: auto;
  }

  .hide-lg {
    display: none !important;
  }

  .show-lg {
    display: block !important;
  }
}

@media (max-width: 840px) {
  .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto {
    flex: none;
  }

  .col-md-12 {
    width: 100%;
  }

  .col-md-11 {
    width: 91.6667%;
  }

  .col-md-10 {
    width: 83.3333%;
  }

  .col-md-9 {
    width: 75%;
  }

  .col-md-8 {
    width: 66.6667%;
  }

  .col-md-7 {
    width: 58.3333%;
  }

  .col-md-6 {
    width: 50%;
  }

  .col-md-5 {
    width: 41.6667%;
  }

  .col-md-4 {
    width: 33.3333%;
  }

  .col-md-3 {
    width: 25%;
  }

  .col-md-2 {
    width: 16.6667%;
  }

  .col-md-1 {
    width: 8.33333%;
  }

  .col-md-auto {
    width: auto;
  }

  .hide-md {
    display: none !important;
  }

  .show-md {
    display: block !important;
  }
}

@media (max-width: 600px) {
  .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto {
    flex: none;
  }

  .col-sm-12 {
    width: 100%;
  }

  .col-sm-11 {
    width: 91.6667%;
  }

  .col-sm-10 {
    width: 83.3333%;
  }

  .col-sm-9 {
    width: 75%;
  }

  .col-sm-8 {
    width: 66.6667%;
  }

  .col-sm-7 {
    width: 58.3333%;
  }

  .col-sm-6 {
    width: 50%;
  }

  .col-sm-5 {
    width: 41.6667%;
  }

  .col-sm-4 {
    width: 33.3333%;
  }

  .col-sm-3 {
    width: 25%;
  }

  .col-sm-2 {
    width: 16.6667%;
  }

  .col-sm-1 {
    width: 8.33333%;
  }

  .col-sm-auto {
    width: auto;
  }

  .hide-sm {
    display: none !important;
  }

  .show-sm {
    display: block !important;
  }
}

@media (max-width: 480px) {
  .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-auto {
    flex: none;
  }

  .col-xs-12 {
    width: 100%;
  }

  .col-xs-11 {
    width: 91.6667%;
  }

  .col-xs-10 {
    width: 83.3333%;
  }

  .col-xs-9 {
    width: 75%;
  }

  .col-xs-8 {
    width: 66.6667%;
  }

  .col-xs-7 {
    width: 58.3333%;
  }

  .col-xs-6 {
    width: 50%;
  }

  .col-xs-5 {
    width: 41.6667%;
  }

  .col-xs-4 {
    width: 33.3333%;
  }

  .col-xs-3 {
    width: 25%;
  }

  .col-xs-2 {
    width: 16.6667%;
  }

  .col-xs-1 {
    width: 8.33333%;
  }

  .col-xs-auto {
    width: auto;
  }

  .hide-xs {
    display: none !important;
  }

  .show-xs {
    display: block !important;
  }
}

.hero {
  flex-direction: column;
  justify-content: space-between;
  padding-top: 4rem;
  padding-bottom: 4rem;
  display: flex;
}

.hero.hero-sm {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.hero.hero-lg {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.hero .hero-body {
  padding: .4rem;
}

.navbar {
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  justify-content: space-between;
  align-items: stretch;
  display: flex;
}

.navbar .navbar-section {
  flex: 1 0 0;
  -ms-flex-align: center;
  align-items: center;
  display: flex;
}

.navbar .navbar-section:not(:first-child):last-child {
  justify-content: flex-end;
}

.navbar .navbar-center {
  flex: none;
  -ms-flex-align: center;
  align-items: center;
  display: flex;
}

.navbar .navbar-brand {
  font-size: .9rem;
  text-decoration: none;
}

.accordion input:checked ~ .accordion-header > .icon:first-child, .accordion[open] .accordion-header > .icon:first-child {
  transform: rotate(90deg);
}

.accordion input:checked ~ .accordion-body, .accordion[open] .accordion-body {
  max-height: 50rem;
}

.accordion .accordion-header {
  padding: .2rem .4rem;
  display: block;
}

.accordion .accordion-header .icon {
  transition: transform .25s;
}

.accordion .accordion-body {
  max-height: 0;
  margin-bottom: .4rem;
  transition: max-height .25s;
  overflow: hidden;
}

summary.accordion-header::-webkit-details-marker {
  display: none;
}

.avatar {
  color: #ffffffd9;
  height: 1.6rem;
  vertical-align: middle;
  width: 1.6rem;
  background: #5755d9;
  border-radius: 50%;
  margin: 0;
  font-size: .8rem;
  font-weight: 300;
  line-height: 1.25;
  display: inline-block;
  position: relative;
}

.avatar.avatar-xs {
  height: .8rem;
  width: .8rem;
  font-size: .4rem;
}

.avatar.avatar-sm {
  height: 1.2rem;
  width: 1.2rem;
  font-size: .6rem;
}

.avatar.avatar-lg {
  height: 2.4rem;
  width: 2.4rem;
  font-size: 1.2rem;
}

.avatar.avatar-xl {
  height: 3.2rem;
  width: 3.2rem;
  font-size: 1.6rem;
}

.avatar img {
  height: 100%;
  width: 100%;
  z-index: 1;
  border-radius: 50%;
  position: relative;
}

.avatar .avatar-icon, .avatar .avatar-presence {
  height: 50%;
  width: 50%;
  z-index: 2;
  background: #fff;
  padding: .1rem;
  position: absolute;
  bottom: 14.64%;
  right: 14.64%;
  transform: translate(50%, 50%);
}

.avatar .avatar-presence {
  height: .5em;
  width: .5em;
  background: #bcc3ce;
  border-radius: 50%;
  box-shadow: 0 0 0 .1rem #fff;
}

.avatar .avatar-presence.online {
  background: #32b643;
}

.avatar .avatar-presence.busy {
  background: #e85600;
}

.avatar .avatar-presence.away {
  background: #ffb700;
}

.avatar[data-initial]:before {
  color: currentColor;
  content: attr(data-initial);
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.badge {
  white-space: nowrap;
  position: relative;
}

.badge:not([data-badge]):after, .badge[data-badge]:after {
  color: #fff;
  content: attr(data-badge);
  background: #5755d9 padding-box padding-box;
  border-radius: .5rem;
  display: inline-block;
  transform: translate(-.05rem, -.5rem);
  box-shadow: 0 0 0 .1rem #fff;
}

.badge[data-badge]:after {
  height: .9rem;
  min-width: .9rem;
  text-align: center;
  white-space: nowrap;
  padding: .1rem .2rem;
  font-size: .7rem;
  line-height: 1;
}

.badge:not([data-badge]):after, .badge[data-badge=""]:after {
  height: 6px;
  min-width: 6px;
  width: 6px;
  padding: 0;
}

.badge.btn:after {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
}

.badge.avatar:after {
  z-index: 100;
  position: absolute;
  top: 14.64%;
  right: 14.64%;
  transform: translate(50%, -50%);
}

.breadcrumb {
  margin: .2rem 0;
  padding: .2rem 0;
  list-style: none;
}

.breadcrumb .breadcrumb-item {
  color: #66758c;
  margin: 0;
  padding: .2rem 0;
  display: inline-block;
}

.breadcrumb .breadcrumb-item:not(:last-child) {
  margin-right: .2rem;
}

.breadcrumb .breadcrumb-item:not(:last-child) a {
  color: #66758c;
}

.breadcrumb .breadcrumb-item:not(:first-child):before {
  color: #66758c;
  content: "/";
  padding-right: .4rem;
}

.bar {
  height: .8rem;
  width: 100%;
  background: #eef0f3;
  border-radius: .1rem;
  flex-wrap: nowrap;
  display: flex;
}

.bar.bar-sm {
  height: .2rem;
}

.bar .bar-item {
  color: #fff;
  height: 100%;
  text-align: center;
  width: 0;
  background: #5755d9;
  flex-shrink: 0;
  font-size: .7rem;
  line-height: .8rem;
  display: block;
  position: relative;
}

.bar .bar-item:first-child {
  border-top-left-radius: .1rem;
  border-bottom-left-radius: .1rem;
}

.bar .bar-item:last-child {
  border-top-right-radius: .1rem;
  border-bottom-right-radius: .1rem;
  flex-shrink: 1;
}

.bar-slider {
  height: .1rem;
  margin: .4rem 0;
  position: relative;
}

.bar-slider .bar-item {
  padding: 0;
  position: absolute;
  left: 0;
}

.bar-slider .bar-item:not(:last-child):first-child {
  z-index: 1;
  background: #eef0f3;
}

.bar-slider .bar-slider-btn {
  height: .6rem;
  width: .6rem;
  background: #5755d9;
  border: 0;
  border-radius: 50%;
  padding: 0;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(50%, -50%);
}

.bar-slider .bar-slider-btn:active {
  box-shadow: 0 0 0 .1rem #5755d9;
}

.card {
  background: #fff;
  border: .05rem solid #dadee4;
  border-radius: .1rem;
  flex-direction: column;
  display: flex;
}

.card .card-body, .card .card-footer, .card .card-header {
  padding: .8rem .8rem 0;
}

.card .card-body:last-child, .card .card-footer:last-child, .card .card-header:last-child {
  padding-bottom: .8rem;
}

.card .card-body {
  flex: auto;
}

.card .card-image {
  padding-top: .8rem;
}

.card .card-image:first-child {
  padding-top: 0;
}

.card .card-image:first-child img {
  border-top-left-radius: .1rem;
  border-top-right-radius: .1rem;
}

.card .card-image:last-child img {
  border-bottom-left-radius: .1rem;
  border-bottom-right-radius: .1rem;
}

.chip {
  height: 1.2rem;
  max-width: 320px;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
  background: #eef0f3;
  border-radius: 5rem;
  -ms-flex-align: center;
  align-items: center;
  margin: .1rem;
  padding: .2rem .4rem;
  font-size: 90%;
  line-height: .8rem;
  text-decoration: none;
  display: inline-flex;
  overflow: hidden;
}

.chip.active {
  color: #fff;
  background: #5755d9;
}

.chip .avatar {
  margin-left: -.4rem;
  margin-right: .2rem;
}

.chip .btn-clear {
  border-radius: 50%;
  transform: scale(.75);
}

.dropdown {
  display: inline-block;
  position: relative;
}

.dropdown .menu {
  max-height: 50vh;
  animation: .15s slide-down;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  overflow-y: auto;
}

.dropdown.dropdown-right .menu {
  left: auto;
  right: 0;
}

.dropdown .dropdown-toggle:focus + .menu, .dropdown .menu:hover, .dropdown.active .menu {
  display: block;
}

.dropdown .btn-group .dropdown-toggle:nth-last-child(2) {
  border-top-right-radius: .1rem;
  border-bottom-right-radius: .1rem;
}

.empty {
  color: #66758c;
  text-align: center;
  background: #f7f8f9;
  border-radius: .1rem;
  padding: 3.2rem 1.6rem;
}

.empty .empty-icon {
  margin-bottom: .8rem;
}

.empty .empty-subtitle, .empty .empty-title {
  margin: .4rem auto;
}

.empty .empty-action {
  margin-top: .8rem;
}

.menu {
  min-width: 180px;
  z-index: 300;
  background: #fff;
  border-radius: .1rem;
  margin: 0;
  padding: .4rem;
  list-style: none;
  transform: translateY(.2rem);
  box-shadow: 0 .05rem .2rem #3037424d;
}

.menu.menu-nav {
  box-shadow: none;
  background: none;
}

.menu .menu-item {
  margin-top: 0;
  padding: 0 .4rem;
  text-decoration: none;
  position: relative;
}

.menu .menu-item > a {
  color: inherit;
  border-radius: .1rem;
  margin: 0 -.4rem;
  padding: .2rem .4rem;
  text-decoration: none;
  display: block;
}

.menu .menu-item > a:focus, .menu .menu-item > a:hover, .menu .menu-item > a.active, .menu .menu-item > a:active {
  color: #5755d9;
  background: #f1f1fc;
}

.menu .menu-item .form-checkbox, .menu .menu-item .form-radio, .menu .menu-item .form-switch {
  margin: .1rem 0;
}

.menu .menu-item + .menu-item {
  margin-top: .2rem;
}

.menu .menu-badge {
  height: 100%;
  -ms-flex-align: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

.menu .menu-badge .label {
  margin-right: .4rem;
}

.modal {
  opacity: 0;
  -ms-flex-align: center;
  justify-content: center;
  align-items: center;
  padding: .4rem;
  display: none;
  position: fixed;
  inset: 0;
  overflow: hidden;
}

.modal.active, .modal:target {
  opacity: 1;
  z-index: 400;
  display: flex;
}

.modal.active .modal-overlay, .modal:target .modal-overlay {
  cursor: default;
  background: #f7f8f9bf;
  display: block;
  position: absolute;
  inset: 0;
}

.modal.active .modal-container, .modal:target .modal-container {
  z-index: 1;
  animation: .2s slide-down;
}

.modal.modal-sm .modal-container {
  max-width: 320px;
  padding: 0 .4rem;
}

.modal.modal-lg .modal-overlay {
  background: #fff;
}

.modal.modal-lg .modal-container {
  box-shadow: none;
  max-width: 960px;
}

.modal-container {
  max-height: 75vh;
  max-width: 640px;
  width: 100%;
  background: #fff;
  border-radius: .1rem;
  flex-direction: column;
  padding: 0 .8rem;
  display: flex;
  box-shadow: 0 .2rem .5rem #3037424d;
}

.modal-container.modal-fullheight {
  max-height: 100vh;
}

.modal-container .modal-header {
  color: #303742;
  padding: .8rem;
}

.modal-container .modal-body {
  padding: .8rem;
  position: relative;
  overflow-y: auto;
}

.modal-container .modal-footer {
  text-align: right;
  padding: .8rem;
}

.nav {
  flex-direction: column;
  margin: .2rem 0;
  list-style: none;
  display: flex;
}

.nav .nav-item a {
  color: #66758c;
  padding: .2rem .4rem;
  text-decoration: none;
}

.nav .nav-item a:focus, .nav .nav-item a:hover {
  color: #5755d9;
}

.nav .nav-item.active > a {
  color: #505c6e;
  font-weight: 700;
}

.nav .nav-item.active > a:focus, .nav .nav-item.active > a:hover {
  color: #5755d9;
}

.nav .nav {
  margin-bottom: .4rem;
  margin-left: .8rem;
}

.pagination {
  margin: .2rem 0;
  padding: .2rem 0;
  list-style: none;
  display: flex;
}

.pagination .page-item {
  margin: .2rem .05rem;
}

.pagination .page-item span {
  padding: .2rem;
  display: inline-block;
}

.pagination .page-item a {
  border-radius: .1rem;
  padding: .2rem .4rem;
  text-decoration: none;
  display: inline-block;
}

.pagination .page-item a:focus, .pagination .page-item a:hover {
  color: #5755d9;
}

.pagination .page-item.disabled a {
  cursor: default;
  opacity: .5;
  pointer-events: none;
}

.pagination .page-item.active a {
  color: #fff;
  background: #5755d9;
}

.pagination .page-item.page-next, .pagination .page-item.page-prev {
  flex: 1 0 50%;
}

.pagination .page-item.page-next {
  text-align: right;
}

.pagination .page-item .page-item-title {
  margin: 0;
}

.pagination .page-item .page-item-subtitle {
  opacity: .5;
  margin: 0;
}

.panel {
  border: .05rem solid #dadee4;
  border-radius: .1rem;
  flex-direction: column;
  display: flex;
}

.panel .panel-footer, .panel .panel-header {
  flex: none;
  padding: .8rem;
}

.panel .panel-nav {
  flex: none;
}

.panel .panel-body {
  flex: auto;
  padding: 0 .8rem;
  overflow-y: auto;
}

.popover {
  display: inline-block;
  position: relative;
}

.popover .popover-container {
  opacity: 0;
  width: 320px;
  z-index: 300;
  padding: .4rem;
  transition: transform .2s;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%)scale(0);
}

.popover :focus + .popover-container, .popover:hover .popover-container {
  opacity: 1;
  display: block;
  transform: translate(-50%, -100%)scale(1);
}

.popover.popover-right .popover-container {
  top: 50%;
  left: 100%;
}

.popover.popover-right :focus + .popover-container, .popover.popover-right:hover .popover-container {
  transform: translate(0, -50%)scale(1);
}

.popover.popover-bottom .popover-container {
  top: 100%;
  left: 50%;
}

.popover.popover-bottom :focus + .popover-container, .popover.popover-bottom:hover .popover-container {
  transform: translate(-50%)scale(1);
}

.popover.popover-left .popover-container {
  top: 50%;
  left: 0;
}

.popover.popover-left :focus + .popover-container, .popover.popover-left:hover .popover-container {
  transform: translate(-100%, -50%)scale(1);
}

.popover .card {
  border: 0;
  box-shadow: 0 .2rem .5rem #3037424d;
}

.step {
  width: 100%;
  flex-wrap: nowrap;
  margin: .2rem 0;
  list-style: none;
  display: flex;
}

.step .step-item {
  min-height: 1rem;
  text-align: center;
  flex: 1 1 0;
  margin-top: 0;
  position: relative;
}

.step .step-item:not(:first-child):before {
  content: "";
  height: 2px;
  width: 100%;
  background: #5755d9;
  position: absolute;
  top: 9px;
  left: -50%;
}

.step .step-item a {
  color: #5755d9;
  padding: 20px 10px 0;
  text-decoration: none;
  display: inline-block;
}

.step .step-item a:before {
  content: "";
  height: .6rem;
  width: .6rem;
  z-index: 1;
  background: #5755d9;
  border: .1rem solid #fff;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: .2rem;
  left: 50%;
  transform: translateX(-50%);
}

.step .step-item.active a:before {
  background: #fff;
  border: .1rem solid #5755d9;
}

.step .step-item.active ~ .step-item:before {
  background: #dadee4;
}

.step .step-item.active ~ .step-item a {
  color: #bcc3ce;
}

.step .step-item.active ~ .step-item a:before {
  background: #dadee4;
}

.tab {
  border-bottom: .05rem solid #dadee4;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  margin: .2rem 0 .15rem;
  list-style: none;
  display: flex;
}

.tab .tab-item {
  margin-top: 0;
}

.tab .tab-item a {
  color: inherit;
  border-bottom: .1rem solid #0000;
  margin: 0 .4rem 0 0;
  padding: .4rem .2rem .3rem;
  text-decoration: none;
  display: block;
}

.tab .tab-item a:focus, .tab .tab-item a:hover {
  color: #5755d9;
}

.tab .tab-item a.active, .tab .tab-item.active a {
  color: #5755d9;
  border-bottom-color: #5755d9;
}

.tab .tab-item.tab-action {
  text-align: right;
  flex: 1 0 auto;
}

.tab .tab-item .btn-clear {
  margin-top: -.2rem;
}

.tab.tab-block .tab-item {
  text-align: center;
  flex: 1 0 0;
}

.tab.tab-block .tab-item a {
  margin: 0;
}

.tab.tab-block .tab-item .badge[data-badge]:after {
  position: absolute;
  top: .1rem;
  right: .1rem;
  transform: translate(0);
}

.tab:not(.tab-block) .badge {
  padding-right: 0;
}

.tile {
  -ms-flex-align: start;
  -ms-flex-line-pack: justify;
  align-content: space-between;
  align-items: flex-start;
  display: flex;
}

.tile .tile-action, .tile .tile-icon {
  flex: none;
}

.tile .tile-content {
  flex: auto;
}

.tile .tile-content:not(:first-child) {
  padding-left: .4rem;
}

.tile .tile-content:not(:last-child) {
  padding-right: .4rem;
}

.tile .tile-subtitle, .tile .tile-title {
  line-height: 1.2rem;
}

.tile.tile-centered {
  -ms-flex-align: center;
  align-items: center;
}

.tile.tile-centered .tile-content {
  overflow: hidden;
}

.tile.tile-centered .tile-subtitle, .tile.tile-centered .tile-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0;
  overflow: hidden;
}

.toast {
  color: #fff;
  width: 100%;
  background: #303742f2;
  border: .05rem solid #303742;
  border-radius: .1rem;
  padding: .4rem;
  display: block;
}

.toast.toast-primary {
  background: #5755d9f2;
  border-color: #5755d9;
}

.toast.toast-success {
  background: #32b643f2;
  border-color: #32b643;
}

.toast.toast-warning {
  background: #ffb700f2;
  border-color: #ffb700;
}

.toast.toast-error {
  background: #e85600f2;
  border-color: #e85600;
}

.toast a {
  color: #fff;
  text-decoration: underline;
}

.toast a.active, .toast a:active, .toast a:focus, .toast a:hover {
  opacity: .75;
}

.toast .btn-clear {
  margin: .1rem;
}

.toast p:last-child {
  margin-bottom: 0;
}

.tooltip {
  position: relative;
}

.tooltip:after {
  color: #fff;
  content: attr(data-tooltip);
  max-width: 320px;
  opacity: 0;
  pointer-events: none;
  text-overflow: ellipsis;
  white-space: pre;
  z-index: 300;
  background: #303742f2;
  border-radius: .1rem;
  padding: .2rem .4rem;
  font-size: .7rem;
  transition: opacity .2s, transform .2s;
  display: block;
  position: absolute;
  bottom: 100%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, .4rem);
}

.tooltip:focus:after, .tooltip:hover:after {
  opacity: 1;
  transform: translate(-50%, -.2rem);
}

.tooltip.disabled, .tooltip[disabled] {
  pointer-events: auto;
}

.tooltip.tooltip-right:after {
  bottom: 50%;
  left: 100%;
  transform: translate(-.2rem, 50%);
}

.tooltip.tooltip-right:focus:after, .tooltip.tooltip-right:hover:after {
  transform: translate(.2rem, 50%);
}

.tooltip.tooltip-bottom:after {
  top: 100%;
  bottom: auto;
  transform: translate(-50%, -.4rem);
}

.tooltip.tooltip-bottom:focus:after, .tooltip.tooltip-bottom:hover:after {
  transform: translate(-50%, .2rem);
}

.tooltip.tooltip-left:after {
  bottom: 50%;
  left: auto;
  right: 100%;
  transform: translate(.4rem, 50%);
}

.tooltip.tooltip-left:focus:after, .tooltip.tooltip-left:hover:after {
  transform: translate(-.2rem, 50%);
}

@keyframes loading {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-1.6rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.text-primary {
  color: #5755d9 !important;
}

a.text-primary:focus, a.text-primary:hover {
  color: #4240d4;
}

a.text-primary:visited {
  color: #6c6ade;
}

.text-secondary {
  color: #e5e5f9 !important;
}

a.text-secondary:focus, a.text-secondary:hover {
  color: #d1d0f4;
}

a.text-secondary:visited {
  color: #fafafe;
}

.text-gray {
  color: #bcc3ce !important;
}

a.text-gray:focus, a.text-gray:hover {
  color: #adb6c4;
}

a.text-gray:visited {
  color: #cbd0d9;
}

.text-light {
  color: #fff !important;
}

a.text-light:focus, a.text-light:hover {
  color: #f2f2f2;
}

a.text-light:visited {
  color: #fff;
}

.text-dark {
  color: #3b4351 !important;
}

a.text-dark:focus, a.text-dark:hover {
  color: #303742;
}

a.text-dark:visited {
  color: #455060;
}

.text-success {
  color: #32b643 !important;
}

a.text-success:focus, a.text-success:hover {
  color: #2da23c;
}

a.text-success:visited {
  color: #39c94b;
}

.text-warning {
  color: #ffb700 !important;
}

a.text-warning:focus, a.text-warning:hover {
  color: #e6a500;
}

a.text-warning:visited {
  color: #ffbe1a;
}

.text-error {
  color: #e85600 !important;
}

a.text-error:focus, a.text-error:hover {
  color: #cf4d00;
}

a.text-error:visited {
  color: #ff6003;
}

.bg-primary {
  color: #fff;
  background: #5755d9 !important;
}

.bg-secondary {
  background: #f1f1fc !important;
}

.bg-dark {
  color: #fff;
  background: #303742 !important;
}

.bg-gray {
  background: #f7f8f9 !important;
}

.bg-success {
  color: #fff;
  background: #32b643 !important;
}

.bg-warning {
  color: #fff;
  background: #ffb700 !important;
}

.bg-error {
  color: #fff;
  background: #e85600 !important;
}

.c-hand {
  cursor: pointer;
}

.c-move {
  cursor: move;
}

.c-zoom-in {
  cursor: zoom-in;
}

.c-zoom-out {
  cursor: zoom-out;
}

.c-not-allowed {
  cursor: not-allowed;
}

.c-auto {
  cursor: auto;
}

.d-block {
  display: block;
}

.d-inline {
  display: inline;
}

.d-inline-block {
  display: inline-block;
}

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

.d-hide, .d-none {
  display: none !important;
}

.d-visible {
  visibility: visible;
}

.d-invisible {
  visibility: hidden;
}

.text-hide {
  color: #0000;
  text-shadow: none;
  background: none;
  border: 0;
  font-size: 0;
  line-height: 0;
}

.text-assistive {
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.divider, .divider-vert {
  display: block;
  position: relative;
}

.divider-vert[data-content]:after, .divider[data-content]:after {
  color: #bcc3ce;
  content: attr(data-content);
  background: #fff;
  padding: 0 .4rem;
  font-size: .7rem;
  display: inline-block;
  transform: translateY(-.65rem);
}

.divider {
  height: .05rem;
  border-top: .05rem solid #f1f3f5;
  margin: .4rem 0;
}

.divider[data-content] {
  margin: .8rem 0;
}

.divider-vert {
  padding: .8rem;
  display: block;
}

.divider-vert:before {
  content: "";
  border-left: .05rem solid #dadee4;
  display: block;
  position: absolute;
  top: .4rem;
  bottom: .4rem;
  left: 50%;
  transform: translateX(-50%);
}

.divider-vert[data-content]:after {
  padding: .2rem 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loading {
  min-height: .8rem;
  pointer-events: none;
  position: relative;
  color: #0000 !important;
}

.loading:after {
  content: "";
  height: .8rem;
  opacity: 1;
  width: .8rem;
  z-index: 1;
  background: none;
  border: .1rem solid #0000;
  border-color: #0000 #0000 #5755d9 #5755d9;
  border-radius: 50%;
  margin-top: -.4rem;
  margin-left: -.4rem;
  padding: 0;
  animation: .5s linear infinite loading;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
}

.loading.loading-lg {
  min-height: 2rem;
}

.loading.loading-lg:after {
  height: 1.6rem;
  width: 1.6rem;
  margin-top: -.8rem;
  margin-left: -.8rem;
}

.clearfix:after {
  clear: both;
  content: "";
  display: table;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.p-relative {
  position: relative !important;
}

.p-absolute {
  position: absolute !important;
}

.p-fixed {
  position: fixed !important;
}

.p-sticky {
  position: sticky !important;
}

.p-centered {
  float: none;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.flex-centered {
  -ms-flex-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

.m-0 {
  margin: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.m-1 {
  margin: .2rem !important;
}

.mb-1 {
  margin-bottom: .2rem !important;
}

.ml-1 {
  margin-left: .2rem !important;
}

.mr-1 {
  margin-right: .2rem !important;
}

.mt-1 {
  margin-top: .2rem !important;
}

.mx-1 {
  margin-left: .2rem !important;
  margin-right: .2rem !important;
}

.my-1 {
  margin-top: .2rem !important;
  margin-bottom: .2rem !important;
}

.m-2 {
  margin: .4rem !important;
}

.mb-2 {
  margin-bottom: .4rem !important;
}

.ml-2 {
  margin-left: .4rem !important;
}

.mr-2 {
  margin-right: .4rem !important;
}

.mt-2 {
  margin-top: .4rem !important;
}

.mx-2 {
  margin-left: .4rem !important;
  margin-right: .4rem !important;
}

.my-2 {
  margin-top: .4rem !important;
  margin-bottom: .4rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.p-1 {
  padding: .2rem !important;
}

.pb-1 {
  padding-bottom: .2rem !important;
}

.pl-1 {
  padding-left: .2rem !important;
}

.pr-1 {
  padding-right: .2rem !important;
}

.pt-1 {
  padding-top: .2rem !important;
}

.px-1 {
  padding-left: .2rem !important;
  padding-right: .2rem !important;
}

.py-1 {
  padding-top: .2rem !important;
  padding-bottom: .2rem !important;
}

.p-2 {
  padding: .4rem !important;
}

.pb-2 {
  padding-bottom: .4rem !important;
}

.pl-2 {
  padding-left: .4rem !important;
}

.pr-2 {
  padding-right: .4rem !important;
}

.pt-2 {
  padding-top: .4rem !important;
}

.px-2 {
  padding-left: .4rem !important;
  padding-right: .4rem !important;
}

.py-2 {
  padding-top: .4rem !important;
  padding-bottom: .4rem !important;
}

.s-rounded {
  border-radius: .1rem;
}

.s-circle {
  border-radius: 50%;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-normal {
  font-weight: 400;
}

.text-bold {
  font-weight: 700;
}

.text-italic {
  font-style: italic;
}

.text-large {
  font-size: 1.2em;
}

.text-small {
  font-size: .9em;
}

.text-tiny {
  font-size: .8em;
}

.text-muted {
  opacity: .8;
}

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-clip {
  text-overflow: clip;
  white-space: nowrap;
  overflow: hidden;
}

.text-break {
  hyphens: auto;
  word-break: break-word;
  word-wrap: break-word;
}

.Tile {
  width: 40px;
  height: 40px;
  pointer-events: none;
  transition-property: top, left, transform;
  transition-duration: 6s;
  transition-timing-function: linear;
  position: absolute;
}

@media screen and (max-width: 500px) {
  .Tile {
    transition-duration: 3s;
  }
}

@media screen and (prefers-reduced-motion: reduce), (update: slow) {
  * {
    transition-duration: .001ms !important;
  }
}

:root {
  --color-0: #f75d32;
  --color-1: #ffc041;
  --color-2: #6d7;
  --color-3: #2299e8;
  --color-4: #f8f353;
  --color-a: #a23ea2;
  --color-b: #3f3f3f;
}

.color__0 {
  background-color: var(--color-0);
}

.color__1 {
  background-color: var(--color-1);
}

.color__2 {
  background-color: var(--color-2);
}

.color__3 {
  background-color: var(--color-3);
}

.color__4 {
  background-color: var(--color-4);
}

.color__a {
  background-color: var(--color-a);
}

.color__b {
  background-color: var(--color-b);
}

.Menu1 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.Menu__backdrop {
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
}

.RouteContent {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.RouteContent__wrapped {
  min-width: 300px;
  max-width: 400px;
}

.RouteContent__content {
  background: #fffc;
}

.RouteContent__content.RouteContent__wrapped {
  padding: 1.5em 1em;
}

.Statistics {
  padding: 1.5em 1em;
}

.About {
  padding: 1.5em 1em;
}

.Point {
  transition: transform .1s ease-in-out, background-color 70ms linear;
}

.Point.is-highlighted {
  cursor: pointer;
  transform: scale(1.2);
}

.row {
  display: flex;
}

.Interaction {
  cursor: pointer;
  width: 100%;
  display: flex;
}

.Interaction__color {
  transition: all .1s linear;
}

.Interaction__color:hover:not(:active) {
  opacity: .8;
  scale: 1.15;
}

.Progress {
  width: 100%;
  height: 50px;
  justify-content: space-between;
  display: flex;
  position: relative;
}

.ProgressBar {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.ProgressBar__label {
  background-color: #fffc;
  border-radius: 4px;
  padding: 6px;
}

.ProgressBar__bar {
  z-index: -1;
  transition: width 50ms cubic-bezier(.1, .7, 1, .1);
  position: absolute;
  inset: 0;
}

.ProgressBar__b .ProgressBar__bar {
  left: 100%;
  transform: translateX(-100%);
}

.Message {
  align-items: center;
  animation-duration: 1s;
  animation-fill-mode: both;
  display: flex;
}

.Message.m-default {
  animation-name: bounce;
}

.Message.m-won {
  animation-name: tada;
}

.Message.m-lost {
  animation-name: shake;
}

@keyframes bounce {
  from, 20%, 53%, 80%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    transform: translate3d(0, 0, 0);
  }

  40%, 43% {
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    transform: translate3d(0, -10px, 0);
  }

  70% {
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    transform: translate3d(0, -3px, 0);
  }

  90% {
    transform: translate3d(0, -1px, 0);
  }
}

@keyframes tada {
  from {
    transform: scale3d(1, 1, 1);
  }

  10%, 20% {
    transform: scale3d(.9, .9, .9)rotate3d(0, 0, 1, -2deg);
  }

  30%, 50%, 70%, 90% {
    transform: scale3d(1.1, 1.1, 1.1)rotate3d(0, 0, 1, 2deg);
  }

  40%, 60%, 80% {
    transform: scale3d(1.1, 1.1, 1.1)rotate3d(0, 0, 1, -2deg);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes shake {
  from, to {
    transform: translate3d(0, 0, 0);
  }

  10%, 30%, 50%, 70%, 90% {
    transform: translate3d(-5px, 0, 0);
  }

  20%, 40%, 60%, 80% {
    transform: translate3d(5px, 0, 0);
  }
}

.Game {
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.Game__message {
  align-items: center;
  margin: 1em 0;
  display: flex;
}

.Game__Interaction {
  border-top: 1px solid #585858;
}

.Rematch {
  position: relative;
}

.Rematch__message {
  min-width: 300px;
  z-index: 1;
  background: #ffffffe6;
  border-radius: .5em;
  flex-direction: column;
  padding: 2.5em;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.Rematch__cta {
  margin-top: 1em;
}

.introjs-overlay {
  box-sizing: content-box;
  z-index: 999999;
  opacity: 0;
  -o-transition: all .3s ease-out;
  transition: all .3s ease-out;
  position: absolute;
}

.introjs-showElement {
  z-index: 9999999 !important;
}

tr.introjs-showElement > td, tr.introjs-showElement > th {
  position: relative;
  z-index: 9999999 !important;
}

.introjs-disableInteraction {
  opacity: 0;
  background-color: #fff;
  position: absolute;
  z-index: 99999999 !important;
}

.introjs-relativePosition {
  position: relative;
}

.introjs-helperLayer {
  box-sizing: content-box;
  z-index: 9999998;
  -o-transition: all .3s ease-out;
  border-radius: 4px;
  transition: all .3s ease-out;
  position: absolute;
}

.introjs-helperLayer *, .introjs-helperLayer :before, .introjs-helperLayer :after {
  box-sizing: content-box;
}

.introjs-tooltipReferenceLayer {
  box-sizing: content-box;
  visibility: hidden;
  z-index: 100000000;
  -o-transition: all .3s ease-out;
  background-color: #0000;
  font-family: Helvetica Neue, Inter, ui-sans-serif, Apple Color Emoji, Helvetica, Arial, sans-serif;
  transition: all .3s ease-out;
  position: absolute;
}

.introjs-tooltipReferenceLayer * {
  font-family: Helvetica Neue, Inter, ui-sans-serif, Apple Color Emoji, Helvetica, Arial, sans-serif;
}

.introjs-helperNumberLayer {
  color: #9e9e9e;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: Helvetica Neue, Inter, ui-sans-serif, Apple Color Emoji, Helvetica, Arial, sans-serif;
}

.introjs-arrow {
  content: "";
  border: 5px solid #0000;
  position: absolute;
}

.introjs-arrow.top {
  border-bottom-color: #fff;
  top: -10px;
  left: 10px;
}

.introjs-arrow.top-right {
  border-bottom-color: #fff;
  top: -10px;
  right: 10px;
}

.introjs-arrow.top-middle {
  border-bottom-color: #fff;
  margin-left: -5px;
  top: -10px;
  left: 50%;
}

.introjs-arrow.right {
  border-left-color: #fff;
  top: 10px;
  right: -10px;
}

.introjs-arrow.right-bottom {
  border-left-color: #fff;
  bottom: 10px;
  right: -10px;
}

.introjs-arrow.bottom {
  border-top-color: #fff;
  bottom: -10px;
  left: 10px;
}

.introjs-arrow.bottom-right {
  border-top-color: #fff;
  bottom: -10px;
  right: 10px;
}

.introjs-arrow.bottom-middle {
  border-top-color: #fff;
  margin-left: -5px;
  bottom: -10px;
  left: 50%;
}

.introjs-arrow.left {
  border-right-color: #fff;
  top: 10px;
  left: -10px;
}

.introjs-arrow.left-bottom {
  border-right-color: #fff;
  bottom: 10px;
  left: -10px;
}

.introjs-tooltip {
  box-sizing: content-box;
  visibility: visible;
  min-width: 250px;
  max-width: 300px;
  -o-transition: opacity .1s ease-out;
  background-color: #fff;
  border-radius: 5px;
  transition: opacity .1s ease-out;
  position: absolute;
  box-shadow: 0 3px 30px #2121214d;
}

.introjs-tooltiptext {
  padding: 20px;
}

.introjs-tooltip-title {
  float: left;
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 700;
  line-height: 32px;
}

.introjs-tooltip-header {
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.introjs-tooltip-header:after {
  content: ".";
  visibility: hidden;
  height: 0;
  clear: both;
  display: block;
}

.introjs-tooltipbuttons {
  text-align: right;
  white-space: nowrap;
  border-top: 1px solid #e0e0e0;
  padding: 10px;
}

.introjs-tooltipbuttons:after {
  content: "";
  visibility: hidden;
  height: 0;
  clear: both;
  display: block;
}

.introjs-button {
  box-sizing: content-box;
  text-shadow: 1px 1px #fff;
  color: #424242;
  white-space: nowrap;
  cursor: pointer;
  zoom: 1;
  background-color: #f4f4f4;
  border: 1px solid #bdbdbd;
  border-radius: .2em;
  outline: 0;
  padding: .5rem 1rem;
  font-size: 14px;
  text-decoration: none;
  display: inline-block;
  position: relative;
  overflow: visible;
}

.introjs-button:hover {
  color: #212121;
  background-color: #e0e0e0;
  border-color: #9e9e9e;
  outline: 0;
  text-decoration: none;
}

.introjs-button:focus {
  color: #212121;
  background-color: #eee;
  border: 1px solid #616161;
  outline: 0;
  text-decoration: none;
  box-shadow: 0 0 0 .2rem #9e9e9e80;
}

.introjs-button:active {
  color: #212121;
  background-color: #e0e0e0;
  border-color: #9e9e9e;
  outline: 0;
  text-decoration: none;
}

.introjs-button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.introjs-skipbutton {
  box-sizing: content-box;
  color: #616161;
  float: right;
  cursor: pointer;
  text-align: center;
  padding: 7px 10px;
  font-size: 20px;
  font-weight: 700;
  line-height: 1;
}

.introjs-skipbutton:focus, .introjs-skipbutton:hover {
  color: #212121;
  outline: 0;
  text-decoration: none;
}

.introjs-prevbutton {
  float: left;
}

.introjs-nextbutton {
  float: right;
}

.introjs-disabled, .introjs-disabled:focus, .introjs-disabled:hover {
  color: #9e9e9e;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: default;
  background-color: #f4f4f4;
  background-image: none;
  border-color: #bdbdbd;
  text-decoration: none;
}

.introjs-hidden {
  display: none;
}

.introjs-bullets {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.introjs-bullets ul {
  box-sizing: content-box;
  clear: both;
  margin: 0 auto;
  padding: 0;
  display: inline-block;
}

.introjs-bullets ul li {
  box-sizing: content-box;
  float: left;
  margin: 0 2px;
  list-style: none;
}

.introjs-bullets ul li a {
  -o-transition: width .1s ease-in;
  box-sizing: content-box;
  width: 6px;
  height: 6px;
  cursor: pointer;
  background: #ccc;
  border-radius: 10px;
  text-decoration: none;
  transition: width .1s ease-in;
  display: block;
}

.introjs-bullets ul li a:focus, .introjs-bullets ul li a:hover {
  width: 15px;
  background: #999;
  outline: 0;
  text-decoration: none;
}

.introjs-bullets ul li a.active {
  width: 15px;
  background: #999;
}

.introjs-progress {
  box-sizing: content-box;
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 4px;
  margin: 10px;
  overflow: hidden;
}

.introjs-progressbar {
  box-sizing: content-box;
  float: left;
  width: 0%;
  height: 100%;
  text-align: center;
  background-color: #08c;
  font-size: 10px;
  line-height: 10px;
}

.introjsFloatingElement {
  height: 0;
  width: 0;
  position: absolute;
  top: 50%;
  left: 50%;
}

.introjs-fixedTooltip {
  position: fixed;
}

.introjs-hint {
  box-sizing: content-box;
  width: 20px;
  height: 15px;
  cursor: pointer;
  background: none;
  position: absolute;
}

.introjs-hint:focus {
  border: 0;
  outline: 0;
}

.introjs-hint:hover > .introjs-hint-pulse {
  background-color: #3c3c3c91;
}

.introjs-hidehint {
  display: none;
}

.introjs-fixedhint {
  position: fixed;
}

@keyframes introjspulse {
  0% {
    transform: scale(.95);
    box-shadow: 0 0 #000000b3;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px #0000;
  }

  100% {
    transform: scale(.95);
    box-shadow: 0 0 #0000;
  }
}

.introjs-hint-pulse {
  box-sizing: content-box;
  width: 15px;
  height: 15px;
  z-index: 10;
  -o-transition: all .2s ease-out;
  background-color: #8888883d;
  border-radius: 30px;
  transition: all .2s ease-out;
  animation: 2s infinite introjspulse;
  position: absolute;
}

.introjs-hint-no-anim .introjs-hint-pulse {
  animation: none;
}

.introjs-hint-dot {
  box-sizing: content-box;
  height: 50px;
  width: 50px;
  z-index: 1;
  opacity: 0;
  background: none;
  border-radius: 60px;
  position: absolute;
  top: -18px;
  left: -18px;
}

@keyframes dots-loader {
  0% {
    box-shadow: var(--color-0) -10px -10px 0 5px, var(--color-1) 10px -10px 0 5px, var(--color-2) 10px 10px 0 5px, var(--color-3) -10px 10px 0 5px;
  }

  8.33% {
    box-shadow: var(--color-0) 10px -10px 0 5px, var(--color-1) 10px -10px 0 5px, var(--color-2) 10px 10px 0 5px, var(--color-3) -10px 10px 0 5px;
  }

  16.67% {
    box-shadow: var(--color-0) 10px 10px 0 5px, var(--color-1) 10px 10px 0 5px, var(--color-2) 10px 10px 0 5px, var(--color-3) -10px 10px 0 5px;
  }

  25% {
    box-shadow: var(--color-0) -10px 10px 0 5px, var(--color-1) -10px 10px 0 5px, var(--color-2) -10px 10px 0 5px, var(--color-3) -10px 10px 0 5px;
  }

  33.33% {
    box-shadow: var(--color-0) -10px -10px 0 5px, var(--color-1) -10px 10px 0 5px, var(--color-2) -10px -10px 0 5px, var(--color-3) -10px -10px 0 5px;
  }

  41.67% {
    box-shadow: var(--color-0) 10px -10px 0 5px, var(--color-1) -10px 10px 0 5px, var(--color-2) -10px -10px 0 5px, var(--color-3) 10px -10px 0 5px;
  }

  50% {
    box-shadow: var(--color-0) 10px 10px 0 5px, var(--color-1) -10px 10px 0 5px, var(--color-2) -10px -10px 0 5px, var(--color-3) 10px -10px 0 5px;
  }

  58.33% {
    box-shadow: var(--color-0) -10px 10px 0 5px, var(--color-1) -10px 10px 0 5px, var(--color-2) -10px -10px 0 5px, var(--color-3) 10px -10px 0 5px;
  }

  66.67% {
    box-shadow: var(--color-0) -10px -10px 0 5px, var(--color-1) -10px -10px 0 5px, var(--color-2) -10px -10px 0 5px, var(--color-3) 10px -10px 0 5px;
  }

  75% {
    box-shadow: var(--color-0) 10px -10px 0 5px, var(--color-1) 10px -10px 0 5px, var(--color-2) 10px -10px 0 5px, var(--color-3) 10px -10px 0 5px;
  }

  83.33% {
    box-shadow: var(--color-0) 10px 10px 0 5px, var(--color-1) 10px -10px 0 5px, var(--color-2) 10px 10px 0 5px, var(--color-3) 10px 10px 0 5px;
  }

  91.67% {
    box-shadow: var(--color-0) -10px 10px 0 5px, var(--color-1) 10px -10px 0 5px, var(--color-2) 10px 10px 0 5px, var(--color-3) -10px 10px 0 5px;
  }

  100% {
    box-shadow: var(--color-0) -10px -10px 0 5px, var(--color-1) 10px -10px 0 5px, var(--color-2) 10px 10px 0 5px, var(--color-3) -10px 10px 0 5px;
  }
}

.Loader {
  width: 5px;
  height: 5px;
  box-shadow: var(--color-0) -10px -10px 0 5px, var(--color-1) 10px -10px 0 5px, var(--color-2) 10px 10px 0 5px, var(--color-3) -10px 10px 0 5px;
  transform-origin: 50%;
  animation: 5s ease-in-out infinite dots-loader;
  display: inline-block;
}

.Multi__Loading {
  min-width: 150px;
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.Multi__shareMsg {
  background: #f0f8ff;
  border-radius: 5px;
  margin: 1rem .5rem;
  padding: .5rem;
}

.Multi__URL {
  width: 100%;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-0-400-normal.358720ac.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+25EE8, U+25F23, U+25F5C, U+25FD4, U+25FE0, U+25FFB, U+2600C, U+26017, U+26060, U+260ED, U+26222, U+2626A, U+26270, U+26286, U+2634C, U+26402, U+2667E, U+266B0, U+2671D, U+268DD, U+268EA, U+26951, U+2696F, U+26999, U+269DD, U+26A1E, U+26A58, U+26A8C, U+26AB7, U+26AFF, U+26C29, U+26C73, U+26C9E, U+26CDD, U+26E40, U+26E65, U+26F94, U+26FF6-26FF8, U+270F4, U+2710D, U+27139, U+273DA-273DB, U+273FE, U+27410, U+27449, U+27614-27615, U+27631, U+27684, U+27693, U+2770E, U+27723, U+27752, U+278B2, U+27985, U+279B4, U+27A84, U+27BB3, U+27BBE, U+27BC7, U+27C3C, U+27CB8, U+27D73, U+27DA0, U+27E10, U+27EAF, U+27FB7, U+2808A, U+280BB, U+28277, U+28282, U+282F3, U+283CD, U+2840C, U+28455, U+284DC, U+2856B, U+285C8-285C9, U+286D7, U+286FA, U+28946, U+28949, U+2896B, U+28987-28988, U+289BA-289BB, U+28A1E, U+28A29, U+28A43, U+28A71, U+28A99, U+28ACD, U+28ADD, U+28AE4, U+28BC1, U+28BEF, U+28CDD, U+28D10, U+28D71, U+28DFB, U+28E0F, U+28E17, U+28E1F, U+28E36, U+28E89, U+28EEB, U+28EF6, U+28F32, U+28FF8, U+292A0, U+292B1, U+29490, U+295CF, U+2967F, U+296F0, U+29719, U+29750, U+29810, U+298C6, U+29A72, U+29D4B, U+29DDB, U+29E15, U+29E3D, U+29E49, U+29E8A, U+29EC4, U+29EDB, U+29EE9, U+29FCE, U+29FD7, U+2A01A, U+2A02F, U+2A082, U+2A0F9, U+2A190, U+2A2B2, U+2A38C, U+2A437, U+2A5F1, U+2A602, U+2A61A, U+2A6B2, U+2A9E6, U+2B746, U+2B751, U+2B753, U+2B75A, U+2B75C, U+2B765, U+2B776-2B777, U+2B77C, U+2B782, U+2B789, U+2B78B, U+2B78E, U+2B794, U+2B7AC, U+2B7AF, U+2B7BD, U+2B7C9, U+2B7CF, U+2B7D2, U+2B7D8, U+2B7F0, U+2B80D, U+2B817, U+2B81A, U+2D544, U+2E278, U+2E569, U+2E6EA, U+2F804, U+2F80F, U+2F815, U+2F818, U+2F81A, U+2F822, U+2F828, U+2F82C, U+2F833, U+2F83F, U+2F846, U+2F852, U+2F862, U+2F86D, U+2F873, U+2F877, U+2F884, U+2F899-2F89A, U+2F8A6, U+2F8AC, U+2F8B2, U+2F8B6, U+2F8D3, U+2F8DB-2F8DC, U+2F8E1, U+2F8E5, U+2F8EA, U+2F8ED, U+2F8FC, U+2F903, U+2F90B, U+2F90F, U+2F91A, U+2F920-2F921, U+2F945, U+2F947, U+2F96C, U+2F995, U+2F9D0, U+2F9DE-2F9DF, U+2F9F4;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-1-400-normal.df79d4d4.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+1F235-1F23B, U+1F240-1F248, U+1F250-1F251, U+2000B, U+20089-2008A, U+200A2, U+200A4, U+200B0, U+200F5, U+20158, U+201A2, U+20213, U+2032B, U+20371, U+20381, U+203F9, U+2044A, U+20509, U+2053F, U+205B1, U+205D6, U+20611, U+20628, U+206EC, U+2074F, U+207C8, U+20807, U+2083A, U+208B9, U+2090E, U+2097C, U+20984, U+2099D, U+20A64, U+20AD3, U+20B1D, U+20B9F, U+20BB7, U+20D45, U+20D58, U+20DE1, U+20E64, U+20E6D, U+20E95, U+20F5F, U+21201, U+2123D, U+21255, U+21274, U+2127B, U+212D7, U+212E4, U+212FD, U+2131B, U+21336, U+21344, U+213C4, U+2146D-2146E, U+215D7, U+21647, U+216B4, U+21706, U+21742, U+218BD, U+219C3, U+21A1A, U+21C56, U+21D2D, U+21D45, U+21D62, U+21D78, U+21D92, U+21D9C, U+21DA1, U+21DB7, U+21DE0, U+21E33-21E34, U+21F1E, U+21F76, U+21FFA, U+2217B, U+22218, U+2231E, U+223AD, U+22609, U+226F3, U+2285B, U+228AB, U+2298F, U+22AB8, U+22B46, U+22B4F-22B50, U+22BA6, U+22C1D, U+22C24, U+22DE1, U+22E42, U+22FEB, U+231B6, U+231C3-231C4, U+231F5, U+23372, U+233CC, U+233D0, U+233D2-233D3, U+233D5, U+233DA, U+233DF, U+233E4, U+233FE, U+2344A-2344B, U+23451, U+23465, U+234E4, U+2355A, U+23594, U+235C4, U+23638-2363A, U+23647, U+2370C, U+2371C, U+2373F, U+23763-23764, U+237E7, U+237F1, U+237FF, U+23824, U+2383D, U+23A98, U+23C7F, U+23CBE, U+23CFE, U+23D00, U+23D0E, U+23D40, U+23DD3, U+23DF9-23DFA, U+23F7E, U+2404B, U+24096, U+24103, U+241C6, U+241FE, U+242EE, U+243BC, U+243D0, U+24629, U+246A5, U+247F1, U+24896, U+248E9, U+24A4D, U+24B56, U+24B6F, U+24C16, U+24D14, U+24E04, U+24E0E, U+24E37, U+24E6A, U+24E8B, U+24FF2, U+2504A, U+25055, U+25122, U+251A9, U+251CD, U+251E5, U+2521E, U+2524C, U+2542E, U+2548E, U+254D9, U+2550E, U+255A7, U+2567F, U+25771, U+257A9, U+257B4, U+25874, U+259C4, U+259CC, U+259D4, U+25AD7, U+25AE3-25AE4, U+25AF1, U+25BB2, U+25C4B, U+25C64, U+25DA1, U+25E2E, U+25E56, U+25E62, U+25E65, U+25EC2, U+25ED8;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-3-400-normal.1c2b4bf0.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+FA10, U+FA12-FA6D, U+FB00-FB04, U+FE10-FE19, U+FE30-FE42, U+FE44-FE52, U+FE54-FE66, U+FE68-FE6B, U+FF02, U+FF04, U+FF07, U+FF51, U+FF5B, U+FF5D, U+FF5F-FF60, U+FF66, U+FF69, U+FF87, U+FFA1-FFBE, U+FFC2-FFC7, U+FFCA-FFCF, U+FFD2-FFD6;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-4-400-normal.12c165ab.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+F92D-F959, U+F95B-F9F2, U+F9F4-FA0B, U+FA0E-FA0F;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-5-400-normal.f8784802.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+9E8B-9E8C, U+9E8E-9E8F, U+9E91-9E92, U+9E95-9E96, U+9E98, U+9E9B, U+9E9D-9E9E, U+9EA4-9EA5, U+9EA8-9EAA, U+9EAC-9EB0, U+9EB3-9EB5, U+9EB8, U+9EBC-9EBF, U+9EC3, U+9EC6, U+9EC8, U+9ECB-9ECD, U+9ECF-9ED1, U+9ED4-9ED5, U+9ED8, U+9EDB-9EE0, U+9EE4-9EE5, U+9EE7-9EE8, U+9EEC-9EF2, U+9EF4-9EF9, U+9EFB-9EFF, U+9F02-9F03, U+9F07-9F09, U+9F0E-9F12, U+9F14-9F17, U+9F19-9F1B, U+9F1F-9F22, U+9F26, U+9F2A-9F2C, U+9F2F, U+9F31-9F32, U+9F34, U+9F37, U+9F39-9F3A, U+9F3C-9F3F, U+9F41, U+9F43-9F47, U+9F4A, U+9F4E-9F50, U+9F52-9F58, U+9F5A, U+9F5D-9F61, U+9F63, U+9F66-9F6A, U+9F6C-9F73, U+9F75-9F77, U+9F7A, U+9F7D, U+9F7F, U+9F8F-9F92, U+9F94-9F97, U+9F99, U+9F9C-9FA3, U+9FA5, U+9FB4, U+9FBC-9FC2, U+9FC4, U+9FC6, U+9FCC, U+F900-F92C;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-6-400-normal.0a535ee1.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+9C3E, U+9C41, U+9C43-9C4A, U+9C4E-9C50, U+9C52-9C54, U+9C56, U+9C58, U+9C5A-9C61, U+9C63, U+9C65, U+9C67-9C6B, U+9C6D-9C6E, U+9C70, U+9C72, U+9C75-9C78, U+9C7A-9C7C, U+9CE6-9CE7, U+9CEB-9CEC, U+9CF0, U+9CF2, U+9CF6-9CF7, U+9CF9, U+9D02-9D03, U+9D06-9D09, U+9D0B, U+9D0E, U+9D11-9D12, U+9D15, U+9D17-9D18, U+9D1B-9D1F, U+9D23, U+9D26, U+9D2A-9D2C, U+9D2F-9D30, U+9D32-9D34, U+9D3A, U+9D3C-9D3F, U+9D41-9D48, U+9D4A, U+9D50-9D54, U+9D59, U+9D5D-9D65, U+9D69-9D6C, U+9D6F-9D70, U+9D72-9D73, U+9D76-9D77, U+9D7A-9D7C, U+9D7E, U+9D83-9D84, U+9D86-9D87, U+9D89-9D8A, U+9D8D-9D8E, U+9D92-9D93, U+9D95-9D9A, U+9DA1, U+9DA4, U+9DA9-9DAC, U+9DAE, U+9DB1-9DB2, U+9DB5, U+9DB8-9DBD, U+9DBF-9DC4, U+9DC6-9DC7, U+9DC9-9DCA, U+9DCF, U+9DD3-9DD7, U+9DD9-9DDA, U+9DDE-9DE0, U+9DE3, U+9DE5-9DE7, U+9DE9, U+9DEB, U+9DED-9DF0, U+9DF3-9DF4, U+9DF8, U+9DFD-9DFE, U+9E02, U+9E07, U+9E0A, U+9E0D-9E0E, U+9E10-9E12, U+9E15-9E16, U+9E19-9E1F, U+9E75, U+9E79-9E7D, U+9E80-9E85, U+9E87-9E88;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-7-400-normal.472c31a0.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+9AE5-9AE7, U+9AE9, U+9AEB-9AEC, U+9AEE-9AEF, U+9AF1-9AF5, U+9AF7, U+9AF9-9AFB, U+9AFD, U+9AFF-9B06, U+9B08-9B09, U+9B0B-9B0E, U+9B10, U+9B12, U+9B16, U+9B18-9B1D, U+9B1F-9B20, U+9B22-9B23, U+9B25-9B2F, U+9B32-9B35, U+9B37, U+9B39-9B3B, U+9B3D, U+9B43-9B44, U+9B48, U+9B4B-9B4F, U+9B51, U+9B55-9B58, U+9B5B, U+9B5E, U+9B61, U+9B63, U+9B65-9B66, U+9B68, U+9B6A-9B6F, U+9B72-9B79, U+9B7F-9B80, U+9B83-9B87, U+9B89-9B8B, U+9B8D, U+9B8F-9B94, U+9B96-9B97, U+9B9A, U+9B9D-9BA0, U+9BA6-9BA7, U+9BA9-9BAA, U+9BAC, U+9BB0-9BB2, U+9BB4, U+9BB7-9BB9, U+9BBB-9BBC, U+9BBE-9BC1, U+9BC6-9BC8, U+9BCA, U+9BCE-9BD2, U+9BD4, U+9BD7-9BD8, U+9BDD, U+9BDF, U+9BE1-9BE5, U+9BE7, U+9BEA-9BEB, U+9BEE-9BF3, U+9BF5, U+9BF7-9BFA, U+9BFD, U+9BFF-9C00, U+9C02, U+9C04, U+9C06, U+9C08-9C0D, U+9C0F-9C16, U+9C18-9C1E, U+9C21-9C2A, U+9C2D-9C32, U+9C35-9C37, U+9C39-9C3A, U+9C3D;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-8-400-normal.d055a840.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+98EB, U+98ED-98EE, U+98F0-98F1, U+98F3, U+98F6, U+9902, U+9907-9909, U+9911-9912, U+9914-9918, U+991A-9922, U+9924, U+9926-9927, U+992B-992C, U+992E, U+9931-9935, U+9939-993E, U+9940-9942, U+9945-9949, U+994B-994E, U+9950-9952, U+9954-9955, U+9958-9959, U+995B-995C, U+995E-9960, U+9963, U+9997-9998, U+999B, U+999D-999F, U+99A3, U+99A5-99A6, U+99A8, U+99AD-99AE, U+99B0-99B2, U+99B5, U+99B9-99BA, U+99BC-99BD, U+99BF, U+99C1, U+99C3, U+99C8-99C9, U+99D1, U+99D3-99D5, U+99D8-99DF, U+99E1-99E2, U+99E7, U+99EA-99EE, U+99F0-99F2, U+99F4-99F5, U+99F8-99F9, U+99FB-99FE, U+9A01-9A05, U+9A08, U+9A0A-9A0C, U+9A0F-9A11, U+9A16, U+9A1A, U+9A1E, U+9A20, U+9A22-9A24, U+9A27, U+9A2B, U+9A2D-9A2E, U+9A31, U+9A33, U+9A35-9A38, U+9A3E, U+9A40-9A45, U+9A47, U+9A4A-9A4E, U+9A51-9A52, U+9A54-9A58, U+9A5B, U+9A5D, U+9A5F, U+9A62, U+9A64-9A65, U+9A69-9A6C, U+9AAA, U+9AAC-9AB0, U+9AB2, U+9AB4-9AB7, U+9AB9, U+9ABB-9AC1, U+9AC3, U+9AC6, U+9AC8, U+9ACE-9AD3, U+9AD5-9AD7, U+9ADB-9ADC, U+9ADE-9AE0, U+9AE2-9AE4;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-9-400-normal.afa02d7c.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+971D, U+9721-9724, U+9728, U+972A, U+9730-9731, U+9733, U+9736, U+9738-9739, U+973B, U+973D-973E, U+9741-9744, U+9746-974A, U+974D-974F, U+9751, U+9755, U+9757-9758, U+975A-975C, U+9760-9761, U+9763-9764, U+9766-9768, U+976A-976B, U+976E, U+9771, U+9773, U+9776-977D, U+977F-9781, U+9785-9786, U+9789, U+978B, U+978F-9790, U+9795-9797, U+9799-979A, U+979C, U+979E-97A0, U+97A2-97A3, U+97A6, U+97A8, U+97AB-97AC, U+97AE, U+97B1-97B6, U+97B8-97BA, U+97BC, U+97BE-97BF, U+97C1, U+97C3-97CE, U+97D0-97D1, U+97D4, U+97D7-97D9, U+97DB-97DE, U+97E0-97E1, U+97E4, U+97E6, U+97ED-97EF, U+97F1-97F2, U+97F4-97F8, U+97FA, U+9804, U+9807, U+980A, U+980C-980F, U+9814, U+9816-9817, U+9819-981A, U+981C, U+981E, U+9820-9821, U+9823-9826, U+982B, U+982E-9830, U+9832-9835, U+9837, U+9839, U+983D-983E, U+9844, U+9846-9847, U+984A-984B, U+984F, U+9851-9853, U+9856-9857, U+9859-985B, U+9862-9863, U+9865-9866, U+986A-986C, U+986F-9871, U+9873-9875, U+98AA-98AB, U+98AD-98AE, U+98B0-98B1, U+98B4, U+98B6-98B8, U+98BA-98BC, U+98BF, U+98C2-98C8, U+98CB-98CC, U+98CE, U+98DC, U+98DE, U+98E0-98E1, U+98E3, U+98E5-98E7, U+98E9-98EA;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-10-400-normal.bc5ff07b.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+944A, U+944C, U+9452-9453, U+9455, U+9459-945C, U+945E-9463, U+9468, U+946A-946B, U+946D-9472, U+9475, U+9477, U+947C-947F, U+9481, U+9483-9485, U+9578-9579, U+957E-957F, U+9582, U+9584, U+9586-9588, U+958A, U+958C-958F, U+9592, U+9594, U+9596, U+9598-9599, U+959D-95A1, U+95A4, U+95A6-95A9, U+95AB-95AD, U+95B1, U+95B4, U+95B6, U+95B9-95BF, U+95C3, U+95C6, U+95C8-95CD, U+95D0-95D6, U+95D9-95DA, U+95DC-95E2, U+95E4-95E6, U+95E8, U+961D-961E, U+9621-9622, U+9624-9626, U+9628, U+962C, U+962E-962F, U+9631, U+9633-9634, U+9637-963A, U+963C-963D, U+9641-9642, U+964B-964C, U+964F, U+9652, U+9654, U+9656-9658, U+965C-965F, U+9661, U+9666, U+966A, U+966C, U+966E, U+9672, U+9674, U+9677, U+967B-967C, U+967E-967F, U+9681-9684, U+9689, U+968B, U+968D, U+9691, U+9695-9698, U+969A, U+969D, U+969F, U+96A4-96AA, U+96AE-96B4, U+96B6, U+96B8-96BB, U+96BD, U+96C1, U+96C9-96CB, U+96CD-96CE, U+96D2, U+96D5-96D6, U+96D8-96DA, U+96DC-96DF, U+96E9, U+96EF, U+96F1, U+96F9-96FA, U+9702-9706, U+9708-9709, U+970D-970F, U+9711, U+9713-9714, U+9716, U+9719-971B;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-11-400-normal.bea67c67.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+92BC-92BD, U+92BF-92C3, U+92C5-92C8, U+92CB-92D0, U+92D2-92D3, U+92D5, U+92D7-92D9, U+92DC-92DD, U+92DF-92E1, U+92E3-92E5, U+92E7-92EA, U+92EC, U+92EE, U+92F0, U+92F2, U+92F7-92FB, U+92FF-9300, U+9302, U+9304, U+9308, U+930D, U+930F-9311, U+9314-9315, U+9318-931A, U+931C-931F, U+9321-9325, U+9327-932B, U+932E, U+9333-9337, U+933A-933B, U+9344, U+9347-934A, U+934D, U+9350-9352, U+9354-9358, U+935A, U+935C, U+935E, U+9360, U+9364-9365, U+9367, U+9369-936D, U+936F-9371, U+9373-9374, U+9376, U+937A, U+937D-9382, U+9388, U+938A-938B, U+938D, U+938F, U+9392, U+9394-9395, U+9397-9398, U+939A-939B, U+939E, U+93A1, U+93A3-93A4, U+93A6, U+93A8-93A9, U+93AB-93AD, U+93B0, U+93B4-93B6, U+93B9-93BB, U+93C1, U+93C3-93CD, U+93D0-93D1, U+93D3, U+93D6-93D9, U+93DC-93DF, U+93E2, U+93E4-93E8, U+93F1, U+93F5, U+93F7-93FB, U+93FD, U+9401-9404, U+9407-9409, U+940D-9410, U+9413-9417, U+9419-941A, U+941F, U+9421, U+942B, U+942E-942F, U+9431-9434, U+9436, U+9438, U+943A-943B, U+943D, U+943F, U+9441, U+9443-9445, U+9448;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-12-400-normal.1707dcd1.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+9143, U+9146-914C, U+914F, U+9153, U+9156-915B, U+9161, U+9163-9165, U+9167, U+9169, U+916D, U+9172-9174, U+9179-917B, U+9181-9183, U+9185-9187, U+9189-918B, U+918E, U+9191, U+9193-9195, U+9197-9198, U+919E, U+91A1-91A2, U+91A6, U+91A8, U+91AA-91B6, U+91BA-91BD, U+91BF-91C6, U+91C9, U+91CB, U+91D0, U+91D3-91D4, U+91D6-91D7, U+91D9-91DB, U+91DE-91DF, U+91E1, U+91E4-91E6, U+91E9-91EA, U+91EC-91F1, U+91F5-91F7, U+91F9, U+91FB-91FD, U+91FF-9201, U+9204-9207, U+9209-920A, U+920C, U+920E, U+9210-9218, U+921C-921E, U+9223-9226, U+9228-9229, U+922C, U+922E-9230, U+9233, U+9235-923A, U+923C, U+923E-9240, U+9242-9243, U+9245-924B, U+924D-9251, U+9256-925A, U+925C-925E, U+9260-9261, U+9264-9269, U+926E-9270, U+9275-9279, U+927B-927F, U+9288-928A, U+928D-928E, U+9291-9293, U+9295-9297, U+9299, U+929B-929C, U+929F-92A0, U+92A4-92A5, U+92A7-92A8, U+92AB, U+92AF, U+92B2-92B3, U+92B6-92BB;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-13-400-normal.0703c20f.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+8F52-8F55, U+8F57-8F58, U+8F5C-8F5E, U+8F61-8F66, U+8F9C-8F9D, U+8F9F-8FA2, U+8FA4-8FA8, U+8FAD-8FAF, U+8FB4-8FB8, U+8FBE, U+8FC0-8FC2, U+8FC6, U+8FC8, U+8FCA-8FCB, U+8FCD, U+8FD0, U+8FD2-8FD3, U+8FD5, U+8FDA, U+8FE0, U+8FE2-8FE5, U+8FE8-8FEA, U+8FED-8FEF, U+8FF1, U+8FF4-8FF6, U+8FF8-8FFB, U+8FFE, U+9002, U+9004-9005, U+9008, U+900B-900E, U+9011, U+9013, U+9015-9016, U+9018, U+901B, U+901E, U+9021, U+9027-902A, U+902C-902D, U+902F, U+9033-9037, U+9039, U+903C, U+903E-903F, U+9041, U+9043-9044, U+9049, U+904C, U+904F-9052, U+9056, U+9058, U+905B-905E, U+9062, U+9066-9068, U+906C, U+906F-9070, U+9072, U+9074, U+9076, U+9079, U+9080-9083, U+9085, U+9087-9088, U+908B-908C, U+908E-9090, U+9095, U+9097-9099, U+909B, U+90A0-90A2, U+90A5, U+90A8, U+90AF-90B6, U+90BD-90BE, U+90C3-90C5, U+90C7-90C9, U+90CC, U+90D2, U+90D5, U+90D7-90D9, U+90DB-90DF, U+90E2, U+90E4-90E5, U+90EB, U+90EF-90F0, U+90F2, U+90F4, U+90F6, U+90FE-9100, U+9102, U+9104-9106, U+9108, U+910D, U+9110, U+9112, U+9114-911A, U+911C, U+911E, U+9120, U+9122-9123, U+9125, U+9127, U+9129, U+912D-9132, U+9134, U+9136-9137, U+9139-913A, U+913C-913D;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-14-400-normal.5f621b3b.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+8DC0, U+8DC2, U+8DC5-8DC8, U+8DCA-8DCC, U+8DCE-8DCF, U+8DD1, U+8DD4-8DD7, U+8DD9-8DDB, U+8DDF, U+8DE3-8DE5, U+8DE7, U+8DEA-8DEC, U+8DF0-8DF2, U+8DF4, U+8DFC-8DFD, U+8DFF, U+8E01, U+8E04-8E06, U+8E08-8E09, U+8E0B-8E0C, U+8E10-8E11, U+8E14, U+8E16, U+8E1D-8E23, U+8E26-8E27, U+8E30-8E31, U+8E33-8E39, U+8E3D, U+8E40-8E42, U+8E44, U+8E47-8E50, U+8E54-8E55, U+8E59, U+8E5B-8E64, U+8E69, U+8E6C-8E6D, U+8E6F-8E72, U+8E75-8E77, U+8E79-8E7C, U+8E81-8E85, U+8E89, U+8E8B, U+8E90-8E95, U+8E98-8E9B, U+8E9D-8E9E, U+8EA1-8EA2, U+8EA7, U+8EA9-8EAA, U+8EAC-8EB1, U+8EB3, U+8EB5-8EB6, U+8EBA-8EBB, U+8EBE, U+8EC0-8EC1, U+8EC3-8EC8, U+8ECB, U+8ECF, U+8ED1, U+8ED4, U+8EDB-8EDC, U+8EE3, U+8EE8, U+8EEB, U+8EED-8EEE, U+8EF0-8EF1, U+8EF7, U+8EF9-8EFC, U+8EFE, U+8F00, U+8F02, U+8F05, U+8F07-8F08, U+8F0A, U+8F0F-8F10, U+8F12-8F13, U+8F15-8F19, U+8F1B-8F1C, U+8F1E-8F21, U+8F23, U+8F25-8F28, U+8F2B-8F2F, U+8F33-8F37, U+8F39-8F3B, U+8F3E, U+8F40-8F43, U+8F45-8F47, U+8F49-8F4A, U+8F4C-8F4F, U+8F51;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-15-400-normal.225523ee.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+8B2D, U+8B30, U+8B37, U+8B3C, U+8B3E, U+8B41-8B46, U+8B48-8B49, U+8B4C-8B4F, U+8B51-8B54, U+8B56, U+8B59, U+8B5B, U+8B5E-8B5F, U+8B63, U+8B69, U+8B6B-8B6D, U+8B6F, U+8B71, U+8B74, U+8B76, U+8B78-8B79, U+8B7C-8B81, U+8B84-8B85, U+8B8A-8B8F, U+8B92-8B96, U+8B99-8B9A, U+8B9C-8BA0, U+8C38-8C3A, U+8C3D-8C3F, U+8C41, U+8C45, U+8C47-8C49, U+8C4B-8C4C, U+8C4E-8C51, U+8C53-8C55, U+8C57-8C59, U+8C5B, U+8C5D, U+8C62-8C64, U+8C66, U+8C68-8C69, U+8C6B-8C6D, U+8C73, U+8C75-8C76, U+8C78, U+8C7A-8C7C, U+8C7E, U+8C82, U+8C85-8C87, U+8C89-8C8B, U+8C8D-8C8E, U+8C90, U+8C92-8C94, U+8C98-8C99, U+8C9B-8C9C, U+8C9F, U+8CA4, U+8CAD-8CAE, U+8CB2-8CB3, U+8CB6, U+8CB9-8CBA, U+8CBD, U+8CC1-8CC2, U+8CC4-8CC6, U+8CC8-8CC9, U+8CCB, U+8CCD-8CCF, U+8CD2, U+8CD5-8CD6, U+8CD9-8CDA, U+8CDD, U+8CE1, U+8CE3-8CE4, U+8CE6, U+8CE8, U+8CEC, U+8CEF-8CF2, U+8CF4-8CF5, U+8CF7-8CF8, U+8CFA-8CFB, U+8CFD-8CFF, U+8D01, U+8D03-8D04, U+8D07, U+8D09-8D0B, U+8D0D-8D10, U+8D12-8D14, U+8D16-8D17, U+8D1B-8D1D, U+8D65, U+8D67, U+8D69, U+8D6B-8D6E, U+8D71, U+8D73, U+8D76, U+8D7F, U+8D81-8D82, U+8D84, U+8D88, U+8D8D, U+8D90-8D91, U+8D95, U+8D99, U+8D9E-8DA0, U+8DA6, U+8DA8, U+8DAB-8DAC, U+8DAF, U+8DB2, U+8DB5, U+8DB7, U+8DB9-8DBC, U+8DBE;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-16-400-normal.9ab904f2.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+8973-8975, U+8977, U+897A-897E, U+8980, U+8983, U+8988-898A, U+898D, U+8990, U+8993-8995, U+8998, U+899B-899C, U+899F-89A1, U+89A5-89A6, U+89A9, U+89AC, U+89AF-89B0, U+89B2, U+89B4-89B7, U+89BA, U+89BC-89BD, U+89BF-89C1, U+89D4-89D8, U+89DA, U+89DC-89DD, U+89E5, U+89E7, U+89E9, U+89EB, U+89ED, U+89F1, U+89F3-89F4, U+89F6, U+89F8-89F9, U+89FD, U+89FF, U+8A01, U+8A04-8A05, U+8A07, U+8A0C, U+8A0F-8A12, U+8A14-8A16, U+8A1B, U+8A1D-8A1E, U+8A20-8A22, U+8A24-8A26, U+8A2B-8A2C, U+8A2F, U+8A35-8A37, U+8A3B, U+8A3D-8A3E, U+8A40-8A41, U+8A43, U+8A45-8A49, U+8A4D-8A4E, U+8A51-8A54, U+8A56-8A58, U+8A5B-8A5D, U+8A61-8A62, U+8A65, U+8A67, U+8A6C-8A6D, U+8A75-8A77, U+8A79-8A7C, U+8A7E-8A80, U+8A82-8A86, U+8A8B, U+8A8F-8A92, U+8A96-8A97, U+8A99-8A9A, U+8A9F, U+8AA1, U+8AA3, U+8AA5-8AAA, U+8AAE-8AAF, U+8AB3, U+8AB6-8AB7, U+8ABB-8ABC, U+8ABE, U+8AC2-8AC4, U+8AC6, U+8AC8-8ACA, U+8ACC-8ACD, U+8AD0-8AD1, U+8AD3-8AD5, U+8AD7, U+8ADA-8AE2, U+8AE4, U+8AE7, U+8AEB-8AEC, U+8AEE, U+8AF0-8AF1, U+8AF3-8AF7, U+8AFA, U+8AFC, U+8AFF, U+8B01-8B02, U+8B04-8B07, U+8B0A-8B0D, U+8B0F-8B11, U+8B14, U+8B16, U+8B1A, U+8B1C, U+8B1E-8B20, U+8B26, U+8B28, U+8B2B-8B2C;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-17-400-normal.83d29b0c.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+87E2-87E6, U+87EA-87ED, U+87EF, U+87F1, U+87F3, U+87F5-87F8, U+87FA-87FB, U+87FE-87FF, U+8801, U+8803, U+8805-8807, U+8809-880B, U+880D-8816, U+8818-881C, U+881E-881F, U+8821-8822, U+8827-8828, U+882D-882E, U+8830-8832, U+8835-8836, U+8839-883C, U+8841-8845, U+8848-884B, U+884D-884E, U+8851-8852, U+8855-8856, U+8858-885A, U+885C, U+885E-8860, U+8862, U+8864, U+8869, U+886B, U+886E-886F, U+8871-8872, U+8875, U+8877, U+8879, U+887B, U+887D-887E, U+8880-8882, U+8888, U+888D, U+8892, U+8897-889C, U+889E-88A0, U+88A2, U+88A4, U+88A8, U+88AA, U+88AE, U+88B0-88B1, U+88B5, U+88B7, U+88BA, U+88BC-88C0, U+88C3-88C4, U+88C6, U+88CA-88CE, U+88D1-88D4, U+88D8-88D9, U+88DB, U+88DD-88E1, U+88E7-88E8, U+88EF-88F2, U+88F4-88F5, U+88F7, U+88F9, U+88FC, U+8901-8902, U+8904, U+8906, U+890A, U+890C-890F, U+8913, U+8915-8916, U+8918-891A, U+891C-891E, U+8920, U+8925-8928, U+892A-892B, U+8930-8932, U+8935-893B, U+893E, U+8940-8946, U+8949, U+894C-894D, U+894F, U+8952, U+8956-8957, U+895A-895C, U+895E, U+8960-8964, U+8966, U+896A-896B, U+896D-8970;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-18-400-normal.545ddc18.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+8655-8659, U+865B, U+865D-8664, U+8667, U+8669, U+866C, U+866F, U+8671, U+8675-8677, U+867A-867B, U+867D, U+8687-8689, U+868B-868D, U+8691, U+8693, U+8695-8696, U+8698, U+869A, U+869C-869D, U+86A1, U+86A3-86A4, U+86A6-86AB, U+86AD, U+86AF-86B1, U+86B3-86B9, U+86BF-86C1, U+86C3-86C6, U+86C9, U+86CB, U+86CE, U+86D1-86D2, U+86D4-86D5, U+86D7, U+86DA, U+86DC, U+86DE-86E0, U+86E3-86E7, U+86E9, U+86EC-86ED, U+86EF, U+86F8-86FE, U+8700, U+8703-870B, U+870D-8714, U+8719-871A, U+871E-871F, U+8721-8723, U+8725, U+8728-8729, U+872E-872F, U+8731-8732, U+8734, U+8737, U+8739-8740, U+8743, U+8745, U+8749, U+874B-874E, U+8751, U+8753, U+8755, U+8757-8759, U+875D, U+875F-8761, U+8763-8766, U+8768, U+876A, U+876E-876F, U+8771-8772, U+8774, U+8778, U+877B-877C, U+877F, U+8782-8789, U+878B-878C, U+878E, U+8790, U+8793, U+8795, U+8797-8799, U+879E-87A0, U+87A2-87A3, U+87A7, U+87AB-87AF, U+87B1, U+87B3, U+87B5, U+87BB, U+87BD-87C1, U+87C4, U+87C6-87CB, U+87CE, U+87D0, U+87D2, U+87D5-87D6, U+87D9-87DA, U+87DC, U+87DF-87E0;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-19-400-normal.0cb522a5.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+84B4, U+84B9-84BB, U+84BD-84C2, U+84C6-84CA, U+84CC-84D1, U+84D3, U+84D6, U+84D9-84DA, U+84DC, U+84E7, U+84EA, U+84EC, U+84EF-84F2, U+84F4, U+84F7, U+84FA-84FD, U+84FF-8500, U+8502-8503, U+8506-8507, U+850C, U+850E, U+8510, U+8514-8515, U+8517-8518, U+851A-851C, U+851E-851F, U+8521-8525, U+8527, U+852A-852C, U+852F, U+8532-8534, U+8536, U+853E-8541, U+8543, U+8546, U+8548, U+854A-854B, U+854F-8553, U+8555-855A, U+855C-8564, U+8569-856B, U+856D, U+856F, U+8577, U+8579-857B, U+857D-8581, U+8585-8586, U+8588-858C, U+858F-8591, U+8593, U+8597-8598, U+859B-859D, U+859F-85A0, U+85A2, U+85A4-85A5, U+85A7-85A8, U+85AD-85B0, U+85B4, U+85B6-85BA, U+85BC-85BF, U+85C1-85C2, U+85C7, U+85C9-85CB, U+85CE-85D0, U+85D5, U+85D8-85DA, U+85DC, U+85DF-85E1, U+85E5-85E6, U+85E8, U+85ED, U+85F3-85F4, U+85F6-85F7, U+85F9-85FA, U+85FC, U+85FE-8600, U+8602, U+8604-8606, U+860A-860B, U+860D-860E, U+8610-8613, U+8616-861B, U+861E, U+8621-8622, U+8624, U+8627, U+8629, U+862F-8630, U+8636, U+8638-863A, U+863C-863D, U+863F-8642, U+8646, U+864D, U+8652-8654;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-20-400-normal.90a1f123.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+82E8, U+82EA, U+82ED, U+82EF, U+82F3-82F4, U+82F6-82F7, U+82F9, U+82FB, U+82FD-82FE, U+8300-8301, U+8303, U+8306-8308, U+830A-830C, U+8316-8318, U+831B, U+831D-831F, U+8321-8323, U+832B-8335, U+8337, U+833A, U+833C-833D, U+8340, U+8342-8347, U+834A, U+834D-8351, U+8353-8357, U+835A, U+8362-8363, U+8370, U+8373, U+8375, U+8378, U+837C-837D, U+837F-8380, U+8382, U+8384-8387, U+838A, U+838D-838E, U+8392-8396, U+8398-83A0, U+83A2, U+83A6-83AD, U+83B1, U+83B5, U+83BD-83C1, U+83C7, U+83C9, U+83CE-83D1, U+83D4, U+83D6, U+83D8, U+83DD, U+83DF-83E1, U+83E5, U+83E8, U+83EA-83EB, U+83F0, U+83F2, U+83F4, U+83F6-83F9, U+83FB-83FD, U+8401, U+8403-8404, U+8406-8407, U+840A-840B, U+840D, U+840F, U+8411, U+8413, U+8415, U+8417, U+8419, U+8420, U+8422, U+842A, U+842F, U+8431, U+8435, U+8438-8439, U+843C, U+8445-8448, U+844A, U+844D-844F, U+8451-8452, U+8456, U+8458-845A, U+845C, U+845F-8462, U+8464-8467, U+8469-846B, U+846D-8470, U+8473-8474, U+8476-847A, U+847C-847D, U+8481-8482, U+8484-8485, U+848B, U+8490, U+8492-8493, U+8495, U+8497, U+849C, U+849E-849F, U+84A1, U+84A6, U+84A8-84AA, U+84AD, U+84AF, U+84B1;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-21-400-normal.f971a227.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+814A, U+814C, U+8151-8153, U+8157, U+815F-8161, U+8165-8169, U+816D-816F, U+8171, U+8173-8174, U+8177, U+8180-8186, U+8188, U+818A-818B, U+818E, U+8190, U+8193, U+8195-8196, U+8198, U+819B, U+819E, U+81A0, U+81A2, U+81A4, U+81A9, U+81AE, U+81B0, U+81B2, U+81B4-81B5, U+81B8, U+81BA-81BB, U+81BD-81BE, U+81C0-81C3, U+81C5-81C6, U+81C8-81CB, U+81CD-81CF, U+81D1, U+81D5-81DB, U+81DD-81E1, U+81E4-81E5, U+81E7, U+81EB-81EC, U+81EF-81F2, U+81F5-81F6, U+81F8-81FB, U+81FD-8205, U+8209-820B, U+820D, U+820F, U+8212-8214, U+8216, U+8219-821D, U+8221-8222, U+8228-8229, U+822B, U+822E, U+8232-8235, U+8237-8238, U+823A, U+823C, U+8240, U+8243-8246, U+8249, U+824B, U+824E-824F, U+8251, U+8256-825A, U+825C-825D, U+825F-8260, U+8262-8264, U+8267-8268, U+826A-826B, U+826D-826E, U+8271, U+8274, U+8277, U+8279, U+827B, U+827D-8281, U+8283-8284, U+8287, U+8289-828A, U+828D-828E, U+8291-8294, U+8296, U+8298-829B, U+829F-82A1, U+82A3-82A4, U+82A7-82AC, U+82AE, U+82B0, U+82B2, U+82B4, U+82B7, U+82BA-82BC, U+82BE-82BF, U+82C5-82C6, U+82D0, U+82D2-82D3, U+82D5, U+82D9-82DA, U+82DC, U+82DE-82E4, U+82E7;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-22-400-normal.67c23d2e.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+7F77-7F79, U+7F7D-7F80, U+7F82-7F83, U+7F86-7F88, U+7F8B-7F8D, U+7F8F-7F91, U+7F94, U+7F96-7F97, U+7F9A, U+7F9C-7F9D, U+7FA1-7FA3, U+7FA6, U+7FAA, U+7FAD-7FAF, U+7FB2, U+7FB4, U+7FB6, U+7FB8-7FB9, U+7FBC, U+7FBF-7FC0, U+7FC3, U+7FC5-7FC6, U+7FC8, U+7FCA, U+7FCE-7FCF, U+7FD5, U+7FDB, U+7FDF, U+7FE1, U+7FE3, U+7FE5-7FE6, U+7FE8-7FE9, U+7FEB-7FEC, U+7FEE-7FF0, U+7FF2-7FF3, U+7FF9-7FFA, U+7FFD-7FFF, U+8002, U+8004, U+8006-8008, U+800A-800F, U+8011-8014, U+8016, U+8018-8019, U+801C-8021, U+8024, U+8026, U+8028, U+802C, U+802E, U+8030, U+8034-8035, U+8037, U+8039-8040, U+8043-8044, U+8046, U+804A, U+8052, U+8058, U+805A, U+805F-8060, U+8062, U+8064, U+8066, U+8068, U+806D, U+806F-8073, U+8075-8076, U+8079, U+807B, U+807D-8081, U+8084-8088, U+808B, U+808E, U+8093, U+8099-809A, U+809C, U+809E, U+80A4, U+80A6-80A7, U+80AB-80AD, U+80B1, U+80B8-80B9, U+80C4-80C5, U+80C8, U+80CA, U+80CD, U+80CF, U+80D2, U+80D4-80DB, U+80DD, U+80E0, U+80E4-80E6, U+80ED-80F3, U+80F5-80F7, U+80F9-80FC, U+80FE, U+8101, U+8103, U+8109, U+810B, U+810D, U+8116-8118, U+811B-811C, U+811E, U+8120, U+8123-8124, U+8127, U+8129, U+812B-812C, U+812F-8130, U+8135, U+8139-813A, U+813C-813E, U+8141, U+8145-8147;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-23-400-normal.445ada12.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+7D57, U+7D59-7D5D, U+7D63, U+7D65, U+7D67, U+7D6A, U+7D6E, U+7D70, U+7D72-7D73, U+7D78, U+7D7A-7D7B, U+7D7D, U+7D7F, U+7D81-7D83, U+7D85-7D86, U+7D88-7D89, U+7D8B-7D8D, U+7D8F, U+7D91, U+7D93, U+7D96-7D97, U+7D9B-7DA0, U+7DA2-7DA3, U+7DA6-7DA7, U+7DAA-7DAC, U+7DAE-7DB0, U+7DB3, U+7DB5-7DB9, U+7DBD, U+7DC0, U+7DC2-7DC7, U+7DCC-7DCE, U+7DD0, U+7DD5-7DD9, U+7DDC-7DDE, U+7DE1-7DE6, U+7DEA-7DED, U+7DF1-7DF2, U+7DF5-7DF6, U+7DF9-7DFA, U+7E00, U+7E05, U+7E08-7E0B, U+7E10-7E12, U+7E15, U+7E17, U+7E1C-7E1D, U+7E1F-7E23, U+7E27-7E28, U+7E2C-7E2D, U+7E2F, U+7E31-7E33, U+7E35-7E37, U+7E39-7E3B, U+7E3D, U+7E3F, U+7E43-7E48, U+7E4E, U+7E50, U+7E52, U+7E56, U+7E58-7E5A, U+7E5D-7E5F, U+7E61-7E62, U+7E65-7E67, U+7E69-7E6B, U+7E6D-7E6F, U+7E73, U+7E75, U+7E78-7E79, U+7E7B-7E7F, U+7E81-7E83, U+7E86-7E8A, U+7E8C-7E8E, U+7E90-7E96, U+7E98, U+7E9A-7E9F, U+7F38, U+7F3A-7F3F, U+7F43-7F45, U+7F47, U+7F4C-7F50, U+7F52-7F55, U+7F58, U+7F5B-7F5D, U+7F5F, U+7F61, U+7F63-7F69, U+7F6B, U+7F6D, U+7F71;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-24-400-normal.c5dc7a54.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+7BC8, U+7BCA-7BCC, U+7BCF, U+7BD4, U+7BD6-7BD7, U+7BD9-7BDB, U+7BDD, U+7BE5-7BE6, U+7BE8-7BEA, U+7BF0, U+7BF2-7BFA, U+7BFC, U+7BFE, U+7C00-7C04, U+7C06-7C07, U+7C09, U+7C0B-7C0F, U+7C11-7C14, U+7C17, U+7C19, U+7C1B, U+7C1E-7C20, U+7C23, U+7C25-7C28, U+7C2A-7C2C, U+7C2F, U+7C31, U+7C33-7C34, U+7C36-7C3A, U+7C3D-7C3E, U+7C40, U+7C42-7C43, U+7C45-7C46, U+7C4A, U+7C4C, U+7C4F-7C5F, U+7C61, U+7C63-7C65, U+7C67, U+7C69, U+7C6C-7C70, U+7C72, U+7C75, U+7C79, U+7C7B-7C7E, U+7C81-7C83, U+7C86-7C87, U+7C8D, U+7C8F-7C90, U+7C94, U+7C9E, U+7CA0-7CA2, U+7CA4-7CA6, U+7CA8, U+7CAB, U+7CAD-7CAE, U+7CB0-7CB3, U+7CB6-7CB7, U+7CB9-7CBD, U+7CBF-7CC0, U+7CC2, U+7CC4-7CC5, U+7CC7-7CCA, U+7CCD-7CCF, U+7CD2-7CD5, U+7CD7-7CDA, U+7CDC-7CDD, U+7CDF-7CE0, U+7CE2, U+7CE6, U+7CE9, U+7CEB, U+7CEF, U+7CF2, U+7CF4-7CF6, U+7CF9-7CFA, U+7CFE, U+7D02-7D03, U+7D06-7D0A, U+7D0F, U+7D11-7D13, U+7D15-7D16, U+7D1C-7D1E, U+7D23, U+7D26, U+7D2A, U+7D2C-7D2E, U+7D31-7D32, U+7D35, U+7D3C-7D41, U+7D43, U+7D45, U+7D47-7D48, U+7D4B, U+7D4D-7D4F, U+7D51, U+7D53, U+7D55-7D56;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-25-400-normal.80303720.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+7A17-7A19, U+7A1B, U+7A1E-7A21, U+7A27, U+7A2B, U+7A2D, U+7A2F-7A31, U+7A34-7A35, U+7A37-7A3B, U+7A3E, U+7A43-7A49, U+7A4C, U+7A4E, U+7A50, U+7A55-7A57, U+7A59, U+7A5C-7A5D, U+7A5F-7A63, U+7A65, U+7A67, U+7A69-7A6A, U+7A6D, U+7A70, U+7A75, U+7A78-7A79, U+7A7D-7A7E, U+7A80, U+7A82, U+7A84-7A86, U+7A88, U+7A8A-7A8B, U+7A90-7A91, U+7A94-7A98, U+7A9E, U+7AA0, U+7AA3, U+7AA9, U+7AAC, U+7AB0, U+7AB3, U+7AB5-7AB6, U+7AB9-7ABF, U+7AC3, U+7AC5-7ACA, U+7ACC-7ACF, U+7AD1-7AD3, U+7AD5, U+7ADA-7ADB, U+7ADD, U+7ADF, U+7AE1-7AE2, U+7AE6-7AED, U+7AF0-7AF1, U+7AF4, U+7AF8, U+7AFA-7AFB, U+7AFD-7AFE, U+7B02, U+7B04, U+7B06-7B08, U+7B0A-7B0B, U+7B0F, U+7B12, U+7B14, U+7B18-7B19, U+7B1E-7B1F, U+7B23, U+7B25, U+7B27-7B2B, U+7B2D-7B31, U+7B33-7B36, U+7B3B, U+7B3D, U+7B3F-7B41, U+7B45, U+7B47, U+7B4C-7B50, U+7B53, U+7B55, U+7B5D, U+7B60, U+7B64-7B66, U+7B69-7B6A, U+7B6C-7B75, U+7B77, U+7B79-7B7A, U+7B7F, U+7B84, U+7B86, U+7B89, U+7B8D-7B92, U+7B96, U+7B98-7BA0, U+7BA5, U+7BAC-7BAD, U+7BAF-7BB0, U+7BB2, U+7BB4-7BB6, U+7BBA-7BBD, U+7BC1-7BC2, U+7BC5-7BC6;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-26-400-normal.2e1c2575.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+7851-7852, U+785C, U+785E, U+7860-7861, U+7863-7864, U+7868, U+786A, U+786E-786F, U+7872, U+7874, U+787A, U+787C, U+787E, U+7886-7887, U+788A, U+788C-788F, U+7893-7895, U+7898, U+789A, U+789D-789F, U+78A1, U+78A3-78A4, U+78A8-78AA, U+78AC-78AD, U+78AF-78B3, U+78B5, U+78BB-78BF, U+78C5-78CC, U+78CE, U+78D1-78D6, U+78DA-78DB, U+78DF-78E1, U+78E4, U+78E6-78E7, U+78EA, U+78EC, U+78F2-78F4, U+78F6-78F7, U+78F9-78FB, U+78FD-7901, U+7906-7907, U+790C, U+7910-7912, U+7919-791C, U+791E-7920, U+7925-792E, U+7930-7931, U+7934-7935, U+793B, U+793D, U+793F, U+7941-7942, U+7944-7946, U+794A-794B, U+794F, U+7951, U+7954-7955, U+7957-7958, U+795A-795C, U+795F-7960, U+7962, U+7967, U+7969, U+796B, U+7972, U+7977, U+7979-797C, U+797E-7980, U+798A-798E, U+7991, U+7993-7996, U+7998, U+799B-799D, U+79A1, U+79A6-79AB, U+79AE-79B1, U+79B3-79B4, U+79B8-79BB, U+79BD-79BE, U+79C2, U+79C4, U+79C7-79CA, U+79CC-79CD, U+79CF, U+79D4-79D6, U+79DA, U+79DD-79E3, U+79E5, U+79E7, U+79EA-79ED, U+79F1, U+79F8, U+79FC, U+7A02-7A03, U+7A05, U+7A07-7A0A, U+7A0C-7A0D, U+7A11, U+7A15;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-27-400-normal.d5d9b474.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+768C-768E, U+7690, U+7693, U+7695-7696, U+7699-76A8, U+76AA, U+76AD, U+76AF-76B0, U+76B4, U+76B6-76BA, U+76BD, U+76C1-76C3, U+76C5, U+76C8-76C9, U+76CB-76CE, U+76D2, U+76D4, U+76D6, U+76D9, U+76DC, U+76DE, U+76E0-76E1, U+76E5-76E8, U+76EA-76EC, U+76F0-76F1, U+76F6, U+76F9, U+76FB-76FC, U+7700, U+7704, U+7706-7708, U+770A, U+770E, U+7712, U+7714-7715, U+7717, U+7719-771C, U+7722, U+7724-7726, U+7728, U+772D-772F, U+7734-7739, U+773D-773E, U+7742, U+7745-7747, U+774A, U+774D-774F, U+7752, U+7756-7758, U+775A-775C, U+775E-7760, U+7762, U+7764-7765, U+7767, U+776A-776C, U+7770, U+7772-7774, U+7779-777A, U+777C-7780, U+7784, U+778B-778E, U+7794-7796, U+779A, U+779E-77A0, U+77A2, U+77A4-77A5, U+77A7, U+77A9-77AA, U+77AE-77B1, U+77B5-77B7, U+77B9, U+77BB-77BF, U+77C3, U+77C7, U+77C9, U+77CD, U+77D1-77D2, U+77D5, U+77D7, U+77D9-77DA, U+77DC, U+77DE-77E0, U+77E3-77E4, U+77E6-77E7, U+77E9-77EA, U+77EC, U+77EE, U+77F0-77F1, U+77F4, U+77F8, U+77FB-77FC, U+7805-7806, U+7809, U+780C-780E, U+7811-7812, U+7819, U+781D, U+7820-7823, U+7826-7827, U+782C-782E, U+7830, U+7835, U+7837, U+783A, U+783F, U+7843-7845, U+7847-7848, U+784C, U+784E-784F;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-28-400-normal.246020f6.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+7511-7513, U+7515-7517, U+751C, U+751E, U+7520-7522, U+7524, U+7526-7527, U+7529-752C, U+752F, U+7536, U+7538-7539, U+753C-7540, U+7543-7544, U+7546-754B, U+754D-7550, U+7552, U+7557, U+755A-755B, U+755D-755F, U+7561-7562, U+7564, U+7566-7567, U+7569, U+756B-756D, U+756F, U+7571-7572, U+7574-757E, U+7581-7582, U+7585-7587, U+7589-758C, U+758F-7590, U+7592-7595, U+7599-759A, U+759C-759D, U+75A2-75A5, U+75B0-75B1, U+75B3-75B5, U+75B7-75B8, U+75BA, U+75BD, U+75BF-75C4, U+75C6, U+75CA, U+75CC-75CF, U+75D3-75D4, U+75D7-75D8, U+75DC-75E1, U+75E3-75E4, U+75E7, U+75EC, U+75EE-75F3, U+75F9, U+75FC, U+75FE-7604, U+7607-760C, U+760F, U+7612-7613, U+7615-7616, U+7618-7619, U+761B-7629, U+762D, U+7630, U+7632-7635, U+7638-763C, U+7640-7641, U+7643-764B, U+764E, U+7655, U+7658-7659, U+765C, U+765F, U+7661-7662, U+7664-7665, U+7667-766A, U+766C-7672, U+7674, U+7676, U+7678, U+7680-7683, U+7685, U+7688, U+768B;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-29-400-normal.b0964657.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+736C, U+736E-7371, U+7375, U+7377-737C, U+7380-7381, U+7383, U+7385-7386, U+738A, U+738E, U+7390, U+7393-7398, U+739C, U+739E-73A0, U+73A2, U+73A5-73A6, U+73A8, U+73AA-73AB, U+73AD, U+73B3, U+73B5, U+73B7, U+73B9-73BD, U+73BF, U+73C5-73C6, U+73C9-73CC, U+73CE-73CF, U+73D2-73D3, U+73D6, U+73D9, U+73DD-73DE, U+73E1, U+73E3-73E7, U+73E9-73EA, U+73EE, U+73F1, U+73F4-73F5, U+73F7-73FB, U+73FD, U+73FF-7401, U+7404-7405, U+7407, U+740A, U+7411, U+7413, U+741A-741B, U+7421, U+7424, U+7426, U+7428-7431, U+7433, U+7439-743A, U+743F-7441, U+7443-7444, U+7446-7447, U+744B, U+744D, U+7451-7453, U+7455, U+7457, U+7459-745A, U+745C-745D, U+745F, U+7462-7464, U+7466-746B, U+746D-7473, U+7476, U+747E, U+7480-7481, U+7485-7489, U+748B, U+748F-7492, U+7497-749A, U+749C, U+749E-74A3, U+74A5-74A6, U+74A8-74AB, U+74AE-74AF, U+74B1-74B2, U+74B5, U+74B9-74BB, U+74BD, U+74BF, U+74C8-74CA, U+74CC, U+74CF-74D0, U+74D3-74D4, U+74D6, U+74D8, U+74DA-74DB, U+74DE-74E0, U+74E3-74E4, U+74E7-74EB, U+74EE-74F2, U+74F4, U+74F7-74F8, U+74FA-74FC, U+74FF, U+7501, U+7503-7506, U+750C-750E;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-30-400-normal.4685c172.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+7166, U+7168, U+716C, U+7179, U+7180, U+7184-7185, U+7187-7188, U+718C, U+718F, U+7192, U+7194-7196, U+7199-719B, U+71A0, U+71A2, U+71A8, U+71AC, U+71AE-71B0, U+71B2-71B3, U+71B9-71BA, U+71BE-71C1, U+71C4, U+71C9, U+71CB-71CC, U+71CE, U+71D0, U+71D2-71D4, U+71D6-71D7, U+71D9-71DA, U+71DC, U+71DF-71E0, U+71E6-71E7, U+71EC-71EE, U+71F4-71F5, U+71F8-71F9, U+71FC, U+71FE-7200, U+7207-7209, U+720D, U+7210, U+7213, U+7215, U+7217, U+721A, U+721D, U+721F, U+7224, U+7228, U+722B, U+722D, U+722F-7230, U+7232, U+7234, U+7238-7239, U+723B-723C, U+723E-7243, U+7245-7246, U+724B, U+724E-7250, U+7252-7253, U+7255-7258, U+725A, U+725C, U+725E, U+7260, U+7263, U+7268, U+726B, U+726E-726F, U+7271, U+7274, U+7277-7278, U+727B-727C, U+727E-7282, U+7284, U+7287, U+7289, U+728D-728E, U+7292-7293, U+7296, U+729B, U+72A2, U+72A7-72A8, U+72AD-72AE, U+72B0-72B2, U+72B4, U+72B9, U+72BE, U+72C0-72C1, U+72C3-72C4, U+72C6-72C7, U+72C9, U+72CC, U+72CE, U+72D2, U+72D5-72D6, U+72D8, U+72DF-72E2, U+72E5, U+72F3-72F4, U+72F7, U+72F9-72FB, U+72FD-72FE, U+7302, U+7304-7305, U+7307, U+730A-730B, U+730D, U+7312-7313, U+7316-7319, U+731C-731E, U+7322, U+7324, U+7327-7329, U+732C, U+732F, U+7331-7337, U+7339-733B, U+733D-733E, U+7343, U+734D-7350, U+7352, U+7356-7358, U+735D-7360, U+7366-736B;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-31-400-normal.1e464caa.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+6F58-6F5B, U+6F5D-6F5E, U+6F60-6F62, U+6F66, U+6F68, U+6F6C-6F6D, U+6F6F, U+6F74, U+6F78, U+6F7A, U+6F7C-6F7E, U+6F80, U+6F82-6F83, U+6F86-6F88, U+6F8B-6F8E, U+6F90-6F94, U+6F96-6F98, U+6F9A, U+6F9D, U+6F9F-6FA1, U+6FA3, U+6FA5-6FA8, U+6FAE-6FB1, U+6FB3, U+6FB5-6FB7, U+6FB9, U+6FBC, U+6FBE, U+6FC2, U+6FC5-6FCA, U+6FD4-6FD5, U+6FD8, U+6FDA-6FDB, U+6FDE-6FE0, U+6FE4, U+6FE8-6FE9, U+6FEB-6FEC, U+6FEE, U+6FF0, U+6FF3, U+6FF5-6FF6, U+6FF9-6FFA, U+6FFC-6FFE, U+7000-7001, U+7005-7007, U+7009-700B, U+700D, U+700F, U+7011, U+7015, U+7017-7018, U+701A-701B, U+701D-7020, U+7023, U+7026, U+7028, U+702F-7030, U+7032, U+7034, U+7037, U+7039-703A, U+703C, U+703E, U+7043-7044, U+7047-704C, U+704E, U+7051, U+7054-7055, U+705D-705E, U+7064-7065, U+7069, U+706C, U+706E, U+7075-7076, U+707E, U+7081, U+7085-7086, U+7094-7098, U+709B, U+709F, U+70A4, U+70AB-70AC, U+70AE-70B1, U+70B3-70B4, U+70B7, U+70BB, U+70CA-70CB, U+70D1, U+70D3-70D6, U+70D8-70D9, U+70DC-70DD, U+70DF, U+70E4, U+70EC, U+70F1, U+70FA, U+70FD, U+7103-7108, U+710B-710C, U+710F, U+7114, U+7119, U+711C, U+711E, U+7120, U+712B, U+712D-7131, U+7138, U+7141, U+7145-7147, U+7149-714B, U+7150-7153, U+7155-7157, U+715A, U+715C, U+715E, U+7160, U+7162, U+7164-7165;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-32-400-normal.4e6ecaa7.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+6D7C, U+6D80-6D82, U+6D85, U+6D87, U+6D89-6D8A, U+6D8C-6D8E, U+6D91-6D98, U+6D9C, U+6DAA-6DAC, U+6DAE, U+6DB4-6DB5, U+6DB7-6DB9, U+6DBD, U+6DBF, U+6DC2, U+6DC4-6DC8, U+6DCA, U+6DCC, U+6DCE-6DD0, U+6DD2, U+6DD5-6DD6, U+6DD8-6DDB, U+6DDD-6DE0, U+6DE2, U+6DE4-6DE6, U+6DE8-6DEA, U+6DEC, U+6DEE-6DF0, U+6DF2, U+6DF4, U+6DF6, U+6DF8-6DFA, U+6DFC, U+6E00, U+6E04, U+6E0A, U+6E17, U+6E19, U+6E1D-6E20, U+6E22-6E25, U+6E27, U+6E2B, U+6E2D-6E2E, U+6E32, U+6E34, U+6E36, U+6E38-6E3C, U+6E42-6E45, U+6E48-6E49, U+6E4B-6E4F, U+6E51-6E54, U+6E57, U+6E5B-6E5F, U+6E62-6E63, U+6E68, U+6E6B, U+6E6E, U+6E72-6E73, U+6E76, U+6E7B, U+6E7D, U+6E82, U+6E89, U+6E8C-6E8D, U+6E8F, U+6E93, U+6E98-6E99, U+6E9F-6EA0, U+6EA5, U+6EA7, U+6EAA-6EAB, U+6EAD-6EAF, U+6EB1-6EB4, U+6EB7, U+6EBB-6EBD, U+6EBF-6EC4, U+6EC7-6ECA, U+6ECC-6ECF, U+6ED3-6ED5, U+6ED9-6EDB, U+6EE6, U+6EEB-6EEF, U+6EF7-6EF9, U+6EFB, U+6EFD-6EFF, U+6F04, U+6F08-6F0A, U+6F0C-6F0D, U+6F10-6F11, U+6F13, U+6F15-6F16, U+6F18, U+6F1A-6F1B, U+6F25-6F26, U+6F29-6F2A, U+6F2D, U+6F2F-6F33, U+6F35-6F36, U+6F38, U+6F3B-6F3C, U+6F3E-6F3F, U+6F41, U+6F45, U+6F4F, U+6F51-6F53, U+6F57;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-33-400-normal.ead7c452.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+6B85, U+6B89, U+6B8D, U+6B95, U+6B97-6B98, U+6B9B, U+6B9E-6BA0, U+6BA2-6BA4, U+6BA8-6BB3, U+6BB7-6BB9, U+6BBC-6BBE, U+6BC0, U+6BC3-6BC4, U+6BC6-6BC9, U+6BCB-6BCC, U+6BCF, U+6BD3, U+6BD6-6BD8, U+6BDA, U+6BDF, U+6BE1, U+6BE3, U+6BE6-6BE7, U+6BEB-6BEC, U+6BEE, U+6BF1, U+6BF3, U+6BF7, U+6BF9, U+6BFF, U+6C02, U+6C04-6C05, U+6C08-6C0A, U+6C0D-6C0E, U+6C10, U+6C12-6C14, U+6C19, U+6C1B, U+6C1F, U+6C24, U+6C26-6C28, U+6C2C, U+6C2E, U+6C33, U+6C35-6C36, U+6C3A-6C3B, U+6C3E-6C40, U+6C4A-6C4B, U+6C4D, U+6C4F, U+6C52, U+6C54-6C55, U+6C59, U+6C5B-6C5E, U+6C62, U+6C67-6C68, U+6C6A-6C6B, U+6C6D, U+6C6F, U+6C73-6C74, U+6C76, U+6C78-6C79, U+6C7B, U+6C7E, U+6C81-6C87, U+6C89, U+6C8C-6C8D, U+6C90, U+6C92-6C95, U+6C97-6C98, U+6C9A-6C9C, U+6C9F, U+6CAA-6CAE, U+6CB0-6CB2, U+6CB4, U+6CBA, U+6CBD-6CBE, U+6CC2, U+6CC5-6CC6, U+6CCD, U+6CCF-6CD4, U+6CD6-6CD7, U+6CD9-6CDD, U+6CE0, U+6CE7, U+6CE9-6CEF, U+6CF1-6CF2, U+6CF4, U+6CFB, U+6D00-6D01, U+6D04, U+6D07, U+6D0A, U+6D0C, U+6D0E-6D0F, U+6D11, U+6D13, U+6D19-6D1A, U+6D1F, U+6D24, U+6D26-6D28, U+6D2B, U+6D2E-6D2F, U+6D31, U+6D33-6D36, U+6D38-6D39, U+6D3C-6D3D, U+6D3F, U+6D57-6D5B, U+6D5E-6D61, U+6D64-6D65, U+6D67, U+6D6C, U+6D6F-6D70, U+6D79;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-34-400-normal.b8a60937.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+69DD-69DE, U+69E2-69E3, U+69E5, U+69E7-69EB, U+69ED-69EF, U+69F1-69F6, U+69F9, U+69FE-6A01, U+6A03, U+6A05, U+6A0A, U+6A0C, U+6A0F, U+6A11-6A15, U+6A17, U+6A1A-6A1B, U+6A1D-6A20, U+6A22-6A24, U+6A28, U+6A2E, U+6A30, U+6A32-6A38, U+6A3B, U+6A3E-6A3F, U+6A44-6A4A, U+6A4E, U+6A50-6A52, U+6A54-6A56, U+6A5B, U+6A61-6A62, U+6A64, U+6A66-6A67, U+6A6A-6A6B, U+6A71-6A73, U+6A78, U+6A7A, U+6A7E-6A7F, U+6A81, U+6A83-6A84, U+6A86-6A87, U+6A89, U+6A8B, U+6A8D, U+6A90-6A91, U+6A94, U+6A97, U+6A9B, U+6A9D-6AA3, U+6AA5, U+6AAA-6AAC, U+6AAE-6AB1, U+6AB3-6AB4, U+6AB8, U+6ABB, U+6ABD-6ABF, U+6AC1-6AC3, U+6AC6, U+6AC8-6AC9, U+6ACC, U+6AD0-6AD1, U+6AD3-6AD6, U+6ADA-6ADF, U+6AE2, U+6AE4, U+6AE7-6AE8, U+6AEA, U+6AEC, U+6AF0-6AF3, U+6AF8, U+6AFA, U+6AFC-6AFD, U+6B02-6B03, U+6B06-6B07, U+6B09-6B0B, U+6B0F-6B12, U+6B16-6B17, U+6B1B, U+6B1D-6B1F, U+6B23-6B24, U+6B28, U+6B2B-6B2C, U+6B2F, U+6B35-6B39, U+6B3B, U+6B3D, U+6B3F, U+6B43, U+6B46-6B47, U+6B49-6B4A, U+6B4D-6B4E, U+6B50, U+6B52, U+6B54, U+6B56, U+6B58-6B59, U+6B5B, U+6B5D, U+6B5F-6B61, U+6B65, U+6B67, U+6B6B-6B6C, U+6B6E, U+6B70, U+6B72, U+6B75, U+6B77-6B7A, U+6B7D-6B84;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-35-400-normal.98673317.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+6855, U+6857-6859, U+685B, U+685D, U+685F, U+6863, U+6867, U+686B, U+686E-6872, U+6874-6875, U+6877, U+6879-687C, U+687E-687F, U+6882-6884, U+6886, U+6888, U+688D-6890, U+6894, U+6896, U+6898-689C, U+689F-68A3, U+68A5-68A7, U+68A9-68AB, U+68AD-68AF, U+68B2-68B5, U+68B9-68BC, U+68C3, U+68C5-68C6, U+68C8-68CA, U+68CC-68CD, U+68CF-68D1, U+68D3-68D9, U+68DC-68DD, U+68E0-68E1, U+68E3-68E5, U+68E7-68E8, U+68EA-68ED, U+68EF-68F1, U+68F5-68F7, U+68F9, U+68FB-68FD, U+6900-6901, U+6903-6904, U+6906-690C, U+690F-6911, U+6913, U+6916-6917, U+6919-691B, U+6921-6923, U+6925-6926, U+6928, U+692A, U+6930-6931, U+6933-6936, U+6938-6939, U+693B, U+693D, U+6942, U+6945-6946, U+6949, U+694E, U+6954, U+6957, U+6959, U+695B-695E, U+6961-6966, U+6968-696C, U+696E-6974, U+6977-697B, U+697E-6981, U+6986, U+698D, U+6991-6992, U+6994-6996, U+6998, U+699C, U+69A0-69A1, U+69A5-69A8, U+69AB, U+69AD, U+69AF-69B2, U+69B4, U+69B7-69B8, U+69BA-69BC, U+69BE-69C1, U+69C3, U+69C5, U+69C7-69C8, U+69CA, U+69CE-69D1, U+69D3, U+69D6-69D7, U+69D9;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-36-400-normal.7d5333ba.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+667E-6680, U+6683-6684, U+6688, U+668B-668E, U+6690, U+6692, U+6698-669D, U+669F-66A0, U+66A2, U+66A4, U+66AD, U+66B1-66B3, U+66B5, U+66B8-66B9, U+66BB-66BC, U+66BE-66C4, U+66C6, U+66C8-66C9, U+66CC, U+66CE-66CF, U+66D4, U+66DA-66DB, U+66DD, U+66DF-66E0, U+66E6, U+66E8-66E9, U+66EB-66EC, U+66EE, U+66F5, U+66F7, U+66FA-66FC, U+6701, U+6705, U+6707, U+670C, U+670E-6710, U+6712-6716, U+6719, U+671C, U+671E, U+6720, U+6722, U+6725-6726, U+672E, U+6733, U+6735-6738, U+673E-673F, U+6741, U+6743, U+6745-6748, U+674C-674D, U+6753-6755, U+6759, U+675D-675E, U+6760, U+6762-6764, U+6766, U+676A, U+676C, U+676E, U+6770, U+6772-6774, U+6776-6777, U+677B-677C, U+6780-6781, U+6784-6785, U+6787, U+6789, U+678B-678C, U+678E-678F, U+6791-6793, U+6796, U+6798-6799, U+679B, U+67A1, U+67A4, U+67A6, U+67A9, U+67B0-67B5, U+67B7-67B9, U+67BB-67BE, U+67C0-67C3, U+67C5-67C6, U+67C8-67C9, U+67CE, U+67D2, U+67D7-67D9, U+67DB-67DE, U+67E1-67E2, U+67E4, U+67E6-67E7, U+67E9, U+67EC, U+67EE-67F0, U+67F2, U+67F6-67F7, U+67F9-67FA, U+67FC, U+67FE, U+6801-6802, U+6805, U+6810, U+6814, U+6818-6819, U+681D, U+681F, U+6822, U+6827-6829, U+682B-682D, U+682F-6834, U+683B, U+683E-6840, U+6844-6846, U+6849-684A, U+684C-684E, U+6852-6854;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-37-400-normal.65080e31.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+64D2, U+64D4-64D5, U+64D7-64D8, U+64DA, U+64E0-64E1, U+64E3-64E5, U+64E7, U+64E9-64EA, U+64ED, U+64EF-64F2, U+64F4-64F7, U+64FA-64FB, U+64FD-6501, U+6504-6505, U+6508-650A, U+650F, U+6513-6514, U+6516, U+6518-6519, U+651B-651F, U+6522, U+6524, U+6526, U+6529-652C, U+652E, U+6531-6532, U+6534-6538, U+653A, U+653C-653D, U+6543-6544, U+6547-6549, U+654D-654E, U+6550, U+6552, U+6554-6556, U+6558, U+655D-6560, U+6567, U+656B, U+6572, U+6578, U+657A, U+657D, U+6581-6585, U+6588, U+658A, U+658C, U+6592, U+6595, U+6598, U+659B, U+659D, U+659F-65A1, U+65A3-65A6, U+65AB, U+65AE, U+65B2-65B5, U+65B7-65B8, U+65BE-65BF, U+65C1-65C4, U+65C6, U+65C8-65C9, U+65CC, U+65CE, U+65D0, U+65D2, U+65D4, U+65D6, U+65D8-65D9, U+65DB, U+65DF-65E1, U+65E3, U+65F0-65F2, U+65F4-65F5, U+65F9, U+65FB-65FC, U+65FE-6600, U+6603-6604, U+6608-660A, U+660D, U+6611-6612, U+6615-6616, U+661C-661E, U+6621-6624, U+6626, U+6629-662C, U+662E, U+6630-6631, U+6633-6637, U+6639-663B, U+663F-6641, U+6644-6646, U+6648-664A, U+664C, U+664E-664F, U+6651, U+6657-6665, U+6667-6668, U+666A-666D, U+6670, U+6673, U+6675, U+6677-6679, U+667B-667C;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-38-400-normal.6ca15115.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+62CF, U+62D1, U+62D4-62D6, U+62DA, U+62DC, U+62EA, U+62EE-62EF, U+62F1-62F2, U+62F4-62F5, U+62FC-62FD, U+62FF, U+6302-6304, U+6308-630D, U+6310, U+6313, U+6316, U+6318, U+631B, U+6327, U+6329-632A, U+632D, U+6332, U+6335-6336, U+6339-633C, U+633E, U+6341-6344, U+6346, U+634A-634E, U+6350, U+6352-6354, U+6358-6359, U+635B, U+6365-6366, U+6369, U+636B-636D, U+6371-6372, U+6374-6378, U+637A, U+637C-637D, U+637F-6380, U+6382, U+6384, U+6387, U+6389-638A, U+638E-6390, U+6394-6396, U+6399-639A, U+639E, U+63A0, U+63A3-63A4, U+63A6, U+63A9, U+63AB-63AF, U+63B5, U+63BD-63BE, U+63C0-63C1, U+63C4-63C6, U+63C8, U+63CE, U+63D1-63D6, U+63DC, U+63E0, U+63E3, U+63E5, U+63E9-63ED, U+63F2-63F3, U+63F5-63F9, U+6406, U+6409-640A, U+640F-6410, U+6412-6414, U+6416-6418, U+641E, U+6420, U+6422, U+6424-6426, U+6428-642A, U+642F-6430, U+6434-6436, U+643D, U+643F, U+644B, U+644E-644F, U+6451-6454, U+645A-645D, U+645F-6461, U+6463, U+6467, U+646D, U+6473-6474, U+6476, U+6478-6479, U+647B, U+647D, U+6485, U+6487-6488, U+648F-6491, U+6493, U+6495, U+6498-649B, U+649D-649F, U+64A1, U+64A3, U+64A6, U+64A8-64A9, U+64AC, U+64B3, U+64BB-64BF, U+64C2, U+64C4-64C5, U+64C7, U+64C9-64CC, U+64CE, U+64D0-64D1;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-39-400-normal.776e4122.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+6117, U+6119, U+611C, U+611E, U+6120-6122, U+6127-6128, U+612A-612C, U+6130-6131, U+6134-6137, U+6139-613A, U+613C-613F, U+6141-6142, U+6144-6147, U+6149-614A, U+614D, U+6153, U+6158-615A, U+615D-6160, U+6164-6165, U+616B-616C, U+616F, U+6171-6175, U+6177-6178, U+617B-6181, U+6183-6184, U+6187, U+618A-618B, U+618D, U+6192-6194, U+6196-619A, U+619C-619D, U+619F-61A0, U+61A5, U+61A8, U+61AA-61AE, U+61B8-61BA, U+61BC, U+61BE, U+61C0-61C3, U+61C6, U+61C8, U+61CA-61CF, U+61D5, U+61DC-61DF, U+61E1-61E3, U+61E5-61E9, U+61EC-61ED, U+61EF, U+61F4-61F7, U+61FA, U+61FC-6201, U+6203-6204, U+6207-620A, U+620D-620E, U+6213-6215, U+621B-621E, U+6220-6223, U+6227, U+6229-622B, U+622E, U+6230-6233, U+6236, U+6239, U+623D-623E, U+6241-6244, U+6246, U+6248, U+624C, U+624E, U+6250-6252, U+6254, U+6256, U+6258, U+625A-625C, U+625E, U+6260-6261, U+6263-6264, U+6268, U+626D, U+626F, U+6273, U+627A-627E, U+6282-6283, U+6285, U+6289, U+628D-6290, U+6292-6294, U+6296, U+6299, U+629B, U+62A6, U+62A8, U+62AC, U+62B3, U+62B6-62B7, U+62BA-62BB, U+62BE-62BF, U+62C2, U+62C4, U+62C6-62C8, U+62CA, U+62CE;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-40-400-normal.b3cccdeb.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+5F6C-5F6D, U+5F6F, U+5F72-5F75, U+5F78, U+5F7A, U+5F7D-5F7F, U+5F82-5F83, U+5F87-5F89, U+5F8D, U+5F8F, U+5F91, U+5F96, U+5F99, U+5F9C-5F9D, U+5FA0, U+5FA2, U+5FA4, U+5FA7-5FA8, U+5FAB-5FAD, U+5FAF-5FB1, U+5FB5, U+5FB7-5FB8, U+5FBC-5FBD, U+5FC4, U+5FC7-5FC9, U+5FCB, U+5FD0-5FD4, U+5FDD-5FDE, U+5FE1-5FE2, U+5FE4, U+5FE8-5FEA, U+5FEC-5FF3, U+5FF6, U+5FF8, U+5FFA-5FFD, U+5FFF, U+6007, U+600A, U+600D-6010, U+6013-6015, U+6017-601B, U+601F, U+6021-6022, U+6024, U+6026, U+6029, U+602B, U+602D, U+6031, U+6033, U+6035, U+603A, U+6040-6043, U+6046-604A, U+604C-604D, U+6051, U+6054-6057, U+6059-605A, U+605D, U+605F-6064, U+6067, U+606A-606C, U+6070-6071, U+6077, U+607E-607F, U+6081-6086, U+6088-608E, U+6091-6093, U+6095-6098, U+609A-609B, U+609D-609E, U+60A2, U+60A4-60A5, U+60A7-60A8, U+60B0-60B1, U+60B3-60B5, U+60B7-60B8, U+60BB, U+60BD-60BE, U+60C2, U+60C4, U+60C6-60CB, U+60CE-60CF, U+60D3-60D5, U+60D8-60D9, U+60DB, U+60DD-60DF, U+60E1-60E2, U+60E5, U+60EE, U+60F0-60F2, U+60F4-60F8, U+60FA-60FD, U+6100, U+6102-6103, U+6106-6108, U+610A, U+610C-610E, U+6110-6114, U+6116;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-41-400-normal.e95e8b55.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+5D9B, U+5D9D, U+5D9F-5DA0, U+5DA2, U+5DA4, U+5DA7, U+5DAB-5DAC, U+5DAE, U+5DB0, U+5DB2, U+5DB4, U+5DB7-5DB9, U+5DBC-5DBD, U+5DC3, U+5DC7, U+5DC9, U+5DCB-5DCE, U+5DD0-5DD3, U+5DD6-5DD9, U+5DDB, U+5DE0, U+5DE2, U+5DE4, U+5DE9, U+5DF2, U+5DF5, U+5DF8-5DF9, U+5DFD, U+5DFF-5E00, U+5E07, U+5E0B, U+5E0D, U+5E11-5E12, U+5E14-5E15, U+5E18-5E1B, U+5E1F-5E20, U+5E25, U+5E28, U+5E2E, U+5E32, U+5E35-5E37, U+5E3E, U+5E40, U+5E43-5E44, U+5E47, U+5E49, U+5E4B, U+5E4E, U+5E50-5E51, U+5E54, U+5E56-5E58, U+5E5B-5E5C, U+5E5E-5E5F, U+5E62, U+5E64, U+5E68, U+5E6A-5E6E, U+5E70, U+5E75-5E77, U+5E7A, U+5E7F-5E80, U+5E87, U+5E8B, U+5E8E, U+5E96, U+5E99-5E9A, U+5EA0, U+5EA2, U+5EA4-5EA5, U+5EA8, U+5EAA, U+5EAC, U+5EB1, U+5EB3, U+5EB8-5EB9, U+5EBD-5EBF, U+5EC1-5EC2, U+5EC6, U+5EC8, U+5ECB-5ECC, U+5ECE-5ED6, U+5ED9-5EE2, U+5EE5, U+5EE8-5EE9, U+5EEB-5EEC, U+5EF0-5EF1, U+5EF3-5EF4, U+5EF8-5EF9, U+5EFC-5F00, U+5F02-5F03, U+5F06-5F09, U+5F0B-5F0E, U+5F11, U+5F16-5F17, U+5F19, U+5F1B-5F1E, U+5F21-5F24, U+5F27-5F29, U+5F2B-5F30, U+5F34, U+5F36, U+5F38, U+5F3A-5F3D, U+5F3F-5F41, U+5F44-5F45, U+5F47-5F48, U+5F4A, U+5F4C-5F4E, U+5F50-5F51, U+5F54, U+5F56-5F58, U+5F5B-5F5D, U+5F60, U+5F63-5F65, U+5F67, U+5F6A;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-42-400-normal.54c0f3b4.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+5BBC, U+5BC0-5BC1, U+5BC3, U+5BC7, U+5BC9, U+5BCD-5BD0, U+5BD3-5BD4, U+5BD6-5BDA, U+5BDE, U+5BE0-5BE2, U+5BE4-5BE6, U+5BE8, U+5BEB-5BEC, U+5BEF-5BF1, U+5BF3-5BF6, U+5BFD, U+5C03, U+5C05, U+5C07-5C09, U+5C0C-5C0D, U+5C12-5C14, U+5C17, U+5C19, U+5C1E-5C20, U+5C22-5C24, U+5C26, U+5C28-5C2E, U+5C30, U+5C32, U+5C35-5C36, U+5C38-5C39, U+5C46, U+5C4D-5C50, U+5C53, U+5C59-5C5C, U+5C5F-5C63, U+5C67-5C69, U+5C6C-5C70, U+5C74-5C76, U+5C79-5C7D, U+5C87-5C88, U+5C8A, U+5C8C, U+5C8F, U+5C91-5C92, U+5C94, U+5C9D, U+5C9F-5CA0, U+5CA2-5CA3, U+5CA6-5CA8, U+5CAA-5CAB, U+5CAD, U+5CB1-5CB2, U+5CB4-5CB7, U+5CBA-5CBC, U+5CBE, U+5CC5, U+5CC7, U+5CC9, U+5CCB, U+5CD0, U+5CD2, U+5CD7, U+5CD9, U+5CDD, U+5CE6, U+5CE8-5CEA, U+5CED-5CEE, U+5CF1-5CF2, U+5CF4-5CF5, U+5CFA-5CFB, U+5CFD, U+5D01, U+5D06, U+5D0B, U+5D0D, U+5D10-5D12, U+5D14-5D15, U+5D17-5D1B, U+5D1D, U+5D1F-5D20, U+5D22-5D24, U+5D26-5D27, U+5D2B, U+5D31, U+5D34, U+5D39, U+5D3D, U+5D3F, U+5D42-5D43, U+5D46-5D48, U+5D4A-5D4B, U+5D4E, U+5D51-5D53, U+5D55, U+5D59, U+5D5C, U+5D5F-5D62, U+5D64, U+5D69-5D6A, U+5D6C-5D6D, U+5D6F-5D70, U+5D73, U+5D76, U+5D79-5D7A, U+5D7E-5D7F, U+5D81-5D84, U+5D87-5D88, U+5D8A, U+5D8C, U+5D90, U+5D92-5D95, U+5D97, U+5D99;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-43-400-normal.a5c57399.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+598B-598E, U+5992, U+5995, U+5997, U+599B, U+599D, U+599F, U+59A3-59A4, U+59A7, U+59AD-59B0, U+59B2-59B3, U+59B7, U+59BA, U+59BC, U+59BE, U+59C1, U+59C3-59C4, U+59C6, U+59C8, U+59CA, U+59CD, U+59D2, U+59D9-59DA, U+59DD-59DF, U+59E3-59E5, U+59E7-59E8, U+59EC, U+59EE-59EF, U+59F1-59F2, U+59F4, U+59F6-59F8, U+5A00, U+5A03-5A04, U+5A09, U+5A0C-5A0E, U+5A11-5A13, U+5A17, U+5A1A-5A1C, U+5A1E-5A1F, U+5A23-5A25, U+5A27-5A28, U+5A2A, U+5A2D, U+5A30, U+5A35-5A36, U+5A40-5A41, U+5A44-5A45, U+5A47-5A49, U+5A4C, U+5A50, U+5A55, U+5A5E, U+5A62-5A63, U+5A65, U+5A67, U+5A6A, U+5A6C-5A6D, U+5A77, U+5A7A-5A7B, U+5A7E, U+5A84, U+5A8B, U+5A90, U+5A93, U+5A96, U+5A99, U+5A9C, U+5A9E-5AA0, U+5AA2, U+5AA7, U+5AAC, U+5AB1-5AB3, U+5AB5, U+5AB8, U+5ABA-5ABF, U+5AC2, U+5AC4, U+5AC6, U+5AC8, U+5ACB, U+5ACF-5AD0, U+5AD6-5AD7, U+5ADA, U+5ADC, U+5AE0-5AE1, U+5AE3, U+5AE5-5AE6, U+5AE9-5AEA, U+5AEE, U+5AF0, U+5AF5-5AF6, U+5AFA-5AFB, U+5AFD, U+5B00-5B01, U+5B08, U+5B0B, U+5B16-5B17, U+5B19, U+5B1B, U+5B1D, U+5B21, U+5B25, U+5B2A, U+5B2C-5B2D, U+5B30, U+5B32, U+5B34, U+5B36, U+5B38, U+5B3E, U+5B40-5B41, U+5B43, U+5B45, U+5B4B-5B4C, U+5B51-5B52, U+5B56, U+5B5A-5B5C, U+5B5E-5B5F, U+5B65, U+5B68-5B69, U+5B6E-5B71, U+5B73, U+5B75-5B76, U+5B7A, U+5B7C-5B84, U+5B86, U+5B8A-5B8B, U+5B8D-5B8E, U+5B90-5B91, U+5B93-5B94, U+5B96, U+5BA5-5BA6, U+5BA8-5BA9, U+5BAC-5BAD, U+5BAF, U+5BB1-5BB2, U+5BB7-5BB8, U+5BBA;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-44-400-normal.4d6348d3.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+57B3, U+57B8, U+57BD, U+57C0, U+57C3, U+57C6-57C8, U+57CC, U+57CF, U+57D2-57D7, U+57DC-57DE, U+57E0-57E1, U+57E3-57E4, U+57E6-57E7, U+57E9, U+57ED, U+57F0, U+57F4-57F6, U+57F8, U+57FB, U+57FD-57FF, U+5803-5804, U+5808-580D, U+5819, U+581B, U+581D-5821, U+5826-5827, U+582D, U+582F-5830, U+5832, U+5835, U+5839, U+583D, U+583F-5840, U+5849, U+584B-584D, U+584F-5852, U+5855, U+5858-5859, U+585F, U+5861-5862, U+5864, U+5867-5868, U+586D, U+5870, U+5872, U+5878-5879, U+587C, U+587F-5881, U+5885, U+5887-588D, U+588F-5890, U+5894, U+5896, U+5898, U+589D-589E, U+58A0-58A2, U+58A6, U+58A9-58AB, U+58AE, U+58B1-58B3, U+58B8-58BC, U+58BE, U+58C2-58C5, U+58C8, U+58CD-58CE, U+58D0-58DA, U+58DC-58E2, U+58E4-58E5, U+58E9, U+58EC, U+58EF, U+58F3-58F4, U+58F7, U+58F9, U+58FB-58FD, U+5902, U+5905-5906, U+590A-590D, U+5910, U+5912-5914, U+5918-5919, U+591B, U+591D, U+591F, U+5921, U+5923-5925, U+5928, U+592C-592D, U+592F-5930, U+5932-5933, U+5935-5936, U+5938-5939, U+593D-593F, U+5943, U+5946, U+594E, U+5950, U+5952-5953, U+5955, U+5957-595B, U+595D-5961, U+5963, U+5967, U+5969, U+596B-596D, U+596F, U+5972, U+5975-5976, U+5978-5979, U+597B-597C, U+5981;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-45-400-normal.b3b92c8a.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+5616-5617, U+5619, U+561B, U+5620, U+5628, U+562C, U+562F-5639, U+563B-563D, U+563F-5641, U+5643-5644, U+5646-5647, U+5649, U+564B, U+564D-5650, U+5653-5654, U+565E, U+5660-5664, U+5666, U+5669-566D, U+566F, U+5671-5672, U+5675-5676, U+5678, U+567A, U+5680, U+5684-5688, U+568A-568C, U+568F, U+5694-5695, U+5699-569A, U+569D-56A0, U+56A5-56A9, U+56AB-56AE, U+56B1-56B4, U+56B6-56B7, U+56BC, U+56BE, U+56C0, U+56C2-56C3, U+56C5, U+56C8-56D1, U+56D3, U+56D7-56D9, U+56DC-56DD, U+56DF, U+56E1, U+56E4-56E8, U+56EB, U+56ED-56EE, U+56F1, U+56F6-56F7, U+56F9, U+56FF-5704, U+5707-570A, U+570C-570D, U+5711, U+5713, U+5715-5716, U+5718, U+571A-571D, U+5720-5726, U+5729-572A, U+572C, U+572E-572F, U+5733-5734, U+5737-5738, U+573B, U+573D-573F, U+5745-5746, U+574C-574F, U+5751-5752, U+5759, U+575F, U+5761-5762, U+5764-5765, U+5767-5769, U+576B, U+576D-5771, U+5773-5775, U+5777, U+5779-577C, U+577E-577F, U+5781, U+5783, U+5788-5789, U+578C, U+5793-5795, U+5797, U+5799-579A, U+579C-57A1, U+57A4, U+57A7-57AA, U+57AC, U+57AE, U+57B0;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-46-400-normal.a538b7b4.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+543F-5440, U+5443-5444, U+5447, U+544C-544F, U+5455, U+545E, U+5462, U+5464, U+5466-5467, U+5469, U+546B-546E, U+5470-5471, U+5474-5477, U+547B, U+547F-5481, U+5483-5486, U+5488-548B, U+548D-5492, U+5495-5496, U+549C, U+549F-54A2, U+54A4, U+54A6-54AF, U+54B1, U+54B7-54BC, U+54BE-54BF, U+54C2-54C4, U+54C6-54C8, U+54CA, U+54CD-54CE, U+54D8, U+54E0, U+54E2, U+54E5-54E6, U+54E8-54EA, U+54EC-54EF, U+54F1, U+54F3, U+54F6, U+54FC-5501, U+5505, U+5508-5509, U+550C-550F, U+5514-5516, U+5527, U+552A-552B, U+552E, U+5532-5533, U+5535-5536, U+5538-5539, U+553B-553D, U+5540-5541, U+5544-5545, U+5547, U+5549-554A, U+554C-554D, U+5550-5551, U+5556-5558, U+555A-555E, U+5560-5561, U+5563-5564, U+5566, U+557B-5583, U+5586-5588, U+558A, U+558E-558F, U+5591-5594, U+5597, U+5599, U+559E-559F, U+55A3-55A4, U+55A8-55A9, U+55AC-55AE, U+55B2, U+55BF, U+55C1, U+55C3-55C4, U+55C6-55C7, U+55C9, U+55CB-55CC, U+55CE, U+55D1-55D4, U+55D7-55D8, U+55DA-55DB, U+55DD-55DF, U+55E2, U+55E4, U+55E9, U+55EC, U+55EE, U+55F1, U+55F6-55F9, U+55FD-55FF, U+5605, U+5607-5608, U+560A, U+560D-5612;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-47-400-normal.69d2e811.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+528D, U+5291-5298, U+529A, U+529C, U+52A4-52A7, U+52AB-52AD, U+52AF-52B0, U+52B5-52B8, U+52BA-52BE, U+52C0-52C1, U+52C4-52C6, U+52C8, U+52CA, U+52CC-52CD, U+52CF-52D2, U+52D4, U+52D6-52D7, U+52DB-52DC, U+52DE, U+52E0-52E1, U+52E3, U+52E5-52E6, U+52E8-52EA, U+52EC, U+52F0-52F1, U+52F3-52FB, U+5300-5301, U+5303, U+5306-5308, U+530A-530D, U+530F-5311, U+5313, U+5315, U+5318-531F, U+5321, U+5323-5325, U+5327-532D, U+532F-5333, U+5335, U+5338, U+533C-533E, U+5340, U+5342, U+5345-5346, U+5349, U+534B-534C, U+5359, U+535B, U+535E, U+5361, U+5363-5367, U+5369, U+536C-536E, U+5372, U+5377, U+5379-537B, U+537D-537F, U+5382-5383, U+5387-5389, U+538E, U+5393-5394, U+5396, U+5398-5399, U+539D, U+53A0-53A1, U+53A4-53A6, U+53A9-53AB, U+53AD-53B0, U+53B2, U+53B4-53B8, U+53BA, U+53BD, U+53C0-53C1, U+53C3-53C5, U+53CF, U+53D2-53D3, U+53D5, U+53DA-53DB, U+53DD-53E0, U+53E2, U+53E6-53E8, U+53ED-53EE, U+53F4-53F5, U+53FA, U+5401-5403, U+540B, U+540F, U+5412-5413, U+541A, U+541D-541E, U+5421, U+5424, U+5427-542A, U+542C-542F, U+5431, U+5433-5436, U+543C-543D;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-48-400-normal.8ee5951e.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+50DD-50DF, U+50E1-50E6, U+50E8-50E9, U+50ED-50F6, U+50F9-50FB, U+50FE, U+5101-5103, U+5106-5109, U+510B-510E, U+5110, U+5112, U+5114-511E, U+5121, U+5123, U+5127-5128, U+512C-512D, U+512F, U+5131, U+5133-5135, U+5137-513C, U+513F-5142, U+5147, U+514A, U+514C, U+514F, U+5152-5155, U+5157-5158, U+515F-5160, U+5162, U+5164, U+5166-5167, U+5169-516A, U+516E, U+5173-5174, U+5179, U+517B, U+517E, U+5180, U+5182-5184, U+5189, U+518B-518C, U+518E-5191, U+5193, U+5195-5196, U+5198, U+519D, U+51A1-51A4, U+51A6, U+51A9-51AB, U+51AD, U+51B0-51B3, U+51B5, U+51B8, U+51BA, U+51BC-51BF, U+51C2-51C3, U+51C5, U+51C8-51CB, U+51CF, U+51D1-51D6, U+51D8, U+51DE-51E0, U+51E2, U+51E5, U+51E7, U+51E9, U+51EC-51EE, U+51F2-51F5, U+51F7, U+51FE, U+5201-5202, U+5204-5205, U+520B, U+520E, U+5212-5216, U+5218, U+5222, U+5226-5228, U+522A-522B, U+522E, U+5231-5233, U+5235, U+523C, U+5244-5245, U+5249, U+524B-524C, U+524F, U+5254-5255, U+5257-5258, U+525A, U+525C-5261, U+5266, U+5269, U+526C, U+526E, U+5271, U+5273-5274, U+5277-5279, U+527D, U+527F-5280, U+5282-5285, U+5288-528A, U+528C;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-49-400-normal.f4e0f724.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+4F57-4F58, U+4F5A-4F5B, U+4F5D-4F5F, U+4F63-4F64, U+4F69-4F6A, U+4F6C, U+4F6E-4F71, U+4F76-4F7E, U+4F81-4F85, U+4F88-4F8A, U+4F8C, U+4F8E-4F90, U+4F92-4F94, U+4F96-4F9A, U+4F9E-4FA0, U+4FAB, U+4FAD, U+4FAF, U+4FB2, U+4FB7, U+4FB9, U+4FBB-4FBE, U+4FC0-4FC1, U+4FC4-4FC6, U+4FC8-4FC9, U+4FCB-4FD4, U+4FD8, U+4FDA-4FDC, U+4FDF-4FE0, U+4FE2, U+4FE4-4FE6, U+4FEF-4FF2, U+4FF6, U+4FFC-5002, U+5004-5007, U+500A, U+500C, U+500E-5011, U+5013-5014, U+5016-5018, U+501A-501E, U+5021-5023, U+5025-502A, U+502C-502E, U+5030, U+5032-5033, U+5035, U+5039, U+503B, U+5040-5043, U+5045-5048, U+504A, U+504C, U+504E, U+5050-5053, U+5055-5057, U+5059-505A, U+505F-5060, U+5062-5063, U+5066-5067, U+506A, U+506C-506D, U+5070-5072, U+5077-5078, U+5080-5081, U+5083-5086, U+5088, U+508A, U+508E-5090, U+5092-5096, U+509A-509C, U+509E-50A3, U+50AA, U+50AD, U+50AF-50B4, U+50B9-50BB, U+50BD, U+50C0, U+50C2-50C4, U+50C7, U+50C9-50CA, U+50CC, U+50CE, U+50D0-50D1, U+50D3-50D4, U+50D6, U+50D8-50D9, U+50DC;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-50-400-normal.1f52999f.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+4093, U+4103, U+4105, U+4148, U+414F, U+4163, U+41B4, U+41BF, U+41E6, U+41EE, U+41F3, U+4207, U+420E, U+4264, U+4293, U+42C6, U+42D6, U+42DD, U+4302, U+432B, U+4343, U+43EE, U+43F0, U+4408, U+440C, U+4417, U+441C, U+4422, U+4453, U+445B, U+4476, U+447A, U+4491, U+44B3, U+44BE, U+44D4, U+4508, U+450D, U+4525, U+4543, U+457A, U+459D, U+45B8, U+45BE, U+45E5, U+45EA, U+460F-4610, U+4641, U+4665, U+46A1, U+46AE-46AF, U+470C, U+471F, U+4764, U+47E6, U+47FD, U+4816, U+481E, U+4844, U+484E, U+48B5, U+49B0, U+49E7, U+49FA, U+4A04, U+4A29, U+4ABC, U+4B38, U+4B3B, U+4B7E, U+4BC2, U+4BCA, U+4BD2, U+4BE8, U+4C17, U+4C20, U+4C38, U+4CC4, U+4CD1, U+4CE1, U+4D07, U+4D77, U+4E02, U+4E04-4E05, U+4E0C, U+4E0F-4E12, U+4E15, U+4E17, U+4E19, U+4E1E-4E1F, U+4E23-4E24, U+4E28-4E2C, U+4E2E-4E31, U+4E35-4E37, U+4E3F-4E42, U+4E44, U+4E47-4E48, U+4E4D-4E4E, U+4E51, U+4E55-4E56, U+4E58, U+4E5A-4E5C, U+4E62-4E63, U+4E68-4E69, U+4E74-4E75, U+4E79, U+4E7F, U+4E82, U+4E85, U+4E8A, U+4E8D-4E8E, U+4E96-4E99, U+4E9D-4EA0, U+4EA2, U+4EA5-4EA6, U+4EA8, U+4EAF-4EB0, U+4EB3, U+4EB6, U+4EB9, U+4EBB-4EBC, U+4EC2-4EC4, U+4EC6-4EC8, U+4ECD, U+4ED0, U+4ED7, U+4EDA-4EDB, U+4EDD-4EE2, U+4EE8, U+4EEB, U+4EED, U+4EEF, U+4EF1, U+4EF3, U+4EF5, U+4EF7, U+4EFC-4F00, U+4F02-4F03, U+4F08-4F09, U+4F0B-4F0D, U+4F12, U+4F15-4F17, U+4F19, U+4F1C, U+4F2B, U+4F2E, U+4F30-4F31, U+4F33, U+4F35-4F37, U+4F39, U+4F3B, U+4F3E, U+4F40, U+4F42-4F43, U+4F48-4F49, U+4F4B-4F4C, U+4F52, U+4F54, U+4F56;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-51-400-normal.77426b1a.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+3395-339B, U+339E-33A0, U+33A2-33FF, U+3402, U+3405-3406, U+3427, U+342C, U+342E, U+3468, U+346A, U+3488, U+3492, U+34B5, U+34BC, U+34C1, U+34C7, U+34DB, U+351F, U+353E, U+355D-355E, U+3563, U+356E, U+35A6, U+35A8, U+35C5, U+35DA, U+35DE, U+35F4, U+3605, U+3614, U+364A, U+3691, U+3696, U+3699, U+36CF, U+3761-3762, U+376B-376C, U+3775, U+378D, U+37C1, U+37E2, U+37E8, U+37F4, U+37FD, U+3800, U+382F, U+3836, U+3840, U+385C, U+3861, U+38A1, U+38AD, U+38FA, U+3917, U+391A, U+396F, U+39A4, U+39B8, U+3A5C, U+3A6E, U+3A73, U+3A85, U+3AC4, U+3ACB, U+3AD6-3AD7, U+3AEA, U+3AF3, U+3B0E, U+3B1A, U+3B1C, U+3B22, U+3B35, U+3B6D, U+3B77, U+3B87-3B88, U+3B8D, U+3BA4, U+3BB6, U+3BC3, U+3BCD, U+3BF0, U+3BF3, U+3C0F, U+3C26, U+3CC3, U+3CD2, U+3D11, U+3D1E, U+3D31, U+3D4E, U+3D64, U+3D9A, U+3DC0, U+3DCC, U+3DD4, U+3E05, U+3E3F-3E40, U+3E60, U+3E66, U+3E68, U+3E83, U+3E8A, U+3E94, U+3EDA, U+3F57, U+3F72, U+3F75, U+3F77, U+3FAE, U+3FB1, U+3FC9, U+3FD7, U+3FDC, U+4039, U+4058;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-53-400-normal.17979487.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+31C8-31E3, U+31F0-321E, U+3220-3230, U+3232-32B4;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-54-400-normal.db8ec476.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+3028-303F, U+3094-3096, U+309F-30A0, U+30EE, U+30F7-30FA, U+30FF, U+3105-312F, U+3131-3163, U+3165-318E, U+3190-31BB, U+31C0-31C7;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-56-400-normal.0d932d1c.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+25E4-25E6, U+2601-2603, U+2609, U+260E-260F, U+2616-2617, U+261C-261F, U+262F, U+2641, U+2660, U+2662-2664, U+2666-2668, U+266D-266E, U+2672-267D, U+26BD-26BE, U+2702, U+271A, U+273D, U+2740, U+2756, U+2776-2793, U+27A1, U+2934-2935, U+29BF, U+29FA-29FB, U+2B05-2B07, U+2B1A, U+2B95, U+2E3A-2E3B, U+2E80-2E99, U+2E9B-2EF3, U+2F00-2F13;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-57-400-normal.9d4e321f.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+24D1-24FF, U+2503-2513, U+2515-2516, U+2518-251B, U+251D-2522, U+2524-259F, U+25A2-25AB, U+25B1, U+25B7, U+25C0-25C1, U+25C9-25CA, U+25CC, U+25D0-25D3, U+25E2-25E3;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-58-400-normal.f29f0560.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+2105, U+2109-210A, U+210F, U+2116, U+2121, U+2126-2127, U+212B, U+212E, U+2135, U+213B, U+2194-2199, U+21B8-21B9, U+21C4-21C6, U+21CB-21CC, U+21D0, U+21E6-21E9, U+21F5, U+2202-2203, U+2205-2206, U+2208-220B, U+220F, U+2211, U+2213, U+2215, U+221A, U+221D, U+2220, U+2223, U+2225-2226, U+2228, U+222A-222E, U+2234-2237, U+223D, U+2243, U+2245, U+2248, U+224C, U+2260, U+2262, U+2264-2265, U+226E-226F, U+2272-2273, U+2276-2277, U+2283-2287, U+228A-228B, U+2295-2299, U+22A0, U+22A5, U+22BF, U+22DA-22DB, U+22EF, U+2305-2307, U+2318, U+2329-232A, U+23B0-23B1, U+23BE-23CC, U+23CE, U+23DA-23DB, U+2423, U+2469-24D0;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-59-400-normal.5f45a821.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+A1-A4, U+A6-A7, U+AA, U+AC-AD, U+B5-B6, U+B8-BA, U+BC-C8, U+CA-CC, U+CE-D5, U+D9-DB, U+DD-DF, U+E6, U+EE, U+F0, U+F5, U+F7, U+F9, U+FB, U+FE-102, U+110-113, U+11A-11B, U+128-12B, U+143-144, U+147-148, U+14C, U+14E-14F, U+152-153, U+168-16D, U+192, U+1A0-1A1, U+1AF, U+1CD-1DC, U+1F8-1F9, U+251, U+261, U+2BB, U+2C7, U+2C9, U+2EA-2EB, U+304, U+307, U+30C, U+1E3E-1E3F, U+1EA0-1EBE, U+1EC0-1EC6, U+1EC8-1EF9, U+2011-2012, U+2016, U+2018-201A, U+201E, U+2021, U+2030, U+2033, U+2035, U+2042, U+2047, U+2051, U+2074, U+20A9, U+20AB-20AC, U+20DD-20DE, U+2100;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-60-400-normal.5f64b7c9.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+2227, U+26A0, U+2713, U+301F, U+4FF8, U+5239, U+526A, U+54FA, U+5740, U+5937, U+5993, U+59FB, U+5A3C, U+5C41, U+6028, U+626E, U+646F, U+647A, U+64B0, U+64E2, U+65A7, U+66FE, U+6727, U+6955, U+6BEF, U+6F23, U+724C, U+767C, U+7A83, U+7AC4, U+7B67, U+8000, U+8471, U+8513, U+8599, U+86DB, U+8718, U+87F2, U+88F3, U+8AD2, U+8E2A, U+8FA3, U+95A5, U+9798, U+9910, U+9957, U+9BAB, U+9C3B, U+9DAF, U+FF95;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-61-400-normal.b4563c48.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+A8, U+2032, U+2261, U+2282, U+3090, U+30F1, U+339C, U+535C, U+53D9, U+56A2, U+56C1, U+5806, U+589F, U+59D0, U+5A7F, U+60E0, U+639F, U+65AF, U+68FA, U+69AE, U+6D1B, U+6EF2, U+71FB, U+725D, U+7262, U+75BC, U+7768, U+7940, U+79BF, U+7BED, U+7D68, U+7DFB, U+814B, U+8207, U+83E9, U+8494, U+8526, U+8568, U+85EA, U+86D9, U+87BA, U+8861, U+887F, U+8FE6, U+9059, U+9061, U+916A, U+976D, U+97AD, U+9ECE;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-62-400-normal.1a31d6f6.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+2D9, U+21D4, U+301D, U+515C, U+52FE, U+5420, U+5750, U+5766, U+5954, U+5B95, U+5F8A, U+5F98, U+620C, U+621F, U+641C, U+66D9, U+676D, U+6775, U+67F5, U+694A, U+6A02, U+6A3A, U+6A80, U+6C23, U+6C72, U+6DCB, U+6FAA, U+707C, U+71C8, U+7422, U+74E2, U+7791, U+7825, U+7A14, U+7A1C, U+7C95, U+7FC1, U+82A5, U+82DB, U+8304, U+853D, U+8CD3, U+8DE8, U+8F0C, U+8F3F, U+9091, U+91C7, U+929A, U+98AF, U+9913;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-63-400-normal.35485218.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+2CA-2CB, U+2229, U+2468, U+2669, U+266F, U+273F, U+4EC0, U+4F60, U+4FB6, U+5347, U+540E, U+543B, U+5B0C, U+5D4C, U+5F14, U+5F9E, U+6155, U+62D0, U+6602, U+6666, U+66F3, U+67A2, U+67CA, U+69CC, U+6D29, U+6D9B, U+6E3E, U+6F81, U+7109, U+73C0, U+73C2, U+7425, U+7435-7436, U+7525, U+7554, U+785D, U+786B, U+7AE3, U+7B94, U+7D18, U+81BF, U+8511, U+8549, U+9075, U+9640, U+98E2, U+9E9F, U+FF96;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-64-400-normal.8f7af624.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+2467, U+4ECE, U+4ED4, U+4F91, U+4FAE, U+534D, U+53C9, U+54B3, U+586B, U+5944, U+5B78, U+5DF7, U+5F77, U+6101, U+6167-6168, U+61A4, U+62D9, U+698A, U+699B, U+6A59, U+6CC4, U+6E07, U+7099, U+75D2, U+77AD, U+7953, U+7984, U+7A92, U+7BAA, U+7DBB, U+817F, U+82AD, U+85E9, U+868A, U+8CAA, U+8F44, U+9017, U+907C, U+908A, U+92F3, U+936E, U+9435, U+978D, U+9838, U+9A28, U+9B41, U+9BA8, U+9C57, U+9EB9;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-65-400-normal.0c4fa871.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+B1, U+309B, U+4E5E, U+51F1, U+5506, U+55C5, U+58CC, U+59D1, U+5C51, U+5EF7, U+6284, U+62D7, U+6689, U+673D, U+6A2B, U+6A8E, U+6A9C, U+6D63, U+6DD1, U+70B8, U+7235, U+72DB, U+72F8, U+7560, U+7C9B, U+7CE7, U+7E1E, U+80AF, U+82EB, U+8463, U+8499, U+85DD, U+86EE, U+8A60, U+8A6E, U+8C79, U+8E87, U+8E8A, U+8F5F, U+9010, U+918D, U+9190, U+965B, U+97FB, U+9AB8, U+9BAD, U+9D3B, U+9D5C, U+9DFA, U+9E93;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-66-400-normal.92e36ee0.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+2020, U+3003, U+3231, U+4E9B, U+4F3D, U+4F47, U+51B6, U+51DC, U+53E1, U+5BC5, U+602F, U+60BC, U+61C9, U+633D, U+637B, U+6492, U+65FA, U+660F, U+66F0, U+6703, U+681E, U+6876, U+6893, U+6912, U+698E, U+6C7D, U+714C, U+7169, U+71D5, U+725F, U+72D7, U+745B, U+74DC, U+75E2, U+7891, U+7897, U+7DCB, U+810A, U+8218, U+8339, U+840E, U+852D, U+8823, U+8A0A, U+9089, U+919C, U+971C, U+9AD9, U+FF4A, U+FF5A;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-67-400-normal.adce47ea.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+2466, U+2600, U+4EAB, U+4FE3, U+4FF5, U+51A5, U+51F0, U+536F, U+53D4, U+53F1, U+54A5, U+559D, U+55E3, U+58FA, U+5962, U+59EA, U+5C16, U+5CEF, U+5D16, U+5F10, U+5FD6, U+6190, U+6216, U+634F, U+63BB, U+66D6, U+6756, U+6BC5, U+6E26, U+727D, U+731F, U+76F2, U+7729, U+7A7F, U+7AFF, U+7C9F, U+818F, U+8236, U+82B9, U+8338, U+85AA, U+88B4, U+8B33, U+904D, U+93A7, U+96CC, U+96EB, U+9AED, U+9B8E, U+FA11;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-68-400-normal.d8da074c.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+251C, U+2523, U+4E14, U+545F, U+54BD, U+553E, U+55DC, U+56DA, U+589C, U+5B55, U+5BB5, U+5CE1, U+5DF4, U+5EB6, U+5EC9, U+6191, U+62F7, U+6357, U+64A5, U+6591, U+65BC, U+6897, U+6E1A, U+7063, U+711A, U+721B, U+722C, U+75B9, U+75D5, U+75FA, U+7766, U+7AAE, U+7B48, U+7B8B, U+7D21, U+7E55, U+7F75, U+842C, U+8910, U+8A63, U+8B39, U+8B5A, U+8CDC, U+8D74, U+907D, U+91E7, U+9306, U+96BC, U+98F4, U+9AC4;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-69-400-normal.7f3c4bc9.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+2003, U+2312, U+266C, U+4F86, U+51EA, U+5243, U+5256, U+541F, U+5841, U+59DC, U+5DF3, U+601C, U+60E7, U+632B, U+638C, U+64AD, U+6881, U+697C, U+69CD, U+6C50, U+6D2A, U+6FC1, U+7027, U+7058, U+70F9, U+714E, U+7345, U+751A, U+760D, U+764C, U+77DB, U+7D79, U+7E8F, U+80CE, U+814E, U+81FC, U+8247, U+8278, U+85A9, U+8A03, U+90ED, U+9784, U+9801, U+984E, U+99B3, U+9BC9, U+9BDB, U+9BE8, U+9E78, U+FF6B;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-70-400-normal.ec9ac62f.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+266B, U+3006, U+5176, U+5197, U+51A8, U+51C6, U+52F2, U+5614, U+5875, U+5A2F, U+5B54, U+5CE0, U+5DBA, U+5DEB, U+5E63, U+5F59, U+5FCC, U+6068, U+6367, U+68B6, U+6A0B, U+6B64, U+6E15, U+6EBA, U+7272, U+72A0, U+7947, U+7985, U+79E6, U+79E9, U+7A3D, U+7A9F, U+7AAF, U+7B95, U+7F60, U+7F9E, U+7FE0, U+8098, U+80BA, U+8106, U+82D4, U+831C, U+87F9, U+8A1F, U+8ACF, U+90C1, U+920D, U+9756, U+FE43, U+FF94;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-71-400-normal.fd8496aa.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+AF, U+2465, U+2517, U+33A1, U+4F10, U+50C5, U+51B4, U+5384, U+5606, U+5BB0, U+5CAC, U+5EE3, U+618E, U+61F2, U+62C9, U+66AB, U+66F9, U+6816, U+6960, U+6B3E, U+6F20, U+7078, U+72D0, U+73ED, U+7AD9, U+7B1B, U+7BE4, U+7D62, U+7F51, U+80B4, U+80F4, U+8154, U+85FB, U+865C, U+8702, U+895F, U+8AED, U+8B90, U+8CED, U+8FBF, U+91D8, U+9418, U+9583, U+9591, U+9813, U+982C, U+9BD6, U+FF46, U+FF7F, U+FF88;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-72-400-normal.31f2c2d9.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+4E91, U+508D, U+50E7, U+514E, U+51F6, U+5446, U+5504, U+584A, U+59A8, U+59D3, U+5A46, U+5AC9, U+6020, U+60A6, U+6148, U+621A, U+6234, U+64C1, U+6523, U+675C, U+67D1, U+6953, U+6CCC, U+6DF5, U+6E13, U+6F06, U+723A, U+7325, U+74E6, U+758E, U+75AB, U+75D9, U+7A40, U+8096, U+82FA, U+8587, U+8594, U+8A6B, U+8AB9, U+8B17, U+8B83, U+937C, U+963B, U+9673, U+96DB, U+9CE9, U+9F4B, U+FF67, U+FF82, U+FF93;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-73-400-normal.d855f0cd.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+221E, U+2514, U+51F9, U+5270, U+5449, U+5824, U+59A5, U+5A29, U+5D07, U+5E16, U+60E3, U+614C, U+6276, U+643E, U+64AB, U+6562, U+6681, U+670B, U+6734, U+67AF, U+6A3D, U+6B05, U+6DC0, U+6E4A, U+7259, U+732A, U+7409, U+78A7, U+7A6B, U+8015, U+809B, U+817A, U+830E, U+837B, U+85AB, U+8A23, U+8A93, U+8B00, U+8B19, U+8B21, U+8CBF, U+8FB0, U+901D, U+91B8, U+9320, U+932C, U+9688, U+96F6, U+9DF2, U+FF6A;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-74-400-normal.124ff4a6.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+2002, U+2025, U+4F8D, U+51E1, U+51F8, U+5507, U+5598, U+58F1, U+5983, U+59AC, U+5C3C, U+5DE7, U+5E7D, U+5ECA, U+5F61, U+606D, U+60F9, U+636E, U+64EC, U+67DA, U+67FF, U+6813, U+68F2, U+693F, U+6B6A, U+6BBB, U+6EF4, U+7092, U+717D, U+7261, U+73C8, U+7432, U+7483, U+76FE, U+7709, U+78D0, U+81A3, U+81B3, U+82AF, U+8305, U+8309, U+8870, U+88FE, U+8CD1, U+8D66, U+906E, U+971E, U+9812, U+FF79, U+FF90;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-75-400-normal.7ee89516.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+2464, U+2501, U+2640, U+2642, U+339D, U+4F0E, U+5091, U+50B5, U+5132, U+51CC, U+558B, U+55AA, U+585E, U+5BEE, U+5DFE, U+60B6, U+62B9, U+6349, U+6566, U+6590, U+6842, U+689D, U+6A58, U+6C70, U+6FF1, U+7815, U+7881, U+7AAA, U+7BC7, U+7DEF, U+7FA8, U+8017, U+8036, U+8061, U+821F, U+8429, U+8CE0, U+8E74, U+9019, U+90CA, U+9162, U+932F, U+93AE, U+9644, U+990C, U+9CF3, U+FF56, U+FF6E, U+FF7E, U+FF85;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-76-400-normal.0a220089.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+2266-2267, U+4F2F, U+5208, U+5451, U+546A, U+5589, U+576A, U+5815, U+5A9A, U+5B9B, U+5C3A, U+5EFB, U+5FAA, U+6109, U+6643, U+6652, U+695A, U+69FD, U+6B86, U+6BB4, U+6DAF, U+7089, U+70CF, U+7A00, U+7A4F, U+7B39, U+7D33, U+80E1, U+828B, U+82A6, U+86CD, U+8C8C, U+8CCA, U+8DF3, U+9077, U+9175, U+91DC, U+925B, U+9262, U+9271, U+92ED, U+9855, U+9905, U+9D28, U+FF3F, U+FF58, U+FF68, U+FF6D, U+FF9C;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-77-400-normal.3e767955.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+2207, U+25EF, U+309C, U+4E4F, U+5146, U+51DD, U+5351, U+540A, U+5629, U+5EB5, U+5F04, U+5F13, U+60DC, U+6212, U+63B4, U+642C, U+6627, U+66A6, U+66C7, U+66FD, U+674E, U+6B96, U+6C4E, U+6DF3, U+6E67, U+6F84, U+72FC, U+733F, U+7C97, U+7DB1, U+7E4D, U+816B, U+82D1, U+84CB, U+854E, U+8607, U+86C7, U+871C, U+8776, U+8A89, U+8FC4, U+91A4, U+9285, U+9685, U+9903, U+9B31, U+9F13, U+FF42, U+FF74, U+FF91;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-78-400-normal.66879055.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+4E32, U+51DB, U+53A8, U+53EA, U+5609, U+5674, U+5A92, U+5E7E, U+6115, U+611A, U+62CC, U+62ED, U+63C9, U+64B9, U+64E6, U+65CB, U+6606, U+6731, U+683D, U+6AFB, U+7460, U+771E, U+78EF, U+7B26, U+7B51, U+7CDE, U+7D10, U+7D2F, U+7D46, U+80DE, U+819C, U+84B2, U+85CD, U+865A, U+8ECC, U+9022, U+90B8, U+9192, U+9675, U+96B7, U+99FF, U+FF44, U+FF55, U+FF6C, U+FF73, U+FF75, U+FF86, U+FF8D, U+FF92, U+FFE3;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-79-400-normal.8b5fcba3.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+25B3, U+30F5, U+4EAE, U+4F46, U+4F51, U+5203, U+52FF, U+55A7, U+564C, U+565B, U+57F9, U+5805, U+5B64, U+5E06, U+5F70, U+5F90, U+60E8, U+6182, U+62F3, U+62FE, U+63AA, U+64A4, U+65D7, U+673A, U+6851, U+68CB, U+68DF, U+6D1E, U+6E58, U+6E9D, U+77B3, U+7832, U+7C3F, U+7DB4, U+7F70, U+80AA, U+80C6, U+8105, U+819D, U+8276, U+8679, U+8986, U+8C9D, U+8FC5, U+916C, U+9665, U+9699, U+96C0, U+9A19, U+FF8B;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-80-400-normal.431b182c.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+2463, U+25A1, U+4EF0, U+5076, U+5098, U+51FD, U+5302, U+5448, U+54C9, U+570B, U+583A, U+5893, U+58A8, U+58EE, U+5949, U+5BDB, U+5F26, U+5F81, U+6052, U+6170, U+61C7, U+631F, U+635C, U+664B, U+69FB, U+6F01, U+7070, U+722A, U+745E, U+755C, U+76C6, U+78C1, U+79E4, U+7BB8, U+7D0B, U+81A8, U+82D7, U+8B5C, U+8F14, U+8FB1, U+8FBB, U+9283, U+9298, U+9A30, U+FF03, U+FF50, U+FF59, U+FF7B, U+FF8E-FF8F;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-81-400-normal.a559055e.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+2010, U+2502, U+25B6, U+4F3A, U+514B, U+5265, U+52C3, U+5339, U+53EC, U+54C0, U+55B0, U+5854, U+5B8F, U+5CB3, U+5E84, U+60DA, U+6247, U+6249, U+628A, U+62CD, U+65AC, U+6838, U+690E, U+6CF0, U+6F02, U+6F2C, U+6F70, U+708A, U+7434, U+75BE, U+77EF, U+7C60, U+7C98, U+7D1B, U+7E2B, U+80A5, U+81E3, U+820C, U+8210, U+8475, U+862D, U+8650, U+8997, U+906D, U+91C8, U+9700, U+9727, U+9DF9, U+FF3A, U+FF9A;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-82-400-normal.4a6b71f4.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+2103, U+5049, U+52B1, U+5320, U+5553, U+572D, U+58C7, U+5B5D, U+5BC2, U+5DE3, U+5E61, U+5F80, U+61A9, U+67D0, U+67F4, U+6C88, U+6CA1, U+6CE5, U+6D78, U+6E9C, U+6F54, U+731B, U+73B2, U+74A7, U+74F6, U+75E9, U+7B20, U+7C8B, U+7F72, U+809D, U+8108, U+82B3, U+82BD, U+84B8, U+84C4, U+88C2, U+8AE6, U+8EF8, U+902E, U+9065, U+9326, U+935B, U+938C, U+9676, U+9694, U+96F7, U+9ED9, U+FF48, U+FF4C, U+FF81;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-83-400-normal.85baac88.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+2500, U+3008-3009, U+4EAD, U+4F0F, U+4FCA, U+53EB, U+543E, U+57A2, U+5CF0, U+5E8F, U+5FE0, U+61B2, U+62D8, U+6442, U+64B2, U+6589, U+659C, U+67F1, U+68C4, U+6CB8, U+6D12, U+6DE1, U+6FE1, U+70C8, U+723D, U+73E0, U+7656, U+773A, U+7948, U+7B87, U+7C92, U+7D3A, U+7E1B, U+7E4A, U+819A, U+8358, U+83C5, U+84BC, U+864E, U+8912, U+8C9E, U+8D05, U+92FC, U+9396, U+98FD, U+99D2, U+FF64, U+FF7A, U+FF83;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-84-400-normal.c4f96531.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+3014-3015, U+4E3C, U+5036, U+5075, U+533F, U+53E9, U+5531, U+5642, U+5984, U+59E6, U+5A01, U+5B6B, U+5C0B, U+5F25, U+6069, U+60A0, U+614E, U+62B5, U+62D2-62D3, U+6597, U+660C, U+674F, U+67CF, U+6841, U+6905, U+6CF3, U+6D32, U+6D69, U+6F64, U+716E, U+7761, U+7B52, U+7BE0, U+7DBF, U+7DE9, U+7F36, U+81D3, U+8302, U+8389, U+846C, U+84EE, U+8A69, U+9038, U+9D8F, U+FF47, U+FF4B, U+FF76, U+FF9B;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-85-400-normal.ae02883f.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+25C7, U+3007, U+504F, U+507D, U+51A0, U+52A3, U+5410, U+5510, U+559A, U+5782, U+582A, U+5C0A, U+5C3F, U+5C48, U+5F6B, U+6176, U+622F, U+6279, U+62BD, U+62DD, U+65ED, U+67B6, U+6817, U+6850, U+6D6A, U+6DEB, U+6EA2, U+6EDD, U+6F5C, U+72E9, U+73A9, U+7573, U+76BF, U+7950, U+7956, U+7F8A, U+7FFC, U+80A2, U+80C3, U+83CA, U+8A02, U+8A13, U+8DF5, U+9375, U+983B, U+99B4, U+FF4E, U+FF71, U+FF89, U+FF97;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-86-400-normal.1dfbf70b.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+24, U+2022, U+2212, U+221F, U+2665, U+4ECF, U+5100, U+51CD, U+52D8, U+5378, U+53F6, U+574A, U+5982, U+5996, U+5C1A, U+5E1D, U+5F84, U+609F, U+61A7, U+61F8, U+6398, U+63EE, U+6676, U+6691, U+6EB6, U+7126, U+71E5, U+7687, U+7965, U+7D17, U+80A1, U+8107, U+8266, U+85A6, U+8987, U+8CA2, U+8CAB, U+8E0A, U+9042, U+95C7, U+9810, U+9867, U+98FC, U+FF52-FF54, U+FF61, U+FF77, U+FF98-FF99;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-87-400-normal.b9a17a20.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+B0, U+226A, U+2462, U+4E39, U+4FC3, U+4FD7, U+50BE, U+50DA, U+5200, U+5211, U+54F2, U+5618, U+596A, U+5B22, U+5BB4, U+5D50, U+60A3, U+63FA, U+658E, U+65E8, U+6669, U+6795, U+679D, U+67A0, U+6B3A, U+6E09, U+757F, U+7CD6, U+7DBE, U+7FFB, U+83CC, U+83F1, U+840C, U+845B, U+8846, U+8972, U+8A34, U+8A50, U+8A87, U+8EDF, U+8FF0, U+90A6, U+9154, U+95A3, U+9663, U+9686, U+96C7, U+FF3C, U+FF7C, U+FF8A;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-88-400-normal.9391692d.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+25BD, U+4E59, U+4EC1, U+4FF3, U+515A, U+518A, U+525B, U+5375, U+552F, U+57A3, U+5B9C, U+5C3D, U+5E3D, U+5E7B, U+5F0A, U+6094, U+6458, U+654F, U+67F3, U+6B8A, U+6BD2, U+6C37, U+6CE1, U+6E56, U+6E7F, U+6ED1, U+6EDE, U+6F0F, U+70AD, U+7267, U+7363, U+786C, U+7A42, U+7DB2, U+7F85, U+8178, U+829D, U+8896, U+8C5A, U+8CB0, U+8CE2, U+8ED2, U+9047, U+9177, U+970A, U+9EA6, U+FF1B, U+FF31, U+FF39, U+FF80;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-89-400-normal.a79cc73f.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+A5, U+4E80, U+4F34, U+4F73, U+4F75, U+511F, U+5192, U+52AA, U+53C8, U+570F, U+57CB, U+596E, U+5D8B, U+5F66, U+5FD9, U+62DB, U+62F6, U+6328, U+633F, U+63A7, U+6469, U+6BBF, U+6C41, U+6C57, U+6D44, U+6DBC, U+706F, U+72C2, U+72ED, U+7551, U+75F4, U+7949, U+7E26, U+7FD4, U+8150, U+8AF8, U+8B0E, U+8B72, U+8CA7, U+934B, U+9A0E, U+9A12, U+9B42, U+FF41, U+FF43, U+FF45, U+FF49, U+FF4F, U+FF62-FF63;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-90-400-normal.c545da95.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+4E18, U+4FB5, U+5104, U+52C7, U+5353, U+5374, U+53E5, U+587E, U+594F, U+5A20, U+5DE1, U+5F18, U+5FCD, U+6291, U+62AB, U+6355, U+6392, U+63DA, U+63E1, U+656C, U+6687, U+68B0-68B1, U+68D2, U+68DA, U+6B27, U+6CBC, U+7159, U+7344, U+73CD, U+76DF, U+790E, U+7CF8, U+8102, U+88C1, U+8AA0, U+8E0F, U+9178, U+92AD, U+9670, U+96C5, U+9CF4, U+9DB4, U+FF3E, U+FF6F, U+FF72, U+FF78, U+FF7D, U+FF84, U+FF8C;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-91-400-normal.52a8dcfc.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+60, U+2200, U+226B, U+2461, U+517C, U+526F, U+5800, U+5B97, U+5BF8, U+5C01, U+5D29, U+5E4C, U+5E81, U+6065, U+61D0, U+667A, U+6696, U+6843, U+6C99, U+6D99, U+6EC5, U+6F22, U+6F6E, U+6FA4, U+6FEF, U+71C3, U+72D9, U+7384, U+78E8, U+7A1A, U+7A32, U+7A3C, U+7ADC, U+7CA7, U+7D2B, U+7DAD, U+7E4B, U+80A9, U+8170, U+81ED, U+820E, U+8A17, U+8AFE, U+90AA, U+914E, U+963F, U+99C4, U+9EBA, U+9F3B, U+FF38;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-92-400-normal.3fb175a4.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+2460, U+4E5F, U+4E7E, U+4ED9, U+501F, U+502B, U+5968, U+5974, U+5AC1, U+5B99, U+5BA3, U+5BE7, U+5BE9, U+5C64, U+5CB8, U+5EC3, U+5F1F, U+616E, U+6297, U+62E0, U+62EC, U+6368, U+642D, U+65E6, U+6717, U+676F, U+6B04, U+732E, U+7652, U+76CA, U+76D7, U+7802, U+7E70, U+7F6A, U+8133, U+81E8, U+866B, U+878D, U+88F8, U+8A5E, U+8CDB, U+8D08, U+907A, U+90E1, U+96F2, U+9F8D, U+FF35, U+FF37, U+FF40, U+FF9D;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-93-400-normal.307e6d07.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+21D2, U+25CE, U+300A-300B, U+4E89, U+4E9C, U+4EA1, U+5263, U+53CC, U+5426, U+5869, U+5947, U+598A, U+5999, U+5E55, U+5E72, U+5E79, U+5FAE, U+5FB9, U+602A, U+6163, U+624D, U+6749, U+6C5A, U+6CBF, U+6D45, U+6DFB, U+6E7E, U+708E, U+725B, U+7763, U+79C0, U+7BC4, U+7C89, U+7E01, U+7E2E, U+8010, U+8033, U+8C6A, U+8CC3, U+8F1D, U+8F9B, U+8FB2, U+907F, U+90F7, U+9707, U+9818, U+9B3C, U+FF0A, U+FF4D;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-94-400-normal.f9a2ca32.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+2015, U+2190, U+4E43, U+5019, U+5247, U+52E7, U+5438, U+54B2, U+55AB, U+57F7, U+5BD2, U+5E8A, U+5EF6, U+6016, U+60B2, U+6162, U+6319, U+6551, U+6607, U+66B4, U+675F, U+67D4, U+6B20, U+6B53, U+6CE3, U+719F, U+75B2, U+770B, U+7720, U+77AC, U+79D2, U+7AF9, U+7D05, U+7DCA, U+8056, U+80F8, U+81F3, U+8352, U+885D, U+8A70, U+8AA4, U+8CBC, U+900F, U+9084, U+91E3, U+9451, U+96C4, U+99C6, U+9AD4, U+FF70;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-95-400-normal.1612d5f2.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+2193, U+25B2, U+4E4B, U+516D, U+51C4, U+529F, U+52C9, U+5360, U+5442, U+5857, U+5915, U+59EB, U+5A9B, U+5C3B, U+6012, U+61B6, U+62B1, U+6311, U+6577, U+65E2, U+65EC, U+6613, U+6790, U+6CB9, U+7372, U+76AE, U+7D5E, U+7FCC, U+88AB, U+88D5, U+8CAF, U+8DDD, U+8ECD, U+8F38, U+8F9E, U+8FEB, U+9063, U+90F5, U+93E1, U+968A, U+968F, U+98FE, U+9EC4, U+FF1D, U+FF27, U+FF2A, U+FF36, U+FF3B, U+FF3D, U+FFE5;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-96-400-normal.c40adb5a.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+4E03, U+4F38, U+50B7, U+5264, U+5348, U+5371, U+585A, U+58CA, U+5951, U+59B9, U+59D4, U+5B98, U+5F8B, U+6388, U+64CD, U+65E7, U+6803, U+6B6F, U+6D66, U+6E0B, U+6ECB, U+6FC3, U+72AC, U+773C, U+77E2, U+7968, U+7A74, U+7DBA, U+7DD1, U+7E3E, U+808C, U+811A, U+8179, U+8239, U+8584, U+8A0E, U+8A72, U+8B66, U+8C46, U+8F29, U+90A3, U+9234, U+96F0, U+9769, U+9774, U+9AA8, U+FF26, U+FF28, U+FF9E-FF9F;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-97-400-normal.43e78cf8.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+7E, U+B4, U+25C6, U+2661, U+4E92, U+4EEE, U+4FFA, U+5144, U+5237, U+5287, U+52B4, U+58C1, U+5BFF, U+5C04, U+5C06, U+5E95, U+5F31, U+5F93, U+63C3, U+640D, U+6557, U+6614, U+662F, U+67D3, U+690D, U+6BBA, U+6E6F, U+72AF, U+732B, U+7518, U+7AE0, U+7AE5, U+7AF6, U+822A, U+89E6, U+8A3A, U+8A98, U+8CB8, U+8DE1, U+8E8D, U+95D8, U+961C, U+96A3, U+96EA, U+9BAE, U+FF20, U+FF22, U+FF29, U+FF2B-FF2C;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-98-400-normal.d2c3d390.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+25CB, U+4E71, U+4F59, U+50D5, U+520A, U+5217, U+5230, U+523A-523B, U+541B, U+5439, U+5747, U+59C9, U+5BDF, U+5C31, U+5DE8, U+5E7C, U+5F69, U+6050, U+60D1, U+63CF, U+663C, U+67C4, U+6885, U+6C38, U+6D6E, U+6DB2, U+6DF7, U+6E2C, U+6F5F, U+7532, U+76E3-76E4, U+7701, U+793C, U+79F0, U+7A93, U+7D00, U+7DE0, U+7E54, U+8328, U+8840, U+969C, U+96E8, U+9811, U+9AEA, U+9B5A, U+FF24, U+FF2E, U+FF57;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-99-400-normal.dff7db57.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+2191, U+505C, U+52E4, U+5305, U+535A, U+56E0, U+59BB, U+5ACC, U+5B09, U+5B87, U+5C90, U+5DF1, U+5E2D, U+5E33, U+5F3E, U+6298, U+6383, U+653B, U+6697, U+6804, U+6A39, U+6CCA, U+6E90, U+6F2B, U+702C, U+7206, U+7236, U+7559, U+7565, U+7591, U+75C7, U+75DB, U+7B4B, U+7BB1, U+7D99, U+7FBD, U+8131, U+885B, U+8B1D, U+8FF7, U+9003, U+9045, U+96A0, U+9732, U+990A, U+99D0, U+9E97, U+9F62, U+FF25, U+FF2D;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-100-400-normal.d90b2a00.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+4E08, U+4F9D, U+5012, U+514D, U+51B7, U+5275, U+53CA, U+53F8, U+5584, U+57FC, U+5B9D, U+5BFA, U+5C3E, U+5F01, U+5FB4, U+5FD7, U+606F, U+62E1, U+6563, U+6674, U+6CB3, U+6D3E, U+6D74, U+6E1B, U+6E2F, U+718A, U+7247, U+79D8, U+7D14, U+7D66, U+7D71, U+7DF4, U+7E41, U+80CC, U+8155, U+83D3, U+8A95, U+8AB2, U+8AD6, U+8CA1, U+9000, U+9006, U+9678, U+97D3, U+9808, U+98EF, U+9A5A, U+9B45, U+FF23, U+FF30;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-101-400-normal.76e228b6.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+25BC, U+3012, U+4EF2, U+4F0A, U+516B, U+5373, U+539A, U+53B3, U+559C, U+56F0, U+5727, U+5742, U+5965, U+59FF, U+5BC6, U+5DFB, U+5E45, U+5EAD, U+5FB3, U+6211, U+6253, U+639B, U+63A8, U+6545, U+6575, U+6628, U+672D, U+68A8, U+6BDB, U+6D25, U+707D, U+767E, U+7834, U+7B46, U+7BC9, U+8074, U+82E6, U+8349, U+8A2A, U+8D70, U+8DA3, U+8FCE, U+91CC, U+967D, U+97FF, U+9996, U+FF1C, U+FF2F, U+FF32, U+FF34;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-102-400-normal.a4dc50eb.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+3D, U+5E, U+25CF, U+4E0E, U+4E5D, U+4E73, U+4E94, U+4F3C, U+5009, U+5145, U+51AC, U+5238, U+524A, U+53F3, U+547C, U+5802, U+5922, U+5A66, U+5C0E, U+5DE6, U+5FD8, U+5FEB, U+6797, U+685C, U+6B7B, U+6C5F-6C60, U+6CC9, U+6CE2, U+6D17, U+6E21, U+7167, U+7642, U+76DB, U+8001, U+821E, U+8857, U+89D2, U+8B1B, U+8B70, U+8CB4, U+8CDE, U+8F03, U+8F2A, U+968E, U+9B54, U+9E7F, U+9EBB, U+FF05, U+FF33;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-103-400-normal.8034ac9d.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+500D, U+5074, U+50CD, U+5175, U+52E2, U+5352, U+5354, U+53F2, U+5409, U+56FA, U+5A18, U+5B88, U+5BDD, U+5CA9, U+5F92, U+5FA9, U+60A9, U+623F, U+6483, U+653F, U+666F, U+66AE, U+66F2, U+6A21, U+6B66, U+6BCD, U+6D5C, U+796D, U+7A4D, U+7AEF, U+7B56, U+7B97, U+7C4D, U+7E04, U+7FA9, U+8377, U+83DC, U+83EF, U+8535, U+8863, U+88CF, U+88DC, U+8907, U+8ACB, U+90CE, U+91DD, U+FF0B, U+FF0D, U+FF19, U+FF65;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-104-400-normal.74050819.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+4E01, U+4E21, U+4E38, U+52A9, U+547D, U+592E, U+5931, U+5B63, U+5C40, U+5DDE, U+5E78, U+5EFA, U+5FA1, U+604B, U+6075, U+62C5, U+632F, U+6A19, U+6C0F, U+6C11, U+6C96, U+6E05, U+70BA, U+71B1, U+7387, U+7403, U+75C5, U+77ED, U+795D, U+7B54, U+7CBE, U+7D19, U+7FA4, U+8089, U+81F4, U+8208, U+8336, U+8457, U+8A33, U+8C4A, U+8CA0, U+8CA8, U+8CC0, U+9014, U+964D, U+9803, U+983C, U+98DB, U+FF17, U+FF21;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-105-400-normal.d0ddea39.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+25, U+25A0, U+4E26, U+4F4E, U+5341, U+56F2, U+5BBF, U+5C45, U+5C55, U+5C5E, U+5DEE, U+5E9C, U+5F7C, U+6255, U+627F, U+62BC, U+65CF, U+661F, U+666E, U+66DC, U+67FB, U+6975, U+6A4B, U+6B32, U+6DF1, U+6E29, U+6FC0, U+738B, U+7686, U+7A76, U+7A81, U+7C73, U+7D75, U+7DD2, U+82E5, U+82F1, U+85AC, U+888B, U+899A, U+8A31, U+8A8C, U+8AB0, U+8B58, U+904A, U+9060, U+9280, U+95B2, U+984D, U+9CE5, U+FF18;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-106-400-normal.62a2f951.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+30F6, U+50AC, U+5178, U+51E6, U+5224, U+52DD, U+5883, U+5897, U+590F, U+5A5A, U+5BB3, U+5C65, U+5E03, U+5E2B, U+5E30, U+5EB7, U+6271, U+63F4, U+64AE, U+6574, U+672B, U+679A, U+6A29-6A2A, U+6CA2, U+6CC1, U+6D0B, U+713C, U+74B0, U+7981, U+7A0B, U+7BC0, U+7D1A, U+7D61, U+7FD2, U+822C, U+8996, U+89AA, U+8CAC, U+8CBB, U+8D77, U+8DEF, U+9020, U+9152, U+9244, U+9662, U+967A, U+96E3, U+9759, U+FF16;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-107-400-normal.6811cdef.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+23, U+3C, U+2192, U+4E45, U+4EFB, U+4F50, U+4F8B, U+4FC2, U+5024, U+5150, U+5272, U+5370, U+53BB, U+542B, U+56DB, U+56E3, U+57CE, U+5BC4, U+5BCC, U+5F71, U+60AA, U+6238, U+6280, U+629C, U+6539, U+66FF, U+670D, U+677E-677F, U+6839, U+69CB, U+6B4C, U+6BB5, U+6E96, U+6F14, U+72EC, U+7389, U+7814, U+79CB, U+79D1, U+79FB, U+7A0E, U+7D0D, U+85E4, U+8D64, U+9632, U+96E2, U+9805, U+99AC, U+FF1E;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-108-400-normal.27289f7c.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+2605-2606, U+301C, U+4E57, U+4FEE, U+5065, U+52DF, U+533B, U+5357, U+57DF, U+58EB, U+58F0, U+591C, U+592A-592B, U+5948, U+5B85, U+5D0E, U+5EA7, U+5FF5, U+6025, U+63A1, U+63A5, U+63DB, U+643A, U+65BD, U+671D, U+68EE, U+6982, U+6B73, U+6BD4, U+6D88, U+7570, U+7B11, U+7D76, U+8077, U+8217, U+8C37, U+8C61, U+8CC7, U+8D85, U+901F, U+962A, U+9802, U+9806, U+9854, U+98F2, U+9928, U+99C5, U+9ED2;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-109-400-normal.0d0177e6.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+266A, U+4F11, U+533A, U+5343, U+534A, U+53CD, U+5404, U+56F3, U+5B57-5B58, U+5BAE, U+5C4A, U+5E0C, U+5E2F, U+5EAB, U+5F35, U+5F79, U+614B, U+6226, U+629E, U+65C5, U+6625, U+6751, U+6821, U+6B69, U+6B8B, U+6BCE, U+6C42, U+706B, U+7C21, U+7CFB, U+805E, U+80B2, U+82B8, U+843D, U+8853, U+88C5, U+8A3C, U+8A66, U+8D8A, U+8FBA, U+9069, U+91CF, U+9752, U+975E, U+9999, U+FF0F-FF10, U+FF14-FF15;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-110-400-normal.ca6383f3.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+40, U+4E86, U+4E95, U+4F01, U+4F1D, U+4FBF, U+5099, U+5171, U+5177, U+53CB, U+53CE, U+53F0, U+5668, U+5712, U+5BA4, U+5CA1, U+5F85, U+60F3, U+653E, U+65AD, U+65E9, U+6620, U+6750, U+6761, U+6B62, U+6B74, U+6E08, U+6E80, U+7248, U+7531, U+7533, U+753A, U+77F3, U+798F, U+7F6E, U+8449, U+88FD, U+89B3, U+8A55, U+8AC7, U+8B77, U+8DB3, U+8EFD, U+8FD4, U+9031-9032, U+9580, U+9589, U+96D1, U+985E;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-111-400-normal.3f1aacf0.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+2B, U+D7, U+300E-300F, U+4E07, U+4E8C, U+512A, U+5149, U+518D, U+5236, U+52B9, U+52D9, U+5468, U+578B, U+57FA, U+5B8C, U+5BA2, U+5C02, U+5DE5, U+5F37, U+5F62, U+623B, U+63D0, U+652F, U+672A, U+6848, U+6D41, U+7136, U+7537, U+754C, U+76F4, U+79C1, U+7BA1, U+7D44, U+7D4C, U+7DCF, U+7DDA, U+7DE8, U+82B1, U+897F, U+8CA9, U+8CFC, U+904E, U+9664, U+982D, U+9858, U+98A8, U+9A13, U+FF13, U+FF5C;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-112-400-normal.3ec8f1c5.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+4E16, U+4E3B, U+4EA4, U+4EE4, U+4F4D, U+4F4F, U+4F55, U+4F9B, U+5317, U+5358, U+53C2, U+53E4, U+548C, U+571F, U+59CB, U+5CF6, U+5E38, U+63A2, U+63B2, U+6559, U+662D, U+679C, U+6C7A, U+72B6, U+7523, U+767D, U+770C, U+7A2E, U+7A3F, U+7A7A, U+7B2C, U+7B49, U+7D20, U+7D42, U+8003, U+8272, U+8A08, U+8AAC, U+8CB7, U+8EAB, U+8EE2, U+9054-9055, U+90FD, U+914D, U+91CD, U+969B, U+97F3, U+984C, U+FF06;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-113-400-normal.d3d9cbb6.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+26, U+5F, U+2026, U+203B, U+4E09, U+4EAC, U+4ED5, U+4FA1, U+5143, U+5199, U+5207, U+539F, U+53E3, U+53F7, U+5411, U+5473, U+5546, U+55B6, U+5929, U+597D, U+5BB9, U+5C11, U+5C4B, U+5DDD, U+5F97, U+5FC5, U+6295, U+6301, U+6307, U+671B, U+76F8, U+78BA, U+795E, U+7D30, U+7D39, U+7D9A, U+89E3, U+8A00, U+8A73, U+8A8D, U+8A9E, U+8AAD, U+8ABF, U+8CEA, U+8ECA, U+8FFD, U+904B, U+9650, U+FF11-FF12;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-114-400-normal.08c895a8.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+3E, U+3005, U+4E0D, U+4E88, U+4ECB, U+4EE3, U+4EF6, U+4FDD, U+4FE1, U+500B, U+50CF, U+5186, U+5316, U+53D7, U+540C, U+544A, U+54E1, U+5728, U+58F2, U+5973, U+5B89, U+5C71, U+5E02, U+5E97, U+5F15, U+5FC3, U+5FDC, U+601D, U+611B, U+611F, U+671F, U+6728, U+6765, U+683C, U+6B21, U+6CE8, U+6D3B, U+6D77, U+7530, U+7740, U+7ACB, U+7D50, U+826F, U+8F09, U+8FBC, U+9001, U+9053, U+91CE, U+9762, U+98DF;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-115-400-normal.9b7b3bbc.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+7C, U+3080, U+4EE5, U+5148, U+516C, U+521D, U+5225, U+529B, U+52A0, U+53EF, U+56DE, U+56FD, U+5909, U+591A, U+5B66, U+5B9F, U+5BB6, U+5BFE, U+5E73, U+5E83, U+5EA6, U+5F53, U+6027, U+610F, U+6210, U+6240, U+660E, U+66F4, U+66F8, U+6709, U+6771, U+697D, U+69D8, U+6A5F, U+6C34, U+6CBB, U+73FE, U+756A, U+7684, U+771F, U+793A, U+7F8E, U+898F, U+8A2D, U+8A71, U+8FD1, U+9078, U+9577, U+96FB, U+FF5E;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-116-400-normal.1cd12740.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+A9, U+3010-3011, U+30E2, U+4E0B, U+4ECA, U+4ED6, U+4ED8, U+4F53, U+4F5C, U+4F7F, U+53D6, U+540D, U+54C1, U+5730, U+5916, U+5B50, U+5C0F, U+5F8C, U+624B, U+6570, U+6587, U+6599, U+691C, U+696D, U+6CD5, U+7269, U+7279, U+7406, U+767A-767B, U+77E5, U+7D04, U+7D22, U+8005, U+80FD, U+81EA, U+8868, U+8981, U+89A7, U+901A, U+9023, U+90E8, U+91D1, U+9332, U+958B, U+96C6, U+9AD8, U+FF1A, U+FF1F;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-117-400-normal.4093cad0.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+4E, U+A0, U+3000, U+300C-300D, U+4E00, U+4E0A, U+4E2D, U+4E8B, U+4EBA, U+4F1A, U+5165, U+5168, U+5185, U+51FA, U+5206, U+5229, U+524D, U+52D5, U+5408, U+554F, U+5831, U+5834, U+5927, U+5B9A, U+5E74, U+5F0F, U+60C5, U+65B0, U+65B9, U+6642, U+6700, U+672C, U+682A, U+6B63, U+6C17, U+7121, U+751F, U+7528, U+753B, U+76EE, U+793E, U+884C, U+898B, U+8A18, U+9593, U+95A2, U+FF01, U+FF08-FF09;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-118-400-normal.f6229197.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+21-22, U+27-2A, U+2C-3B, U+3F, U+41-4D, U+4F-5D, U+61-7B, U+7D, U+AB, U+AE, U+B2-B3, U+B7, U+BB, U+C9, U+CD, U+D6, U+D8, U+DC, U+E0-E5, U+E7-ED, U+EF, U+F1-F4, U+F6, U+F8, U+FA, U+FC-FD, U+103, U+14D, U+1B0, U+300-301, U+1EBF, U+1EC7, U+2013-2014, U+201C-201D, U+2039-203A, U+203C, U+2048-2049, U+2113, U+2122, U+65E5, U+6708, U+70B9;
}

@font-face {
  font-family: Mochiy Pop One;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("mochiy-pop-one-119-400-normal.b26441dc.woff2") format("woff2"), url("mochiy-pop-one-all-400-normal.128cee90.woff") format("woff");
  unicode-range: U+20, U+2027, U+3001-3002, U+3041-307F, U+3081-308F, U+3091-3093, U+3099-309A, U+309D-309E, U+30A1-30E1, U+30E3-30ED, U+30EF-30F0, U+30F2-30F4, U+30FB-30FE, U+FF0C, U+FF0E;
}

body {
  margin: 0;
}

a:visited {
  color: #5755d9;
}

h1, h2, h3, h4, h5, h1.introjs-tooltip-title {
  font-family: Mochiy Pop One, sans-serif;
}

#app {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}

#app .panel {
  border-radius: none;
  border: none;
}

/*# sourceMappingURL=index.d521b380.css.map */
